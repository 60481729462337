import axios from "axios";
import config from '@/config.js'

export const gridModule = {
	state: () =>({
		domain: config.API_LOCATION,
		url: '',
		action: '/',
		filter: {},
		setting: null,
		data: null,
		isLoading: true,
	}),
	getters: {
		navPageList(state)
		{
			let res=Array();
			for(let n=1;n<=state.setting.option.page.total_page;n++){
				if(
					n<=3
					||(n>=state.setting.option.page.current-2&& n<=state.setting.option.page.current+4)
					||n>=state.setting.option.page.total_page-3
				)
					res.push(n);
			}
//			console.log(res);
			return res;
		},
		getSavedFilter(state)
		{
			let filter={};
			for(let name in state.setting.field){
				if(state.setting.field[name].filter.show=='1') {
					if(state.setting.field[name].filter.type=='mkey')
						filter[name] = String(state.setting.field[name].filter.set).split('|');
					else
						filter[name] = state.setting.field[name].filter.set;
				}
			}
			return filter;
		},
		getKeyFilter(state)
		{
			let field={}
			for(let name in state.setting.field){
				if(state.setting.field[name].filter.show=='1') {
					if(state.setting.field[name].filter.type=='key'||state.setting.field[name].filter.type=='mkey')
						field[name]=state.setting.field[name].data;
				}
			}
			return field;
		}
	},
	mutations: {
		setUrl(state,url)
		{
			state.isLoading = true;
			state.url=url;
		},
		setLoading(state, bool)
		{
			state.isLoading = bool;
		},
		setAction(state,action)
		{
			state.action=action;
		},
		setFilter(state,filter)
		{
			state.filter=filter;
		},
		setSetting(state,setting)
		{
			state.setting=setting;
		},
		setData(state,data)
		{
			state.data=data;
		},
	},
	actions: {
		async load({state,commit})
		{
			try {
//				commit('setLoading', true);
				const response = await axios.post(state.domain+state.url+state.action, {filter:state.filter});
				commit('setSetting', response.data['setting']);
				commit('setData', response.data['data']);
			} catch (e) {
				console.log(e)
			} finally {
				commit('setLoading', false);
				commit('setAction', '');
			}
		},
		page ({state,commit,dispatch},current)
		{
			commit('setAction','/page/'+current+'/');
			dispatch('load');
		},
		count ({state,commit,dispatch})
		{
			commit('setAction','/count/'+event.target.value+'/');
			dispatch('load');
		},
		sort ({state,commit,dispatch},current)
		{
			commit('setAction','/sort/'+current+'/');
			dispatch('load');
		},
		async exec_filter({state,commit,dispatch},filter)
		{
			commit('setLoading', true);
			commit('setFilter',filter);
			commit('setAction','/filter/');
			await dispatch('load');
		}
	},
	namespaced: true
}