<template>
	<div class="bg-gray-50 min-w-screen min-h-screen d-flex justify-content-between align-items-center w-100">
		<div class="d-flex justify-content-between align-items-center w-100">
			<input
				type="text"
				@change="unselect()"
				@keyup="do_search()"
				v-model="text_value"
				placeholder="P/N"
				class="p-2 mb-0.5 w-full border border-gray-300 rounded flex-grow-1"
			>
			<div style="position:absolute;">
				<ul
					v-if="Object.keys(search_list).length"
					class="autoc w-full rounded bg-white border border-gray-300"
				>

					<li
						v-for="v in search_list"
						:key="v.id"
						@click="select(v.pn)"
					>
						{{ v.pn }}
					</li>
				</ul>
			</div>

		</div>
	</div>
</template>
<script>
import axios from 'axios';
import config from '@/config.js'
export default {
	name: 'type-pn-edit',
	data() {
		return {
			text_value: '',
			search_list: {}
		}
	},

	props: {
		modelValue: {
			type: [ String,Number],
			default: ''
		},
		url: {
			type: [ String ],
			default: ''
		},
	},
	mounted ()
	{
		this.text_value=this.modelValue
	},
	methods: {
		select(text)
		{
//			console.log('se')
			this.text_value = text
			this.search_list={}
			this.$emit('update:modelValue', text);
		},
		unselect()
		{
//			console.log('un')
			this.$emit('update:modelValue', this.text_value);
			setTimeout(this.do_unselect,250)
		},
		do_unselect()
		{
//			console.log('do_un')
			this.search_list={}
		},
		do_search()
		{
			if (this.text_value.trim().length < 4 ){
				this.search_list={}
				return
			}
			axios({url: config.API_LOCATION+this.url+'?q='+this.text_value+'&limit=10' })
				.then(resp => {
					this.search_list=resp.data.data
				})
				.catch(err => {
					console.log(err)
				})
		},
		show_history()
		{
			this.$parent.show_history(this.text_value)
		},
		update_value(value)
		{

			this.text_value=value
		}
	},
	watch: {

	}
}
</script>

<style>
.cursor-pointer {
	cursor: pointer;
}
.autoc {
	list-style: none;
	padding-left: 0;
	padding-right: 0;
	position: absolute;
	z-index: 20;
	min-width: 13.5em;
	top: 20px;
}
.autoc li {
	cursor: pointer;
	padding: .5em 1em;
}
.autoc li:hover,
.autoc li:focus {
	background-color: #6CB4EE;
	color: #fff;
}
</style>