<template>
	<lg-menu/>
	<div class="main-content">
		<div class="container-fluid">
			<div  class="row justify-content-center">
				<div class="header">
					<div class="container-fluid">
						<div class="header-body">
							<div class="row align-items-end">
								<div class="col">
									<h6 class="header-pretitle">Рабочий стол / справочники</h6>
									<h1 class="header-title">Посредники</h1>
								</div>
							</div> <!-- / .row -->
						</div> <!-- / .header-body -->
					</div>
				</div> <!-- / .header -->
				<div class="container-fluid">
					<grid :url="url"></grid>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Grid from "@/components/Grid";
export default {
	components: {
		Grid
	},
	data() {
		return {
			url: 'directories/intermediary'
		}
	},
	methods: {
		load_filter(filter,key)
		{

		}
	}
}
</script>