<template>
	<td v-if="formatter_money!=null" class="align-top">
		{{ value }}

	</td>
</template>

<script>
export default {
	name: 'type-money-grid',
	props: {
		data: [ String, Number],
		currency: [String],
	},
	data(){
		return {
			formatter_money: null,
			value: '',
		}
	},
	mounted()
	{
		this.formatter_money={
			USD:new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'USD'}),
			EUR:new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'EUR'}),
			RUB:new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB'}),
			AED:new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'AED'}),
		}
		if(this.currency)
			this.value=this.formatter_money[this.currency].format(this.data)
		else
			this.value=this.data
	},
}
</script>

<style scoped>

</style>
