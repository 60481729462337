<template>
	<td class="align-top" style="white-space: normal; line-height: normal;">
		<template v-for="i in data">
			<b>{{i.n_view}}</b>
			<type-key-grid :data="i.status" :field="field.setting.field.status" />
			<template v-for="pn in i.spares">
				<b>{{pn.pn}}</b>&nbsp;-&nbsp;{{pn.count}}
				<br/>
			</template>
			<br/>
		</template>
	</td>
</template>

<script>
export default {
	name: 'type-invoice-grid',
	props: {
		data: [ Object],
		field: [ Object],
	},
	methods: {

	},
}
</script>

<style scoped>

</style>
