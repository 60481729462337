<template>
	<input v-model="modelValue" @change="changeOption" type="text" class="form-control" style="width: 110px;">
</template>
<script>
import flatpickr from 'flatpickr';
import { Russian } from "flatpickr/dist/l10n/ru.js";
export default {
	name: 'lg-date',
	props: {
		modelValue: {
			type: String
		},
	},
	mounted ()
	{
		flatpickr(this.$el, {
			"locale": Russian,
			dateFormat:'d.m.Y'
		});
	},
	methods: {
		changeOption(event) {
			this.$emit('update:modelValue', event.target.value);
		}
	},
}
</script>
