<template>

	<div class="modal fade" id="modalMembers" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-card card" data-list="{&quot;valueNames&quot;: [&quot;name&quot;]}">
					<div class="card-header">
						<h4 class="card-header-title" id="exampleModalCenterTitle">Создание invoice</h4>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div  class="card-body">
						<div v-if="add_invoice_error_currency" class="alert alert-danger" role="alert">Валюта должна совпадать.</div>
						<div v-if="add_invoice_error_count" class="alert alert-danger" role="alert">Ошибка в количестве.</div>
						<ul v-if="data!=null" class="list-group list-group-flush list my-n3">
							<li class="list-group-item">
								<div class="row align-items-center">
									<div class="d-flex justify-content-between">
										<input type="checkbox" v-model="invoice_spares_select_checkall" @change="modal_add_invoice_checkall"/>
									</div>
								</div>
							</li>
							<template v-for="(c,k) in data.spares">
								<li v-if="invoice_spares_select.hasOwnProperty(k)" class="list-group-item">
									<div class="row align-items-center">
										<div class="d-flex justify-content-between">
											<lg-checkbox v-model="invoice_spares_select[k]" :ref="'invoice_spares_select'+k"/>
											<div style="min-width:100px;">{{c.pn}}</div>
											<div><lg-input v-model="invoice_spares_select_count[k]"  :width="50" :ref="'invoice_spares_select_count'+k"/></div>
										</div>
									</div> <!-- / .row -->
								</li>
							</template>
						</ul>
					</div>
					<div class="btn-group mt-2">
						<button type="button" class="btn btn-success" @click="add_invoice();"><span class="fe fe-save mr-3"></span> Создать</button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade" id="modalProforma" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-card card" data-list="{&quot;valueNames&quot;: [&quot;name&quot;]}">
					<div class="card-header">
						<h4 class="card-header-title" id="exampleModalCenterTitle">Создание proforma</h4>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div  class="card-body">
						<div v-if="add_proforma_error_currency" class="alert alert-danger" role="alert">Валюта должна совпадать.</div>
						<div v-if="add_proforma_error_count" class="alert alert-danger" role="alert">Ошибка в количестве.</div>
						<ul v-if="data!=null" class="list-group list-group-flush list my-n3">
							<li class="list-group-item">
								<div class="row align-items-center">
									<div class="d-flex justify-content-between">
										<input type="checkbox" v-model="proforma_spares_select_checkall" @change="modal_add_proforma_checkall"/>
									</div>
								</div>
							</li>
							<template v-for="(c,k) in data.spares">
								<li v-if="proforma_spares_select.hasOwnProperty(k)" class="list-group-item">
									<div class="row align-items-center">
										<div class="d-flex justify-content-between">
											<lg-checkbox v-model="proforma_spares_select[k]" :ref="'proforma_spares_select'+k"/>
											<div style="min-width:100px;">{{c.pn}}</div>
											<div><lg-input v-model="proforma_spares_select_count[k]"  :width="50" :ref="'proforma_spares_select_count'+k"/></div>
										</div>
									</div> <!-- / .row -->
								</li>
							</template>
						</ul>
					</div>
					<div class="btn-group mt-2">
						<button type="button" class="btn btn-success" @click="add_proforma();"><span class="fe fe-save mr-3"></span> Создать</button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade" id="modal_order_payment" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-card card" data-list="{&quot;valueNames&quot;: [&quot;name&quot;]}">
					<div class="card-header">

						<h4 class="card-header-title" id="exampleModalCenterTitle">
							Расчет с поставщиком
						</h4>

						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

					</div>
					<div class="card-body">

						<ul v-if="data!=null&&order_payment_k!=0" class="list-group list-group-flush list my-n3">
							<li v-for="(p,k) in data.order[order_payment_k].payment" :key="k" class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">{{p.date}}</div>
									<div class="col ms-n2">{{formatter_money[p.currency].format(p.sum)}}</div>
									<div class="col ms-n2">
										<div v-if="p.payed==0" class="badge d-inline bg-danger"><strong>Не оплачен</strong></div>
										<div v-if="p.payed==1" class="badge d-inline bg-success"><strong>Оплачен</strong></div>
									</div>
									<div v-if="p.payed==0" class="col-auto">
										<button @click="order_payment_payed(p.id)" type="button" class="btn btn-outline-primary btn-sm mb-2">Оплачен</button>
									</div>
								</div>
							</li>
						</ul>

					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade" id="modal_invoice_payment" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-card card" data-list="{&quot;valueNames&quot;: [&quot;name&quot;]}">
					<div class="card-header">

						<!-- Title -->
						<h4 class="card-header-title" id="exampleModalCenterTitle">
							Расчет с клиентом
						</h4>

						<!-- Close -->
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

					</div>
					<div class="card-body">

						<!-- List group -->
						<ul v-if="data!=null&&invoice_payment_k!=0" class="list-group list-group-flush list my-n3">
							<li v-for="(p,k) in data.invoice[invoice_payment_k].payment" :key="k" class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">

										<lg-date v-model="p.date" class="form-control-sm" />

									</div>
									<div class="col ms-n2">

										<lg-input v-model="p.sum" :type="'float'" :mask="'9{1,},9{2}'" :width="70" class="mb-1" />

									</div>
									<div class="col-auto">

										<button type="button" class="btn btn-outline-primary btn-sm mb-2">Оплачен</button>
										<a  class="text-reset me-3" style="cursor: pointer;" title="Удалить" >
											<i class="fe fe-trash-2"></i>
										</a>

									</div>
								</div> <!-- / .row -->
							</li>
						</ul>

						<div class="kanban-add">
							<div class="card card-sm card-flush mb-0">
								<div class="card-body">

									<!-- Link -->
									<div class="text-center">
										<a  class="kanban-add-link" style="cursor: pointer;" >Добавить</a>
									</div>



								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade" id="modal_order_file" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-card card" data-list="{&quot;valueNames&quot;: [&quot;name&quot;]}">
					<div class="card-header">
						<h4 class="card-header-title" id="exampleModalCenterTitle">Файлы</h4>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div  class="card-body">
						<ul v-if="data!=null&&order_file_active_k!=''" class="list-group list-group-flush list my-n3">
							<li class="list-group-item">
								<div class="row align-items-center">
									<div class="d-flex justify-content-between">
										<button @click="order_file_add();" type="button" class="btn btn-outline-primary btn-sm mb-2">Добавить</button>
									</div>
								</div>
							</li>
							<li v-for="(f,k) in data.order[order_file_active_k].file" class="list-group-item">
								<div class="row align-items-center">
									<div class="d-flex justify-content-between">
										<div><lg-input v-model="f.title" :width="200"/></div>
										<div>
											<span v-if="f.title.length!=0" class="text-reset me-3" title="Загрузить" >
												<label :for="'upload-order-file-input'+k">
													<i class="fe fe-paperclip text-reset" style="cursor: pointer;--hover-color: green"></i>
												</label>
												<input class="d-none" :id="'upload-order-file-input'+k" type="file" v-on:change="order_file_upload($event,k,f.id);"/>
											</span>
											<a v-if="f.file.id!=0" :href="'https://api-skyparts.litegroup.ru/release/child/'+this.data.__id+'/order/child/'+order_file_active_id+'/file/element/'+f.id+'/file/download/?token='+token" target="_blank" class="text-reset me-3">
												<i class="fe fe-download text-reset"></i>
											</a>
											<a @click="order_file_delete(k,f.id);" class="text-reset me-3" style="cursor: pointer;" title="Удалить" >
												<i class="fe fe-trash-2"></i>
											</a>
										</div>
									</div>
								</div> <!-- / .row -->
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade modal-xl" id="modal_add_spares" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-card card" data-list="{&quot;valueNames&quot;: [&quot;name&quot;]}">
					<div class="card-header">
						<h4 class="card-header-title" id="exampleModalCenterTitle">Создание spares</h4>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div style="height: 800px;" class="card-body">
						<ul v-if="data!=null" class="list-group list-group-flush list my-n3">
							<li class="list-group-item">
								<div class="row align-items-center">
									<div class="d-flex justify-content-between">
										<div>
											<offer-edit-pn v-model="add_spares_pn" :url="'offer/pn_autocomplete/0/'" />
										</div>
										<div>
											<lg-select-autocomplete v-model="add_spares_provider" :placeholder="'Поставщик'" :data="setting.field.spares.setting.field.provider.data" :url="'release/child/'+data.id+'/spares/element/0/provider/autocomplete/'" />
										</div>
									</div>
								</div>
							</li>
						</ul>
					</div>
					<div class="btn-group mt-2">
						<button type="button" class="btn btn-success" @click="add_spares();"><span class="fe fe-save mr-3"></span> Создать</button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<lg-menu/>

	<div class="main-content">
		<div class="container-fluid">
			<div v-if="data!=null" class="row justify-content-center">
				<div class="header mt-md-5">
					<div class="header-body">
						<div class="row align-items-center">
							<div class="col">
								<h6 class="header-pretitle">Рабочий стол</h6>
								<h1 class="header-title">Release # {{data.n}}</h1>
							</div>
							<div class="col">
								<div class="position-relative" style="height:44px;">
									<div v-if="Object.keys(save_list).length>0||data_saving" class="alert alert-light" role="alert">Данные сохраняются, не закрывайте вкладку!</div>
								</div>
							</div>
							<div class="col">
								<div class="position-relative" style="height:44px;">
									<div v-if="error!=''" class="alert alert-danger" role="alert">{{error}}</div>
								</div>
							</div>
						</div> <!-- / .row -->
					</div>
				</div>
				<div class="col">
					<div class="row gx-3 d-flex align-items-stretch">
						<div class="col-12 col-md-12 col-lg-1">
							<div class="form-group">
								<label class="form-label d-block">Offer #</label>
								<h4><span @click="link_offer()" style="cursor: pointer;" class="badge bg-primary-soft ms-1 mt-1">{{data.quote}}</span></h4>
							</div>
						</div>
						<div class="col-12 col-md-12 col-lg-1">
							<div class="form-group">
								<label class="form-label d-block">Статус</label>
								<div class="badge d-inline" :class="setting.field.status.data['id_'+data.status].color">
									<strong>{{setting.field.status.data['id_'+data.status].title}}</strong>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-12 col-lg-2">
							<div class="form-group">
								<label class="form-label d-block">Оплата поставщику</label>
								<div class="badge d-inline" :class="setting.field.payed.data['id_'+data.payed].color">
									<strong>{{setting.field.payed.data['id_'+data.payed].title}}</strong>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-12 col-lg-2">
							<div class="form-group">
								<label class="form-label">Клиент</label>
								<lg-select v-model="data.client" :options="setting.field.client.data"/>
							</div>
						</div>
						<div class="col-12 col-md-12 col-lg-1">
							<div class="form-group">
								<label class="form-label">Заказ клиента</label>
								<div class="d-flex">
									<lg-input v-model="data.client_order_n" :placeholder="'Order #'"/>
									<span class="text-reset ms-3 me-3 pt-1" title="Загрузить" >
										<label for="client-order-file-input">
											<i class="fe fe-paperclip" style="cursor: pointer;--hover-color: green"></i>
										</label>
										<input class="d-none" id="client-order-file-input" type="file" v-on:change="client_order_upload_file($event)"/>
									</span>
									<a v-if="data.client_order_file.id!=0" :href="'https://api-skyparts.litegroup.ru/release/element/'+this.data.__id+'/client_order_file/download/?token='+token" target="_blank" class="text-reset me-3" style="cursor: pointer;" title="Скачать">
										<i class="fe fe-download"></i>
									</a>
									<a v-if="data.client_order_file.id!=0" @click="client_order_delete_file()" class="text-reset me-3" style="cursor: pointer;" title="Удалить" >
										<i class="fe fe-trash-2"></i>
									</a>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-12 col-lg-1">
							<div class="form-group">
								<label class="form-label">Срочность</label>
								<lg-select v-model="data.urgency" :options="setting.field.urgency.data"/>
							</div>
						</div>
						
					</div> <!-- / .row -->
					
					<div class="row gx-3 d-flex align-items-start justify-content-end">
						<div class="col-12 col-md-12 col-lg-1">
							<div class="form-group">
								<label class="form-label">Дата</label>
								<lg-date class="form-control-sm" v-model="data.date"/>
							</div>
						</div>
						<div class="col-12 col-md-12 col-lg-2">
							<div class="form-group">
								<label class="form-label">Ответственный</label>
								<lg-select v-model="data.responsible" :options="setting.field.responsible.data"/>
							</div>
						</div>
						<div class="col-12 col-md-12 col-lg-3 text-md-end d-flex justify-content-md-end">
							<div class="form-group float-start mb-0">
								<!--<label class="form-label invisible d-none d-md-block">Действия</label>-->
								<div class="dropdown mb-2 me-2">
									<button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButtonTwo" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :disabled="data.client==0">Действия</button>
									<div class="dropdown-menu" aria-labelledby="dropdownMenuButtonTwo" style="">
										<a @click="modal_add_invoice()" class="dropdown-item" style="cursor: pointer;">Создать invoice</a>
										<a @click="modal_add_proforma()" class="dropdown-item" style="cursor: pointer;">Создать proforma</a>
										<a @click="add_payment_customers()" class="dropdown-item" style="cursor: pointer;">Создать оплату от клиента</a>
										<a :href="'https://api-skyparts.litegroup.ru/release/dowload_1c/'+data.id+'/?token='+token" target="_blank" class="dropdown-item">Бланк для 1С</a>
										<a @click="reset_status()" class="dropdown-item" style="cursor: pointer;">Сбросить статусы</a>
										<a @click="set_status_canceled()" class="dropdown-item" style="cursor: pointer;">Установить статус отменен</a>
										<a @click="del_link_offer()"  class="dropdown-item" style="cursor: pointer;">Удалить и вернуться в offer</a>
										<a @click="modal_add_spares()"  class="dropdown-item" style="cursor: pointer;">Создать spares</a>
										<a @click="copy_release()"  class="dropdown-item" style="cursor: pointer;">Копировать release</a>
									</div>
								</div>
							</div>
							<div class="form-group float-end mb-0">
								<!--<label class="form-label invisible d-none d-md-block">К списку</label>-->
								<button @click="save_manual()" type="button" class="btn btn-success" :disabled="data.client==0"> Сохранить</button>
							</div>
							<div class="form-group float-end mb-0">
								<!--<label class="form-label invisible d-none d-md-block">К списку</label>-->
								<button @click="save_and_exit()" type="button" class="btn btn-secondary ms-2 mb-2">К списку</button>
							</div>
						</div>
					</div><!-- / .row -->
				</div>

				<div class="header-body">
					<div class="row align-items-center">
						<div class="col order-last order-md-first">
							<ul class="nav nav-tabs nav-overflow header-tabs">
								<li class="nav-item">
									<a @click="tab_active='SPARES'" class="nav-link" :class="{active:tab_active=='SPARES'}" style="cursor: pointer;">Spares</a>
								</li>
								<li class="nav-item">
									<a @click="tab_active='ORDER'" class="nav-link" :class="{active:tab_active=='ORDER'}" style="cursor: pointer;">Order</a>
								</li>
								<li class="nav-item">
									<a @click="tab_active='INVOICE'" class="nav-link" :class="{active:tab_active=='INVOICE'}" style="cursor: pointer;">Invoice</a>
								</li>
								<li class="nav-item">
									<a @click="tab_active='PROFORMA'" class="nav-link" :class="{active:tab_active=='PROFORMA'}" style="cursor: pointer;">Proforma</a>
								</li>
								<li class="nav-item">
									<a @click="tab_active='PAYMENT_CUSTOMERS'" class="nav-link" :class="{active:tab_active=='PAYMENT_CUSTOMERS'}" style="cursor: pointer;">Оплата от клиентов</a>
								</li>
								<li class="nav-item">
									<a @click="tab_active='MESSAGE'" class="nav-link" :class="{active:tab_active=='MESSAGE'}" style="cursor: pointer;">Комментарии</a>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div v-if="tab_active=='SPARES'" class="card px-0">
					<div  class="">
						<table class="table table-sm table-nowrap table-nobcollapse card-table">
							<thead class="">
							<tr>
								<th>P/N</th>
								<th>Поставщик</th>
								<th style="width:10px;">Кол-во</th>
								<th style="width:160px;">Цена поставщика</th>
								<th style="width:160px;">Цена в invoice</th>
								<th style="width:125px;">Итоговая цена USD</th>
								<th style="width:125px;">Итоговая цена RUB</th>
								<th style="width:250px;">Info</th>
								<th style="width:50px;">Статус</th>
								<th></th>
							</tr>
							</thead>
							<tbody ref="price_result_rub" class="list px-2">
							<tr v-for="(s,k) in data.spares" :key="k">
								<td>
									<p>{{s.pn}}<span @click="spares_edit(s.spares_id)" style="cursor: pointer;font-size:14px;" class="fe fe-edit ms-3"></span></p>
									<p><lg-input v-model="s.release_alt" :type="'string'" :width="200" :placeholder="'Альтернатива'" :disabled="s.commit==1"/></p>
								</td>
								<td>
									{{(typeof s.provider === 'object'?s.provider[Object.keys(s.provider)[0]].title:'')}}
								</td>
								<td>
									<lg-input v-model="s.order_count" :type="'int'" :mask="'9{1,}'" :width="40" class="mb-3" :disabled="s.commit==1"/>
								</td>
								<td class="">
									<div class="d-flex justify-content-start">
										<div class="col me-1">
											<lg-input :ref="'spares_'+k+'_price_provider'" v-model="s.price_provider" :type="'float'" :mask="'9{1,},9{2}'" :width="70" class="mb-1" :disabled="s.commit==1"/>
											<h4><span onclick="navigator.clipboard.writeText(this.innerText);" style="cursor: pointer;" class="badge bg-success ms-1 mt-n1">{{data_calc[k].price_provider_total}}</span></h4>
										</div>
										<lg-select v-model="s.currency_provider" :options="setting.field.spares.setting.field.currency_provider.data" :width="70" :disabled="s.status>2||s.commit==1"/>
									</div>
								</td>
								<td class="">
									<div class="d-flex justify-content-start">
										<div class="col me-1">
											<lg-input :ref="'spares_'+k+'_price_invoice_lotos'" v-model="s.price_invoice_lotos" :type="'float'" :mask="'9{1,},9{2}'" :width="70" class="mb-1" :disabled="s.commit==1"/>
											<h4><span onclick="navigator.clipboard.writeText(this.innerText);" style="cursor: pointer;" class="badge bg-success ms-1 mt-n1">{{data_calc[k].price_invoice_lotos_total}}</span></h4>
										</div>
										<lg-select v-model="s.currency_invoice_lotos" :options="setting.field.spares.setting.field.currency_invoice_lotos.data" :width="70" :disabled="s.commit==1"/>
									</div>
								</td>
								<td class="">
									<div class="d-flex justify-content-start">
										<div class="col me-1">
											<lg-input :ref="'spares_'+k+'_price_result_usd'" v-model="s.price_result_usd" :type="'float'" :mask="'9{1,},9{2}'" :width="70" class="mb-1" :disabled="s.commit==1"/>
											<h4><span onclick="navigator.clipboard.writeText(this.innerText);" style="cursor: pointer;" class="badge bg-success ms-1 mt-n1">{{data_calc[k].price_result_usd_total}}</span></h4>
										</div>
									</div>
								</td>
								<td>
									<div class="">
										<p><lg-input v-model="s.price_result_rub" :type="'float'" :mask="'9{1,},9{2}'" :width="90" :disabled="s.commit==1"/></p>
										<h3><span onclick="navigator.clipboard.writeText(this.innerText);" style="cursor: pointer;" class="badge bg-success ms-1 mt-n1">{{data_calc[k].price_result_rub_total}}</span></h3>
									</div>
								</td>
								<td>
									<div class="d-flex align-items-center justify-content-between">
										<p class="fs-6 me-1 pt-3">Country of origin:&nbsp;</p> <lg-input v-model="s.country_of_origin" :type="'string'" :width="150" class="d-inline-flex" :disabled="s.commit==1"/>
									</div>
									<div class="d-flex align-items-center justify-content-between">
										<p class="fs-6 me-1 pt-3">ECCN:&nbsp;</p><lg-input v-model="s.eccn" :type="'string'" :width="150" class="d-inline-flex" :disabled="s.commit==1"/>
									</div>
									<div class="d-flex align-items-center justify-content-between">
										<p class="fs-6 me-1 pt-3">HS code:&nbsp;</p><lg-input v-model="s.hs_code" :type="'string'" :width="150" class="d-inline-flex" :disabled="s.commit==1"/>
									</div>
									<div class="d-flex align-items-center justify-content-between">
										<p class="fs-6 me-1 pt-3">HAZMAT:&nbsp;</p><lg-select v-model="s.hazmat" :options="setting.field.spares.setting.field.hazmat.data" :width="70" class="d-inline-flex" :disabled="s.commit==1"/>
									</div>
									<div class="d-flex align-items-center justify-content-between">
										<p class="fs-6 me-1 pt-3">MFG:&nbsp;</p><lg-input v-model="s.mfg" :type="'string'" :width="150" class="d-inline-flex" :disabled="s.commit==1"/>
									</div>
								</td>
								<td>
									<div class="badge d-inline" :class="setting.field.status.data['id_'+s.status].color">
										<strong>{{setting.field.spares.setting.field.status.data['id_'+s.status].title}}</strong>
									</div>
								</td>
								<td>
									<div class="col">
										<span  @click="delete_spares(s.id,k)" style="cursor: pointer;" class="fe fe-trash ms-2"></span>
									</div>
								</td>
							</tr>
							</tbody>

							<tfoot>
							<tr>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th>{{price_result_rub_sum}}</th>
								<th></th>
								<th></th>
								<th></th>
							</tr>
							</tfoot>
						</table>


					</div>
				</div>


				<div v-if="tab_active=='ORDER'" class="card px-0">
					<div  class="">
						<table class="table table-sm table-nowrap table-nobcollapse card-table">
							<thead class="">
							<tr>
								<th style="width:110px;">#</th>
								<th style="width:85px;">Дата</th>
								<th style="width:100px;">Поставщик</th>
								<th>P/N</th>
								<th>Bank fee</th>
								<th>Сумма</th>
								<th style="width:150px;">Terms</th>
								<th style="width:140px;">Forwarder</th>
								<th style="width:140px;">Ship to</th>
								<th style="width:70px;">AOG fee</th>
								<th>Статус</th>
								<th>Оплачен</th>
								<th>Файлы</th>
								<th></th>
							</tr>
							</thead>
							<tbody ref="price_result_rub" class="list px-2">
							<tr v-for="(o,k) in data.order" :key="k">
								<td>
									<div class="d-flex flex-nowrap">
									{{o.n_view}}
									<div class="dropdown d-flex order-2">
										<a href="#" class="text-dark" role="button" data-bs-toggle="dropdown" aria-haspopup="true" data-expanded="false">
											<span style="cursor: pointer;font-size:14px;" class="fe fe-message-circle ms-3 mt-2 d-inline-flex"></span>
										</a>
										<div class="dropdown-menu p-3" style="width:250px;">
											<p><textarea v-model="o.comment" class="form-control form-control-sm" placeholder="Коментарий"></textarea></p>
										</div>
									</div>
									</div>
								</td>
								<td>
									{{o.date}}
								</td>
								<td style="white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;width:150px;display:block;">
									<span title="">{{(typeof o.provider === 'object'?o.provider[Object.keys(o.provider)[0]].title:'')}}</span>
								</td>
								<td>
									<div v-for="(o_s,o_s_k) in o.spares">
										{{o_s.pn}}
										<div class="d-flex align-items-center justify-content-between mt-1">
											<lg-date v-model="o_s.scheduled_delivery_date" :placeholder="'Delivery date'" class="form-control form-control-sm me-1" :disabled="o.commit==1"/>
											<div v-if="o.commit==0" class="dropdown d-inline-flex order-2">
												<span @click="order_date_copy(k,o_s_k)" style="cursor: pointer;font-size:14px;" class="fe fe-copy ms-3"></span>
											</div>
											<lg-input :ref="'order_'+k+'_spares_'+o_s_k+'_result_dispatch_place'" v-model="o_s.result_dispatch_place" :type="'string'" :width="70" :placeholder="'Место'" :disabled="o.commit==1"/>
										</div>
										<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-1 border-bottom"></div>
									</div>
								</td>
								<td>
									<div class="d-flex justify-content-start">
										<div class="col text-center">
											<lg-input v-model="o.bank_fee" :type="'float'" :mask="'9{1,},9{2}'" :width="60"  class="d-inline-flex" :disabled="o.commit==1"/>
										</div>
									</div>
								</td>
								<td>
									<div class="col w-100">
										<div class="row">
											<h3><span onclick="navigator.clipboard.writeText(this.innerText);" style="cursor: pointer;" class="badge bg-success ms-1 mt-n1">{{formatter_money[o.currency].format(o.sum)}}</span></h3>
										</div>
										<div class="d-flex flex-nowrap">
											<lg-input v-model="o.result_sum" :type="'float'" :mask="'9{1,},9{2}'" :width="120"  class="d-inline-flex" :disabled="o.status>2||o.commit==1" />
										</div>
									</div>
								</td>
								<td>
									<div class="d-flex justify-content-start">
										<div class="col text-center">
											<lg-select v-model="o.terms" :options="setting.field.order.setting.field.terms.data" :disabled="o.status>2||o.commit==1"/>
										</div>
									</div>
								</td>
								<td>
									<div class="d-flex justify-content-start">
										<div class="col text-center">
											<lg-select v-model="o.forwarder" :options="setting.field.order.setting.field.forwarder.data" :disabled="o.commit==1"/>
										</div>
									</div>
								</td>
								<td>
									<div class="d-flex justify-content-start">
										<div class="col text-center">
											<lg-select v-model="o.ship_to" :options="setting.field.order.setting.field.ship_to.data" :disabled="o.commit==1"/>
										</div>
									</div>
								</td>
								<td>
									<div class="d-flex justify-content-start">
										<div class="col text-center">
											<lg-input v-model="o.aog_fee" :type="'float'" :mask="'9{1,},9{2}'" :width="60"  class="d-inline-flex" :disabled="o.commit==1"/>
										</div>
									</div>
								</td>
								<td>
									<div class="badge d-inline" :class="setting.field.status.data['id_'+o.status].color">
										<strong>{{setting.field.spares.setting.field.status.data['id_'+o.status].title}}</strong>
									</div>
								</td>
								<td>
									<div class="badge d-inline" :class="setting.field.order.setting.field.payed.data['id_'+o.payed].color">
										<strong>{{setting.field.order.setting.field.payed.data['id_'+o.payed].title}}</strong>
									</div>
								</td>
								<td class="text-left">
									<div class="me-3">
										<div class="dropdown">
											<a class="dropdown-toggle text-reset" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<span  style="cursor: pointer;" class="fe fe-download text-reset"></span>
											</a>
											<div class="dropdown-menu dropdown-menu-end">
												<a :href="'https://api-skyparts.litegroup.ru/release/dowload_order/'+o.id+'/?token='+token" target="_blank" class="dropdown-item">Заказ</a>
												<template v-for="(f,k) in data.order[k].file">
													<a v-if="f.file.id!=0" :href="'https://api-skyparts.litegroup.ru/release/child/'+this.data.__id+'/order/child/'+o.id+'/file/element/'+f.id+'/file/download/?token='+token" target="_blank" class="dropdown-item">{{f.title}}</a>
												</template>
											</div>
										</div>
									</div>
									<a @click="order_file_show_modal(k,o.id)" class="text-reset me-3" style="cursor: pointer;" title="Файлы" >
										<i class="fe fe-paperclip text-reset"></i>
									</a>
								</td>
								<td class="td-actions">
									<div class="row">
										<div class="d-flex justify-content-between align-items-center">
											<div class="">
												<button @click="modal_order_payment_show(k)" type="button" class="btn btn-outline-primary btn-sm mb-2">Оплата</button>
											</div>
										</div>
										<div v-if="get_next_status_order[k]!=false" class="col mt-2">
											<button @click="set_next_status_order(k,o.id,o.status)" type="button" class="btn btn-outline-primary btn-sm mb-2">{{get_next_status_order[k]}}</button>
										</div>
									</div>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div v-if="tab_active=='INVOICE'" class="card px-0">
					<div  class="">
						<table class="table table-sm table-nowrap table-nobcollapse card-table">
							<thead class="">
							<tr>
								<th style="width:110px;">#</th>
								<th style="width:90px;">Дата</th>
								<th style="width:90px;">Дата Prof.</th>
								<th style="width:90px;">Cust. PO#</th>
								<th>P/N</th>
								<th style="width:160px;">Сумма</th>
								<th style="width:400px;">Посредник</th>
								<th style="width:400px;">Условия</th>
								<th style="width:140px;">Bank</th>
								<th style="width:100px;"># of Boxes<br/>Weight</th>
								<th style="width:100px;"># накладной</th>
								<th style="width:100px;"># со склада</th>
								<th style="width:100px;">Место</th>
								<th style="width:100px;">Оплата</th>
								<th style="width:170px;">Статус</th>
								<th style="width:170px;"></th>
							</tr>
							</thead>
							<tbody ref="price_result_rub" class="list px-2">
							<tr v-for="(i,k) in data.invoice" :key="k">
								<td>
									{{i.n_view}}
								</td>
								<td>
									<lg-date v-model="i.date" class="form-control-sm" :disabled="i.commit==1"/>
								</td>
								<td>
									<lg-date v-model="i.date_proforma" class="form-control-sm" :disabled="i.commit==1"/>
								</td>
								<td>
									<lg-input v-model="i.cust_po"  :width="70" class="mb-1" :disabled="i.commit==1"/><br/>
									<lg-date v-model="i.order_date_edit" class="form-control-sm" :disabled="i.commit==1"/>
								</td>
								<td>
									<div v-for="i_s in i.spares">
										{{i_s.pn}} ({{i_s.count}})
										<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-1 border-bottom"></div>
									</div>
								</td>
								<td>
									<template v-if="i.spares[Object.keys(i.spares)[0]].currency_invoice_lotos!=''">
									{{formatter_money[i.spares[Object.keys(i.spares)[0]].currency_invoice_lotos].format(i.sum)}}
									</template>
								</td>
								<td>
									Sold To:<br/>
									<lg-select v-model="i.intermediary" :options="setting.field.invoice.setting.field.intermediary.data" :disabled="i.commit==1"/><br/>
									Ship to:<br/>
									<lg-select v-model="i.ship_to" :options="setting.field.invoice.setting.field.ship_to.data" :disabled="i.commit==1"/><br/>
									Forwarder:<br/>
									<lg-select v-model="i.forwarder" :options="setting.field.invoice.setting.field.forwarder.data" :disabled="i.commit==1"/>
								</td>
								<td>
									Payment terms:<br/>
									<lg-select v-model="i.terms" :options="setting.field.invoice.setting.field.terms.data" :disabled="i.commit==1"/><br/>
									Delivery Terms:<br/>
									<lg-input v-model="i.delivery_terms" :type="'string'" :width="70" :disabled="i.commit==1"/><br/>
									Ship Data:<br/>
									<lg-date v-model="i.ship_data"  class="form-control form-control-sm me-1" :disabled="i.commit==1"/>
								</td>
								<td>
									<div class="d-flex justify-content-start">
										<div class="col text-center">
											<lg-select v-model="i.bank" :options="setting.field.invoice.setting.field.bank.data" :disabled="i.commit==1"/>
										</div>
									</div>
								</td>
								<td>
									<lg-input v-model="i.count_boxes" :type="'int'" :mask="'9{1,}'" :width="70" class="mb-1" :disabled="i.commit==1"/><br/>
									<lg-input v-model="i.weight" :type="'string'"  :width="70" class="mb-1" :disabled="i.commit==1"/>
								</td>
								<td>
									<lg-input v-model="i.transport_invoice_n" :type="'string'"  :width="70" class="mb-1" :disabled="i.status<5||i.commit==1" />
								</td>
								<td>
									<lg-input v-model="i.warehouse" :type="'string'"  :width="70" class="mb-1" :disabled="i.status<5||i.commit==1" />
								</td>
								<td style="width:50px;">
									<lg-select v-model="i.location" :options="setting.field.invoice.setting.field.location.data" :disabled="i.status<5"/>
								</td>
								<td style="width:50px;">
									<lg-select v-model="i.payed" :options="setting.field.invoice.setting.field.payed.data" :disabled="i.status<5"/>
								</td>
								<td style="width:50px;">
									<lg-select v-model="invoice_status[k]" :options="setting.field.invoice.setting.field.status.data" :disabled="i.status<5"/>
								</td>
								<td>
									<div class="row">
										<div class="d-flex justify-content-between align-items-center">
											<div v-if="(i.date!=''||i.date_proforma)&&i.bank!=0" class="me-2">
												<div class="dropdown">
													<a class="dropdown-toggle text-reset" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<span  style="cursor: pointer;" class="fe fe-download text-reset"></span>
													</a>
													<div  class="dropdown-menu dropdown-menu-end">
														<a v-if="i.date!=''" :href="'https://api-skyparts.litegroup.ru/release/dowload_invoice/'+i.id+'/invoice/1/?token='+token" target="_blank" class="dropdown-item">Invoice</a>
														<a v-if="i.date!=''" :href="'https://api-skyparts.litegroup.ru/release/dowload_invoice/'+i.id+'/invoice/2/?token='+token" target="_blank" class="dropdown-item">Invoice new</a>
														<a v-if="i.date_proforma" :href="'https://api-skyparts.litegroup.ru/release/dowload_invoice/'+i.id+'/proforma/?token='+token" target="_blank" class="dropdown-item">Proforma</a>
													</div>
												</div>
											</div>
											<div class="me-2">
												<span v-if="i.commit==0" @click="delete_invoice(i.id,k)" style="cursor: pointer;" class="fe fe-trash ms-2"></span>
												&nbsp;&nbsp;&nbsp;
												<span @click="invoice_commit(i.id,k,1)" v-if="auth[1]&&i.commit==0" class="fe fe-square mr-4"></span>
												<span @click="invoice_commit(i.id,k,0)" v-if="auth[1]&&i.commit==1" class="fe fe-check-square mr-4"></span>
											</div>
											<div  class="">
												<button v-if="false" @click="modal_invoice_payment_show(k)" type="button" class="btn btn-outline-primary btn-sm mb-2">Оплата</button>
											</div>
										</div>
									</div>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div v-if="tab_active=='PROFORMA'" class="card px-0">
					<div  class="">
						<table class="table table-sm table-nowrap table-nobcollapse card-table">
							<thead class="">
							<tr>
								<th style="width:110px;">#</th>
								<th style="width:90px;">Дата</th>
								<th style="width:90px;">Cust. PO#</th>
								<th>P/N</th>
								<th style="width:160px;">Сумма</th>
								<th style="width:400px;">Посредник</th>
								<th style="width:400px;">Условия</th>
								<th style="width:140px;">Bank</th>
								<th style="width:100px;"># of Boxes<br/>Weight</th>
								<th style="width:100px;"># накладной</th>
								<th style="width:100px;"># со склада</th>
								<th style="width:100px;">Оплата</th>
								<th style="width:170px;"></th>
							</tr>
							</thead>
							<tbody ref="price_result_rub" class="list px-2">
							<tr v-for="(p,k) in data.proforma" :key="k">
								<td>
									{{p.n_view}}
								</td>
								<td>
									<lg-date v-model="p.date" class="form-control-sm" :disabled="p.commit==1"/>
								</td>
								<td>
									<lg-input v-model="p.cust_po"  :width="70" class="mb-1"  :disabled="p.commit==1"/><br/>
									<lg-date v-model="p.order_date_edit" class="form-control-sm"  :disabled="p.commit==1"/>
								</td>
								<td>
									<div v-for="p_s in p.spares">
										{{p_s.pn}} ({{p_s.count}})
										<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-1 border-bottom"></div>
									</div>
								</td>
								<td>
									{{formatter_money[p.spares[Object.keys(p.spares)[0]].currency_invoice_lotos].format(p.sum)}}
								</td>
								<td>
									Sold To:<br/>
									<lg-select v-model="p.intermediary" :options="setting.field.proforma.setting.field.intermediary.data" :disabled="p.commit==1"/><br/>
									Ship to:<br/>
									<lg-select v-model="p.ship_to" :options="setting.field.proforma.setting.field.ship_to.data" :disabled="p.commit==1"/><br/>
									Forwarder:<br/>
									<lg-select v-model="p.forwarder" :options="setting.field.proforma.setting.field.forwarder.data" :disabled="p.commit==1"/>
								</td>
								<td>
									Payment terms:<br/>
									<lg-select v-model="p.terms" :options="setting.field.proforma.setting.field.terms.data"  :disabled="p.commit==1"/><br/>
									Delivery Terms:<br/>
									<lg-input v-model="p.delivery_terms" :type="'string'" :width="70" :disabled="p.commit==1" /><br/>
									Ship Data:<br/>
									<lg-date v-model="p.ship_data"  class="form-control form-control-sm me-1"  :disabled="p.commit==1"/>
								</td>
								<td>
									<div class="d-flex justify-content-start">
										<div class="col text-center">
											<lg-select v-model="p.bank" :options="setting.field.proforma.setting.field.bank.data" :disabled="p.commit==1"/>
										</div>
									</div>
								</td>
								<td>
									<lg-input v-model="p.count_boxes" :type="'int'" :mask="'9{1,}'" :width="70" class="mb-1"  :disabled="p.commit==1"/><br/>
									<lg-input v-model="p.weight" :type="'string'"  :width="70" class="mb-1"  :disabled="p.commit==1"/>
								</td>
								<td>
									<lg-input v-model="p.transport_invoice_n" :type="'string'"  :width="70" class="mb-1"  :disabled="p.commit==1"/>
								</td>
								<td>
									<lg-input v-model="p.warehouse" :type="'string'"  :width="70" class="mb-1"  :disabled="p.commit==1" />
								</td>
								<td style="width:50px;">
									<lg-select v-model="p.payed" :options="setting.field.proforma.setting.field.payed.data" />
								</td>
								<td>
									<div class="row">
										<div class="d-flex justify-content-between align-items-center">
											<div v-if="p.date!=''&&p.bank!=0" class="me-2">
												<div class="dropdown">
													<a class="dropdown-toggle text-reset" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<span  style="cursor: pointer;" class="fe fe-download text-reset"></span>
													</a>
													<div  class="dropdown-menu dropdown-menu-end">
														<a :href="'https://api-skyparts.litegroup.ru/release/dowload_proforma/'+p.id+'/?token='+token" target="_blank" class="dropdown-item">Proforma</a>
													</div>
												</div>
											</div>
											<div class="me-2">
												<span @click="delete_proforma(p.id,k)" style="cursor: pointer;" class="fe fe-trash ms-2"></span>
												&nbsp;&nbsp;&nbsp;
												<span @click="proforma_commit(p.id,k,1)" v-if="auth[1]&&p.commit==0" class="fe fe-square mr-4"></span>
												<span @click="proforma_commit(p.id,k,0)" v-if="auth[1]&&p.commit==1" class="fe fe-check-square mr-4"></span>
											</div>
										</div>
									</div>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div v-if="tab_active=='PAYMENT_CUSTOMERS'" class="card px-0">
					<div  class="">
						<table class="table table-sm table-nowrap table-nobcollapse card-table">
							<thead class="">
							<tr>
								<th style="width:110px;">#</th>
								<th style="width:90px;">Дата</th>
								<th style="width:160px;">Сумма</th>
								<th style="width:160px;">Валюта</th>
								<th style="width:400px;">Посредник</th>
								<th style="width:170px;">Статус</th>
								<th style="width:170px;"></th>
							</tr>
							</thead>
							<tbody class="list px-2">
							<tr v-for="(pc,k) in data.payment_customers" :key="k">
								<td>
									<lg-input v-model="pc.n"  :width="70" class="mb-1" :disabled="pc.commit==1"/>
								</td>
								<td>
									<lg-date v-model="pc.date" class="form-control-sm"  :disabled="pc.commit==1"/>
								</td>
								<td>
									<lg-input v-model="pc.sum"  :width="70" class="mb-1"  :disabled="pc.commit==1"/>
								</td>
								<td>
									<lg-select v-model="pc.currency" :options="setting.field.payment_customers.setting.field.currency.data" :disabled="pc.commit==1"/><br/>
								</td>
								<td>
									<lg-select v-model="pc.intermediary" :options="setting.field.payment_customers.setting.field.intermediary.data" :disabled="pc.commit==1"/><br/>
								</td>
								<td>
									<lg-select v-model="pc.status" :options="setting.field.payment_customers.setting.field.status.data" :disabled="pc.commit==1"/><br/>
								</td>
								<td class="td-actions">
									<div class="row">
										<div class="me-2">
											<span v-if="pc.commit==0" @click="delete_payment_customerse(pc.id,k)" style="cursor: pointer;" class="fe fe-trash ms-2"></span>
											&nbsp;&nbsp;&nbsp;
											<span @click="payment_customers_commit(pc.id,k,1)" v-if="auth[1]&&pc.commit==0" class="fe fe-square mr-4"></span>
											<span @click="payment_customers_commit(pc.id,k,0)" v-if="auth[1]&&pc.commit==1" class="fe fe-check-square mr-4"></span>
										</div>
									</div>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div v-if="tab_active=='MESSAGE'" class="card px-0">
					<div  class="">
						<table class="table table-sm table-nowrap table-nobcollapse card-table">
							<thead class="">
							<tr>
								<th style="width:90px;">Дата</th>
								<th style="width:160px;">Пользователь</th>
								<th style="width:400px;">Сообщение</th>
							</tr>
							</thead>
							<tbody class="list px-2">
							<tr v-for="(m,k) in data.message" :key="k">
								<td>
									{{m.date}}
								</td>
								<td>
									{{m.user[Object.keys(m.user)[0]].title}}
								</td>
								<td v-html="m.body.replace(/\n/g, '<br>')"></td>
							</tr>
							</tbody>
						</table>
					</div>
					<div class="row">
						<p>
							<textarea v-model="message_new_text" class="form-control form-control-sm mt-3" placeholder="Текст" rows="3"></textarea>
							<div class="form-group float-end mb-0" style="margin-top: 15px; margin-right: 20px;"><button @click="message_send()" type="button" class="btn btn-success"> Отправить</button></div>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>

	<confirm_delete ref="confirm_delete_spares" @del="do_delete_spares" :text="'Вы уверенные что хотите удалить P/N'"/>
	<confirm_delete ref="confirm_delete_invoice" @del="do_delete_invoice" :text="'Вы уверенные что хотите удалить INVOICE'"/>
	<confirm_delete ref="confirm_delete_proforma" @del="do_delete_proforma" :text="'Вы уверенные что хотите удалить proforma'"/>
	<confirm_delete ref="confirm_delete_payment_customerse" @del="do_delete_payment_customerse" :text="'Вы уверенные что хотите удалить оплату от клиента'"/>
	<release-edit-p-n ref="release_edit_pn" @reload="reload" />

</template>
<script>
import axios from 'axios';
import config from '@/config.js'
import {  updatedDiff } from 'deep-object-diff';
import objectAssignDeep from 'object-assign-deep'
import router from "../../router/router";
import { Modal } from 'bootstrap';
import LgInput from "../../components/UI/Input";
import LgSelect from "../../components/UI/Select";
import confirm_delete from '@/components/UI/ConfirmDelete.vue'
import ReleaseEditPN from '@/pages/release/ReleaseEditPN.vue'
import OfferEditPN from "@/pages/offer/OfferEditPN";

export default {
	components: {LgSelect, LgInput, confirm_delete, ReleaseEditPN,  'offer-edit-pn': OfferEditPN },
	data() {
			return {
				auth: {0:false,1:false,2:false},
				data: null,
				data_old: null,
				data_calc: {},
				calc_empty: {
					price_provider_total:'',
					price_invoice_lotos_total:'',
					price_result_usd:'',
					price_result_rub_total:'',
					price_provider_15_eur_total:''
				},
				setting: null,
				currency: null,
				formatter_money: null,

				save_list: {},
				data_saving: false,
				current: false,
				back_list: [],

				calc_provider_margin: 1.15,

				do_reload: false,

				tab_active: 'SPARES',

				add_spares_modal: null,
				add_spares_pn: '',
				add_spares_provider: 0,

				invoice_modal: null,
				invoice_spares_select: {},
				invoice_spares_select_count: {},
				invoice_type: 'direct',
				add_invoice_error_currency: false,
				add_invoice_error_count: false,
				invoice_spares_select_checkall: false,

				proforma_modal: null,
				proforma_spares_select: {},
				proforma_spares_select_count: {},
				add_proforma_error_currency: false,
				add_proforma_error_count: false,
				proforma_spares_select_checkall: false,

				modal_order_payment: null,
				order_payment_k: 0,

				modal_invoice_payment: null,
				invoice_payment_k: 0,
				invoice_status: {},
				invoice_status_old: {},

				modal_order_file: null,
				order_file_active_k: '',
				order_file_active_id: 0,

				error: '',

				token: localStorage.getItem('token') || '',

				delete_spares_id: 0,
				delete_spares_k: '',
				delete_invoice_id: 0,
				delete_invoice_k: '',
				delete_proforma_id: 0,
				delete_proforma_k: '',

				message_new_text: ''
			}
		},
		mounted ()
		{
			this.load()
		},
		methods: {
			async load()
			{
				axios({url: config.API_LOCATION + 'release/edit/' + this.$route.params.id + '/'})
					.then(resp => {
						let data=resp.data.data[Object.keys(resp.data.data)[0]]
						let n=1
						for(const s_k in data.spares) {
							this.data_calc[s_k] = structuredClone(this.calc_empty)
							data.spares[s_k]['n']=n++
							if(data.spares[s_k].order_count-data.spares[s_k].invoice_count_sum>0) {
								this.invoice_spares_select[s_k] = 0;
								this.invoice_spares_select_count[s_k]=data.spares[s_k].order_count-data.spares[s_k].invoice_count_sum
							}
							if(data.spares[s_k].order_count-data.spares[s_k].proforma_count_sum>0) {
								this.proforma_spares_select[s_k] = 0;
								this.proforma_spares_select_count[s_k]=data.spares[s_k].order_count-data.spares[s_k].proforma_count_sum
							}
						}

						for(const i_k in data.invoice)
							this.invoice_status[i_k]=data.invoice[i_k].status
						this.invoice_status_old=structuredClone(this.invoice_status)

						this.data = data
						this.data_old = structuredClone(this.data)
						this.setting = resp.data.setting
						this.currency=resp.data.currency
						for(const s_k in data.spares)
							this.calc_all(s_k)

						for(const g_k in resp.data.auth.group)
							this.auth[resp.data.auth.group[g_k]] = true
					})
					.catch(err => {
						console.log(err)
					})

				this.add_spares_modal = new Modal(document.getElementById('modal_add_spares'))
				this.invoice_modal = new Modal(document.getElementById('modalMembers'))
				this.proforma_modal = new Modal(document.getElementById('modalProforma'))
				this.modal_order_payment = new Modal(document.getElementById('modal_order_payment'))
				this.modal_invoice_payment = new Modal(document.getElementById('modal_invoice_payment'))
				this.modal_order_file = new Modal(document.getElementById('modal_order_file'))

				this.formatter_money = {
					USD: new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'USD'}),
					EUR: new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'EUR'}),
					RUB: new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB'}),
					AED: new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'AED'}),
					CNY: new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'CNY'}),
				}
				setTimeout(this.save, 10000)
			},
			async reload()
			{
				axios({url: config.API_LOCATION + 'release/reload/' + this.data.__id + '/'})
					.then(resp => {
						this.data = resp.data.data[Object.keys(resp.data.data)[0]]
						this.invoice_spares_select={}
						this.invoice_spares_select_checkall=false
						this.data_calc={}
						for (let s_k in this.data.spares) {
							this.data_calc[s_k] = structuredClone(this.calc_empty)
							if(this.data.spares[s_k].order_count-this.data.spares[s_k].invoice_count_sum>0){
								this.invoice_spares_select[s_k] = 0;
								this.invoice_spares_select_count[s_k]=this.data.spares[s_k].order_count-this.data.spares[s_k].invoice_count_sum
							}
							if(this.data.spares[s_k].order_count-this.data.spares[s_k].proforma_count_sum>0){
								this.proforma_spares_select[s_k] = 0;
								this.proforma_spares_select_count[s_k]=this.data.spares[s_k].order_count-this.data.spares[s_k].proforma_count_sum
							}
						}

						this.invoice_status={}
						for(const i_k in this.data.invoice)
							this.invoice_status[i_k]=this.data.invoice[i_k].status
						this.invoice_status_old=structuredClone(this.invoice_status)

						this.data_old = structuredClone(this.data)
						this.setting = resp.data.setting

						for(const s_k in this.data.spares)
							this.calc_all(s_k)
					})
					.catch(err => {
						console.log(err)
					})
			},
			async save()
			{
				if (Object.keys(this.save_list).length == 0 || this.data_saving) {
					setTimeout(this.save, 10000)
					return
				}
				this.data_saving = true
				let save_data = {row: {}}
				save_data.row[this.data.__id] = structuredClone(this.save_list)
				this.save_list = {}

				axios({url: config.API_LOCATION + 'release/save_list/', data: save_data, method: 'POST'})
					.then(resp => {
						if (resp.data.status == 'ok') {
							this.data_saving = false
							this.current = true
							if(this.do_reload) {
								this.do_reload=false
								this.reload()
							}
							setTimeout(this.save, 10000)
						}
					})
					.catch(err => {

					})
			},
			async save_and_exit()
			{
				this.data_saving = true
				let save_data = {row: {}}
				save_data.row[this.data.__id] = structuredClone(this.save_list)
				this.save_list = {}

				axios({url: config.API_LOCATION + 'release/save_list/', data: save_data, method: 'POST'})
					.then(resp => {
						if (resp.data.status == 'ok') {
							this.data_saving = false
							this.current = true
							router.push('/release')
						}
					})
					.catch(err => {

					})
			},
			save_manual()
			{
				this.data_saving = true
				let save_data = {row: {}}
				save_data.row[this.data.__id] = structuredClone(this.save_list)
				this.save_list = {}

				axios({url: config.API_LOCATION + 'release/save_list/', data: save_data, method: 'POST'})
					.then(resp => {
						if (resp.data.status == 'ok') {
							this.data_saving = false
							this.current = true
							if(this.do_reload) {
								this.do_reload=false
								this.reload()
							}
						}
					})
					.catch(err => {

					})
			},
			backspace()
			{
				if (this.back_list.length == 0)
					return
//			let t=this.data_old
				eval("this.data_old" + this.back_list[this.back_list.length - 1].name + "='" + this.back_list[this.back_list.length - 1].value + "'")
//			console.log("this.data_old"+this.back_list[this.back_list.length-1].name+"='"+this.back_list[this.back_list.length-1].value+"'")
//			console.log(t)
//			console.log(this.data_old)
				let update = updatedDiff(this.data, this.data_old)
				this.data = structuredClone(this.data_old)
				objectAssignDeep(this.save_list, update)
				this.back_list.pop()
//			console.log(this.back_list.slice(1,1))
			},
			save_empty()
			{
				if (this.current)
					return
				let save_data = {row: {}}
				save_data.row[this.data.__id] = {}
				axios({url: config.API_LOCATION + 'release/save_list/', data: save_data, method: 'POST'})
					.then(resp => {
						if (resp.data.status == 'ok') {
							this.current = true
						}
					})
					.catch(err => {

					})
			},

			calc_all(k)
			{
				this.calc_price_provider_total(k)
				this.calc_price_invoice_lotos_total(k)
				this.calc_price_result_usd_total(k)
				this.calc_price_result_rub_total(k)
			},
			calc_price_provider_total(k)
			{
				let p=this.data.spares[k].order_count * this.data.spares[k].price_provider
				p=Math.round(p*100)/100
				if(isNaN(p))
					p=''
				else {
					p = this.formatter_money[this.data.spares[k].currency_provider].format(p)
				}
				this.data_calc[k].price_provider_total=p;
			},
			calc_price_invoice_lotos_total(k)
			{
				let p=this.data.spares[k].order_count * this.data.spares[k].price_invoice_lotos
				p=Math.round(p*100)/100
				if(isNaN(p))
					p=''
				else {
					p = this.formatter_money[this.data.spares[k].currency_invoice_lotos].format(p)
				}
				this.data_calc[k].price_invoice_lotos_total=p;
			},
			calc_price_result_usd_total(k)
			{
				let p=this.data.spares[k].order_count * this.data.spares[k].price_result_usd
				p=Math.round(p*100)/100
				if(isNaN(p))
					p=''
				else {
					p = this.formatter_money.USD.format(p)
				}
				this.data_calc[k].price_result_usd_total=p;
			},
			calc_price_result_rub_total(k)
			{
				let p=this.data.spares[k].order_count * this.data.spares[k].price_result_rub
				p=Math.round(p*100)/100
				if(isNaN(p))
					p=''
				else {
					p = this.formatter_money.RUB.format(p)
				}
				this.data_calc[k].price_result_rub_total=p;
			},

			client_order_upload_file(e)
			{
				let formData = new FormData();
				formData.append('client_order_file', e.target.files[0]);
				axios({
					url: config.API_LOCATION+'release/element/'+this.data.__id+'/client_order_file/upload/',
					data: formData,
					method: 'POST',
					headers: {'Content-Type': 'multipart/form-data'}
				})
					.then(resp => {
						if(resp.data.status=='ok') {
							this.data_old.client_order_file=resp.data.data.client_order_file
							this.data=structuredClone(this.data_old)
							this.save_empty()
						}
					})
					.catch(err => {

					})
			},
			client_order_delete_file(s_k,s_c_id,s_c_k)
			{
				axios({url: config.API_LOCATION+'release/element/'+this.data.__id+'/client_order_file/delete_file/' })
					.then(resp => {
						if(resp.data.status=='ok') {
							this.data_old.client_order_file.id=0
							this.data=structuredClone(this.data_old)
							this.save_empty()
						}
					})
					.catch(err => {

					})
			},
			async delete_spares(id,k)
			{
				this.delete_spares_id=id
				this.delete_spares_k=k
				this.$refs['confirm_delete_spares'].show()
			},
			async do_delete_spares(id,k)
			{
				await this.save_empty()
				axios({url: config.API_LOCATION+'release/child/'+this.data.__id+'/spares/delete/'+this.delete_spares_id+'/' })
					.then(resp => {
						if(resp.data.status=='ok') {
							delete this.data_old.spares[this.delete_spares_k]
							this.data=structuredClone(this.data_old)
							this.reload()
						}
						this.delete_spares_k=''
					})
					.catch(err => {

					})
				this.delete_spares_id=0
			},
			order_date_copy(o_k,o_s_k)
			{
				const date=this.data.order[o_k].spares[o_s_k].scheduled_delivery_date
				const place=this.data.order[o_k].spares[o_s_k].result_dispatch_place
				for(const k in this.data.order[o_k].spares) {
					this.data.order[o_k].spares[k].scheduled_delivery_date = date
					this.data.order[o_k].spares[k].result_dispatch_place = place
					if(this.$refs['order_'+o_k+'_spares_'+k+'update_value']!==undefined){
						let el=this.$refs['order_'+o_k+'_spares_'+k+'_result_dispatch_place']
						if(Array.isArray(el)&&el.length>0)
							el=el[0]
						el.update_value(place)
					}
				}
			},
			async delete_invoice(id,k)
			{
				this.delete_invoice_id=id
				this.delete_invoice_k=k
				this.$refs['confirm_delete_invoice'].show()
			},
			async do_delete_invoice()
			{
				await this.save_empty()
				axios({url: config.API_LOCATION+'release/child/'+this.data.__id+'/invoice/delete/'+this.delete_invoice_id+'/' })
					.then(resp => {
						if(resp.data.status=='ok') {
							delete this.data_old.spares[this.delete_invoice_k]
							this.data=structuredClone(this.data_old)
							this.reload()
						}
						this.delete_spares_k=''
					})
					.catch(err => {

					})
				this.delete_invoice_id=0
			},
			async invoice_commit(id,k,set)
			{
				await this.save_empty()
				axios({url: config.API_LOCATION+'release/invoice_commit/'+this.data.__id+'/'+id+'/'+set+'/' })
					.then(resp => {
						if(resp.data.status=='ok') {
							this.reload()
						}
					})
					.catch(err => {

					})
			},
			async proforma_commit(id,k,set)
			{
				await this.save_empty()
				axios({url: config.API_LOCATION+'release/proforma_commit/'+this.data.__id+'/'+id+'/'+set+'/' })
					.then(resp => {
						if(resp.data.status=='ok') {
							this.reload()
						}
					})
					.catch(err => {

					})
			},
			async delete_proforma(id,k)
			{
				this.delete_proforma_id=id
				this.delete_proforma_k=k
				this.$refs['confirm_delete_proforma'].show()
			},
			async do_delete_proforma()
			{
				await this.save_empty()
				axios({url: config.API_LOCATION+'release/child/'+this.data.__id+'/proforma/delete/'+this.delete_proforma_id+'/' })
					.then(resp => {
						if(resp.data.status=='ok') {
							delete this.data_old.spares[this.delete_proforma_k]
							this.data=structuredClone(this.data_old)
							this.reload()
						}
						this.delete_proforma_k=''
					})
					.catch(err => {

					})
				this.delete_proforma_id=0
			},
			async  delete_payment_customerse(id,k)
			{
				this.delete_payment_customerse_id=id
				this.delete_payment_customerse_k=k
				this.$refs['confirm_delete_payment_customerse'].show()
			},
			async do_delete_payment_customerse()
			{
				await this.save_empty()
				axios({url: config.API_LOCATION+'release/child/'+this.data.__id+'/payment_customers/delete/'+this.delete_payment_customerse_id+'/' })
					.then(resp => {
						if(resp.data.status=='ok') {
							delete this.data_old.spares[this.delete_payment_customerse_k]
							this.data=structuredClone(this.data_old)
							this.reload()
						}
					})
					.catch(err => {

					})
				this.delete_payment_customerse_id=0
			},
			async payment_customers_commit(id,k,set)
			{
				await this.save_empty()
				axios({url: config.API_LOCATION+'release/payment_customers_commit/'+this.data.__id+'/'+id+'/'+set+'/' })
					.then(resp => {
						if(resp.data.status=='ok') {
							this.data.payment_customers[k].commit=set
							this.data_old.payment_customers[k].commit=set
						}
					})
					.catch(err => {

					})
			},
			async copy_release()
			{
				await this.save_manual()
				axios({url: config.API_LOCATION+'release/copy/'+this.data.id+'/' })
					.then(resp => {
						if(resp.data.status=='ok') {
							router.push('/release/edit/'+resp.data.id);
						}
					})
					.catch(err => {
						console.log(err)
					})
			},
			async modal_add_spares()
			{
				this.add_spares_pn=''
				this.add_spares_provider=0
				this.add_spares_modal.show()
			},
			async add_spares()
			{
				this.add_spares_modal.hide();
				await this.save_empty();
				axios({url: config.API_LOCATION + 'release/add_spares/', data: {
						release___id:this.data.__id,
						pn:this.add_spares_pn,
						provider:this.add_spares_provider
					}, method: 'POST'})
					.then(resp => {
						if (resp.data.status == 'ok') {
							this.reload();
							this.tab_active='SPARES';
						}
					})
					.catch(err => {

					})
			},
			async modal_add_invoice()
			{
				if(Object.keys(this.invoice_spares_select).length>0) {
					await this.reload()
					for (let s_k in this.data.spares) {
						if(this.data.spares[s_k].order_count-this.data.spares[s_k].invoice_count_sum>0){
							if(this.$refs['invoice_spares_select'+s_k]!==undefined) {
								let el = this.$refs['invoice_spares_select' + s_k]
								if (Array.isArray(el) && el.length > 0)
									el = el[0]
								el.update_value(0)
								el = this.$refs['invoice_spares_select_count' + s_k]
								if (Array.isArray(el) && el.length > 0)
									el = el[0]
								el.update_value(this.data.spares[s_k].order_count-this.data.spares[s_k].invoice_count_sum)
							}
						}
					}
					this.invoice_modal.show()
				}
			},
			modal_add_invoice_checkall()
			{
				for (let s_k in this.data.spares) {
					if(this.data.spares[s_k].order_count-this.data.spares[s_k].invoice_count_sum>0){
						this.invoice_spares_select[s_k] = (this.invoice_spares_select_checkall?1:0);
						let el=this.$refs['invoice_spares_select'+s_k]
						if(Array.isArray(el)&&el.length>0)
							el=el[0]
						el.update_value(this.invoice_spares_select[s_k])
					}
				}
			},
			async add_invoice()
			{
				let ok=true
				let currency=''
				for(const s_k in this.data.spares){
					if(this.invoice_spares_select[s_k]==1){
						if(currency==''){
							currency=this.data.spares[s_k].currency_invoice_lotos
						}
						if(currency!=this.data.spares[s_k].currency_invoice_lotos){
							ok=false
							this.add_invoice_error_currency=true
							break
						}
						if(this.invoice_spares_select_count[s_k]>this.data.spares[s_k].order_count-this.data.spares[s_k].invoice_count_sum){
							ok=false
							this.add_invoice_error_count=true
							break
						}
					}
				}
				if(!ok||currency==''){
					return
				}
				else {
					this.add_invoice_error_currency = false
					this.add_invoice_error_count = false
				}

				this.invoice_modal.hide();
				await this.save_empty();
				axios({url: config.API_LOCATION + 'release/add_invoice/', data: {
					release___id:this.data.__id,
					type:this.invoice_type,
					spares:this.invoice_spares_select,
					count:this.invoice_spares_select_count
				}, method: 'POST'})
					.then(resp => {
						if (resp.data.status == 'ok') {
							this.reload();
							this.tab_active='INVOICE';
						}
					})
					.catch(err => {

					})
			},
			async modal_add_proforma()
			{
				if(Object.keys(this.proforma_spares_select).length>0) {
					await this.reload()
					for (let s_k in this.data.spares) {
						if(this.data.spares[s_k].order_count-this.data.spares[s_k].proforma_count_sum>0){
							if(this.$refs['proforma_spares_select'+s_k]!==undefined) {
								let el = this.$refs['proforma_spares_select' + s_k]
								if (Array.isArray(el) && el.length > 0)
									el = el[0]
								el.update_value(0)
								el = this.$refs['proforma_spares_select_count' + s_k]
								if (Array.isArray(el) && el.length > 0)
									el = el[0]
								el.update_value(this.data.spares[s_k].order_count-this.data.spares[s_k].proforma_count_sum)
							}
						}
					}
					this.proforma_modal.show()
				}
			},
			modal_add_proforma_checkall()
			{
				for (let s_k in this.data.spares) {
					if(this.data.spares[s_k].order_count-this.data.spares[s_k].proforma_count_sum>0){
						this.proforma_spares_select[s_k] = (this.proforma_spares_select_checkall?1:0);
						let el=this.$refs['proforma_spares_select'+s_k]
						if(Array.isArray(el)&&el.length>0)
							el=el[0]
						el.update_value(this.proforma_spares_select[s_k])
					}
				}
			},
			async add_proforma()
			{
				let ok=true
				let currency=''
				for(const s_k in this.data.spares){
					if(this.proforma_spares_select[s_k]==1){
						if(currency==''){
							currency=this.data.spares[s_k].currency_invoice_lotos
						}
						if(currency!=this.data.spares[s_k].currency_invoice_lotos){
							ok=false
							this.add_proforma_error_currency=true
							break
						}
						if(this.proforma_spares_select_count[s_k]>this.data.spares[s_k].order_count-this.data.spares[s_k].proforma_count_sum){
							ok=false
							this.add_proforma_error_count=true
							break
						}
					}
				}
				if(!ok||currency==''){
					return
				}
				else {
					this.add_proforma_error_currency = false
					this.add_proforma_error_count = false
				}

				this.proforma_modal.hide();
				await this.save_empty();
				axios({url: config.API_LOCATION + 'release/add_proforma/', data: {
						release___id:this.data.__id,
						spares:this.proforma_spares_select,
						count:this.proforma_spares_select_count
					}, method: 'POST'})
					.then(resp => {
						if (resp.data.status == 'ok') {
							this.reload();
							this.tab_active='PROFORMA';
						}
					})
					.catch(err => {

					})
			},
			async add_payment_customers()
			{
				await this.save_empty();
				axios({url: config.API_LOCATION + 'release/add_payment_customers/', data: {
						release___id:this.data.__id
					}, method: 'POST'})
					.then(resp => {
						if (resp.data.status == 'ok') {
							this.reload();
							this.tab_active='PAYMENT_CUSTOMERS';
						}
					})
					.catch(err => {

					})
			},
			spares_edit(id)
			{
				this.$refs['release_edit_pn'].edit(id)
			},
			modal_order_payment_show(o_k)
			{
				this.order_payment_k=o_k;
				this.modal_order_payment.show();
			},
			order_payment_payed(id)
			{
				axios({url: config.API_LOCATION + 'release/order_payment_payed/'+this.data.__id+'/'+id+'/',  method: 'GET'})
					.then(resp => {
						if (resp.data.status == 'ok') {
							this.reload()
						}
					})
					.catch(err => {

					})
			},
			modal_invoice_payment_show(i_k)
			{
				this.invoice_payment_k=i_k;
				this.modal_invoice_payment.show();
			},
			async set_next_status_order(o_k,id,status)
			{
				status+=1;
				if(status==3){
					const sum=parseFloat(this.data.order[o_k].result_sum)
					if(isNaN(sum)||sum==0){
						this.error='У order #'+this.data.order[o_k].n_view+' не указанна итоговая сумма';
						return
					}
					this.error=''
				}
				if(status==5){
					if((this.data.order[o_k].terms==3||this.data.order[o_k].terms==4)&&this.data.order[o_k].payed=='no'){
						this.error='Заказ не оплачен';
						return
					}
					this.error=''
				}
				await this.save_empty();
				axios({url: config.API_LOCATION + 'release/set_status_order/'+this.data.__id+'/'+id+'/'+status+'/',  method: 'GET'})
					.then(resp => {
						if (resp.data.status == 'ok') {
							this.reload();
						}
					})
					.catch(err => {

					})
			},
			order_file_show_modal(o_k,o_id)
			{
				this.order_file_active_k=o_k
				this.order_file_active_id=o_id
				this.modal_order_file.show()
			},
			order_file_add()
			{
				axios({url: config.API_LOCATION+'release/child/'+this.data.__id+'/order/child/'+this.order_file_active_id+'/file/add/' })
					.then(resp => {
						if(resp.data.status=='ok') {
							this.reload()
						}
					})
					.catch(err => {

					})
			},
			order_file_upload(e,file_k,file_id)
			{
				let formData = new FormData();
				formData.append('file', e.target.files[0]);
				axios({
					url: config.API_LOCATION+'release/child/'+this.data.__id+'/order/child/'+this.order_file_active_id+'/file/element/'+file_id+'/file/upload/',
					data: formData,
					method: 'POST',
					headers: {'Content-Type': 'multipart/form-data'}
				})
					.then(resp => {
						if(resp.data.status=='ok') {
							this.data_old.order[this.order_file_active_k].file[file_k].file=resp.data.data.file
							this.data=structuredClone(this.data_old)
							this.save_empty()
						}
					})
					.catch(err => {

					})
			},
			order_file_delete(file_k,file_id)
			{
				axios({url: config.API_LOCATION+'release/child/'+this.data.__id+'/order/child/'+this.order_file_active_id+'/file/delete/'+file_id+'/' })
					.then(resp => {
						if(resp.data.status=='ok') {
							this.reload()
						}
					})
					.catch(err => {

					})
			},
			upload_proform_upload_file(e,order_k,order_id)
			{
				let formData = new FormData();
				formData.append('proform', e.target.files[0]);
				axios({
					url: config.API_LOCATION+'release/child/'+this.data.__id+'/order/element/'+order_id+'/proform/upload/',
					data: formData,
					method: 'POST',
					headers: {'Content-Type': 'multipart/form-data'}
				})
					.then(resp => {
						if(resp.data.status=='ok') {
							this.data_old.order[order_k].proform=resp.data.data.proform
							this.data=structuredClone(this.data_old)
							this.save_empty()
						}
					})
					.catch(err => {

					})
			},
			upload_proform_delete_file(order_k,order_id)
			{
				axios({url: config.API_LOCATION+'release/child/'+this.data.__id+'/order/element/'+order_id+'/proform/delete_file/' })
					.then(resp => {
						if(resp.data.status=='ok') {
							this.data_old.order[order_k].proform.id=0
							this.data=structuredClone(this.data_old)
							this.save_empty()
						}
					})
					.catch(err => {

					})
			},
			async reset_status()
			{
				await this.save_empty();
				axios({url: config.API_LOCATION + 'release/reset_status/'+this.data.__id+'/',  method: 'GET'})
					.then(resp => {
						if (resp.data.status == 'ok') {
							this.reload();
						}
					})
					.catch(err => {

					})
			},
			async set_status_canceled()
			{
				await this.save_empty();
				axios({url: config.API_LOCATION + 'release/set_status_canceled/'+this.data.__id+'/',  method: 'GET'})
					.then(resp => {
						if (resp.data.status == 'ok') {
							this.reload();
						}
					})
					.catch(err => {

					})
			},
			link_offer()
			{
				router.push('/offer/edit/'+this.data.offer);
			},
			del_link_offer()
			{
				axios({url: config.API_LOCATION + 'release/delete/'+this.data.id+'/',  method: 'GET'})
					.then(resp => {
						if (resp.data.status == 'ok') {
							router.push('/offer/edit/'+this.data.offer);
						}
					})
					.catch(err => {

					})
			},
			check_error()
			{
				for(const o_k in this.data.order){
					/*const sum=parseFloat(this.data.order[o_k].result_sum)
					if(isNaN(sum)||sum==0){
						this.error='У order #'+this.data.order[o_k].n_view+' не указанна итоговая сумма';
						return;
					}*/
				}
				this.error='';
			},
			async message_send()
			{
				await this.save_empty();
				axios({url: config.API_LOCATION + 'release/message_send/'+this.data.id+'/', data: { message:this.message_new_text },  method: 'POST'})
					.then(resp => {
						if (resp.data.status == 'ok') {
							this.message_new_text=''
							this.reload();
						}
					})
					.catch(err => {

					})
			}
		},
	watch: {
		data: {
			handler(){
//				console.log('up')
//				console.log(this.data_old)
//				console.log(updatedDiff(this.data_old, this.data));
				let update=updatedDiff(this.data_old, this.data)
				if(Object.keys(update).length === 0)
					return
				let get_name= function(o)
				{
					return "['"+Object.keys(o)[0]+"']"+(typeof o[Object.keys(o)[0]] === 'object' && !Array.isArray(o[Object.keys(o)[0]]) && o[Object.keys(o)[0]] !== null?get_name(o[Object.keys(o)[0]]):'')
				}
				let name=get_name(update)
//				console.log(name)
				let old_value=eval('this.data_old'+name)
//				console.log(old_value)
				this.back_list.push({name:name,value:old_value})
				objectAssignDeep(this.save_list,update)
				this.data_old=structuredClone(this.data)

				if(Object.keys(update)[0]=='spares') {
					let s_k = Object.keys(update.spares)[0]
					let s_var = Object.keys(update.spares[s_k])[0]
					this.calc_all(s_k)
					if(s_var=='order_count'||s_var=='price_provider'||s_var=='currency_provider'){
						this.do_reload=true
					}
				}
				if(Object.keys(update)[0]=='order') {
					let s_k = Object.keys(update.order)[0]
					let s_var = Object.keys(update.order[s_k])[0]
					if(s_var=='bank_fee')
						this.do_reload=true
				}
				this.check_error()
			},
			deep: true
		},
		invoice_status: {
			async handler() {
				let update=updatedDiff(this.invoice_status_old, this.invoice_status)
				if(Object.keys(update).length === 0)
					return
				await this.save_empty();
				for(const i_k in update)
					axios({url: config.API_LOCATION + 'release/set_status_invoice/'+this.data.__id+'/'+this.data.invoice[i_k].id+'/'+update[i_k]+'/',  method: 'GET'})
						.then(resp => {
							if (resp.data.status == 'ok') {
								this.reload();
							}
						})
						.catch(err => {

						})
			},
			deep: true
		}
	},
	computed: {
		price_result_rub_sum()
		{
			let sum=0
			for(const k in this.data.spares){
				let p=this.data.spares[k].order_count * this.data.spares[k].price_result_rub
				p=Math.round(p*100)/100
				if(!isNaN(p))
					sum+=p
			}
			return this.formatter_money.RUB.format(sum)
		},
		get_next_status_order()
		{
			let res={};
			let data = this.setting.field.order.setting.field.status.data;
			for(const k in this.data.order){
				if(this.data.order[k].status<5&&data.hasOwnProperty('id_'+(this.data.order[k].status+1)))
					res[k]=data['id_'+(this.data.order[k].status+1)].title;
				else
					res[k]=false;
			}
			return res;
		}
	}
}
</script>
<style>
.table-nobcollapse {
	border-collapse: separate;
}
.card-table .form-select {
	height: fit-content;
}

.card-table th {
	position: sticky;
	top: 0;
	z-index: 9;
}

.card-table thead tr th {
	font-size: 0.86em;
	white-space: normal;
}

.card-table tbody td {
	vertical-align: top;
}

.modal-card .choices__list.dropdown-menu {
	position: fixed;
}

.collapse-selects .form-select-sm .choices__list {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.w-150 .choices__list {

}

.cert-chars {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 20ch;
	display: inline-flex;
}
.mt-negative svg {
	margin-top:-0.25em;
}

.td-actions .btn-sm {
	font-size: 0.65rem;
}
</style>
