<template>
	<lg-menu/>

	<div class="main-content">

		<div class="container-fluid">
			<div  class="row justify-content-center">
				<!-- HEADER -->
				<div class="header">
					<div class="container-fluid">

						<!-- Body -->
						<div class="header-body">
							<div class="row align-items-end">
								<div class="col">

									<!-- Pretitle -->
									<h6 class="header-pretitle">Рабочий стол / Логистика </h6>

									<!-- Title -->
									<h1 class="header-title">Движение SPARES</h1>

								</div>


							</div> <!-- / .row -->
						</div> <!-- / .header-body -->

					</div>
				</div> <!-- / .header -->

				<!-- CARDS -->
				<div class="container-fluid">

					<div class="row">
						<div class="col-12 col-md-2">
							<div class="input-group input-group-merge input-group-reverse mb-3">
								<input class="form-control" placeholder="Offer" type="text"  v-model="filter.offer_n">
								<div class="input-group-text">
									<i class="las la-stream la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-2">
							<div class="input-group input-group-merge input-group-reverse mb-3">
								<input class="form-control" placeholder="Release" type="text"  v-model="filter.release_n">
								<div class="input-group-text">
									<i class="las la-stream la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-2">
							<div class="input-group input-group-merge input-group-reverse mb-3">
								<input class="form-control" placeholder="Order" type="text"  v-model="filter.order_n">
								<div class="input-group-text">
									<i class="las la-stream la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-2">
							<div class="input-group input-group-merge input-group-reverse mb-3">
								<input class="form-control" placeholder="Invoice" type="text"  v-model="filter.invoice_n">
								<div class="input-group-text">
									<i class="las la-stream la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12 col-md-4">
							<div class="input-group input-group-merge input-group-reverse form-group">
								<input class="form-control" placeholder="P/N" type="text" v-model="filter.pn">
								<div class="input-group-text">
									<i class="las la-headset la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>

						<div class="col-12 col-md-4">
							<div class="input-group d-block mb-3">
								<lg-select-multi ref="filter_responsible" v-if="!loading" v-model="filter.responsible" :options="filter_key.responsible"  :placeholder="'Ответсвенный'" class="form-control" />

							</div>
						</div>

					</div>
					<div class="row">
						<div class="col-12 col-md-4">
							<div class="input-group input-group-merge input-group-reverse form-group">
								<input class="form-control" placeholder="Клиент" type="text" v-model="filter.client">
								<div class="input-group-text">
									<i class="las la-user-circle la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-4">
							<div class="input-group input-group-merge input-group-reverse form-group">
								<input class="form-control" placeholder="Поставщик" type="text" v-model="filter.provider">
								<div class="input-group-text">
									<i class="las la-user-circle la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>

					</div>

					<div class="row">
						<lg-type-date-filter v-model="filter.date" :title="'Дата'"/>
						<div v-if="false" class="col-12 col-md-2">
							<div class="form-group">
								<label class="mb-3">Дата изменения</label>
								<div class="input-group input-group-merge">
									<input id="date" type="text" class="form-control" placeholder="ДД.ММ.ГГГГ">
									<div class="input-group-text">
										<span class="fe fe-x mr-2"></span>
										<span class="fe fe-calendar"></span>
									</div>
								</div>
							</div>
						</div>

						<div v-if="false" class="col-12 col-md-2">
							<div class="form-group">
								<label class="mb-3">&nbsp;</label>
								<div class="input-group input-group-merge">
									<input id="date" type="text" class="form-control" placeholder="ДД.ММ.ГГГГ">
									<div class="input-group-text">
										<span class="fe fe-x mr-2"></span>
										<span class="fe fe-calendar"></span>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-4">
							<div class="form-group">
								<label class="mb-3">&nbsp;</label>
								<div>
									<button @click="exec_filter()" class="btn btn-primary">Искать</button>
									<a @click="clear_filter()" class="btn btn-link text-muted">Очистить фильтр</a>
								</div>
							</div>
						</div>
					</div>

					<div class="row mb-4" style="margin-top: -1rem;">
						<div class="col">
							<div v-if="!loading_status&&!loading" class="filter-item">
								<div @click="clear_status_filter()">Все статусы</div>
								<div @click="set_status_filter('1')" :class="{'filter-selected-item': filter.status.indexOf('1')!==-1}">Получен order <span class="badge bg-danger-soft">{{status_count[1].count}}</span> <button v-if="filter.status.indexOf('1')!==-1" type="button" class="button-small-remove">x</button></div>
								<div @click="set_status_filter('2')" :class="{'filter-selected-item': filter.status.indexOf('2')!==-1}">Отправлен поставщику <span class="badge bg-warning-soft">{{status_count[2].count}}</span> <button v-if="filter.status.indexOf('2')!==-1" type="button" class="button-small-remove">x</button></div>
								<div @click="set_status_filter('3')" :class="{'filter-selected-item': filter.status.indexOf('3')!==-1}">Получена проформа <span class="badge bg-danger-soft">{{status_count[3].count}}</span> <button v-if="filter.status.indexOf('3')!==-1" type="button" class="button-small-remove">x</button></div>
								<div @click="set_status_filter('4')" :class="{'filter-selected-item': filter.status.indexOf('4')!==-1}">Готовность к отгрузке <span class="badge bg-danger-soft">{{status_count[4].count}}</span> <button v-if="filter.status.indexOf('4')!==-1" type="button" class="button-small-remove">x</button></div>
								<div @click="set_status_filter('5')" :class="{'filter-selected-item': filter.status.indexOf('5')!==-1}">Отправлен клиенту <span class="badge bg-warning-soft">{{status_count[5].count}}</span> <button v-if="filter.status.indexOf('5')!==-1" type="button" class="button-small-remove">x</button></div>
								<div @click="set_status_filter('6')" :class="{'filter-selected-item': filter.status.indexOf('6')!==-1}">Подтвержден клиентом <span class="badge bg-success-soft">{{status_count[6].count}}</span> <button v-if="filter.status.indexOf('6')!==-1" type="button" class="button-small-remove">x</button></div>
								<div @click="set_status_filter('7')" :class="{'filter-selected-item': filter.status.indexOf('7')!==-1}">Отгружен <span class="badge bg-info-soft">{{status_count[7].count}}</span> <button v-if="filter.status.indexOf('7')!==-1" type="button" class="button-small-remove">x</button></div>
								<div @click="set_status_filter('8')" :class="{'filter-selected-item': filter.status.indexOf('8')!==-1}">Получен клиентом <span class="badge bg-secondary-soft">{{status_count[8].count}}</span> <button v-if="filter.status.indexOf('8')!==-1" type="button" class="button-small-remove">x</button></div>
							</div>
						</div>
					</div>



					<grid :url="url" :can_add="false" :can_edit="false" :can_del="false" ref="ref_grid"></grid>

				</div>
			</div>
		</div>
	</div>

</template>
<script>
import axios from 'axios';
import config from '@/config.js'
import Grid from "@/components/Grid";
export default {
	components: {
		Grid
	},
	data() {
		return {
			url: 'logistics/spares',

			filter: {},
			default_filter: {
				offer_n: '', release_n:'',order_n:'',invoice_n:'', pn:'', responsible:[], client: '',provider:'', date: {from:'',to:''}, status: [],
			},
			filter_key: {},

			status_count: {},
			loading: true,
			loading_status: true,
		}
	},
	mounted()
	{
		this.filter=structuredClone(this.default_filter);
		this.load_status_count();
	},
	methods: {
		load_status_count()
		{
			this.loading_status=true;
			axios({url: config.API_LOCATION+ this.url+ '/get_status_count/' })
				.then(resp => {
					this.status_count=resp.data.status_count;
					for(let i=1;i<9;i++){
						if(!this.status_count.hasOwnProperty(i))
							this.status_count[i]={status:i,count:0};
					}
					this.loading_status=false;
				})
				.catch(err => {
					console.log(err)
				})
		},
		async exec_filter ()
		{
			await this.$refs.ref_grid.exec_filter(this.filter);
			this.load_status_count();
		},
		load_filter(filter,key)
		{
			this.filter=filter;
			this.filter_key=key;
			this.loading=false;
		},
		async clear_filter(filter)
		{
			this.$refs.filter_responsible.object.removeActiveItems();
			this.filter=structuredClone(this.default_filter);

//			console.log(this.$refs.filter_responsible.object.getValue(true));

//			console.log(this.$refs.filter_responsible.object.getValue(true));
			await this.$refs.ref_grid.exec_filter(this.filter);
			this.load_status_count();
		},
		set_status_filter(status)
		{
			if(this.filter.status.indexOf(status)===-1){
				this.filter.status.push(status);
			}
			else{
				this.filter.status.splice(this.filter.status.indexOf(status),1);
			}
			this.$refs.ref_grid.exec_filter(this.filter);
		},
		clear_status_filter()
		{
			this.filter.status=[];
			this.$refs.ref_grid.exec_filter(this.filter);
		},
	}
}
</script>