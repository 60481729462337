function word_copy_header()
{
	let html="" ;
	html+= "<html xmlns:o=\"urn:schemas-microsoft-com:office:office\"\n" +
		"\t  xmlns:w=\"urn:schemas-microsoft-com:office:word\"\n" +
		"\t  xmlns:m=\"http://schemas.microsoft.com/office/2004/12/omml\"\n" +
		"\t  xmlns=\"http://www.w3.org/TR/REC-html40\">\n" +
		"\n" +
		"<head>\n" +
		"\t<meta http-equiv=Content-Type content=\"text/html; charset=utf-8\">\n" +
		"\t<meta name=ProgId content=Word.Document>\n" +
		"\t<meta name=Generator content=\"Microsoft Word 15\">\n" +
		"\t<meta name=Originator content=\"Microsoft Word 15\">\n" +
		"\n" +
		"\t<!--[if gte mso 9]>\n" +
		"\t<xml>\n" +
		"\t\t<o:OfficeDocumentSettings>\n" +
		"\t\t\t<o:AllowPNG/>\n" +
		"\t\t</o:OfficeDocumentSettings>\n" +
		"\t</xml><![endif]-->\n" +
		"\n" +
		"\n" +
		"\t<!--[if gte mso 9]><xml>\n" +
		"\t <w:WordDocument>\n" +
		"\t  <w:View>Normal</w:View>\n" +
		"\t  <w:Zoom>0</w:Zoom>\n" +
		"\t  <w:TrackMoves/>\n" +
		"\t  <w:TrackFormatting/>\n" +
		"\t  <w:PunctuationKerning/>\n" +
		"\t  <w:ValidateAgainstSchemas/>\n" +
		"\t  <w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid>\n" +
		"\t  <w:IgnoreMixedContent>false</w:IgnoreMixedContent>\n" +
		"\t  <w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText>\n" +
		"\t  <w:DoNotPromoteQF/>\n" +
		"\t  <w:LidThemeOther>RU</w:LidThemeOther>\n" +
		"\t  <w:LidThemeAsian>X-NONE</w:LidThemeAsian>\n" +
		"\t  <w:LidThemeComplexScript>X-NONE</w:LidThemeComplexScript>\n" +
		"\t  <w:Compatibility>\n" +
		"\t   <w:BreakWrappedTables/>\n" +
		"\t   <w:SnapToGridInCell/>\n" +
		"\t   <w:WrapTextWithPunct/>\n" +
		"\t   <w:UseAsianBreakRules/>\n" +
		"\t   <w:DontGrowAutofit/>\n" +
		"\t   <w:SplitPgBreakAndParaMark/>\n" +
		"\t   <w:EnableOpenTypeKerning/>\n" +
		"\t   <w:DontFlipMirrorIndents/>\n" +
		"\t   <w:OverrideTableStyleHps/>\n" +
		"\t  </w:Compatibility>\n" +
		"\t  <m:mathPr>\n" +
		"\t   <m:mathFont m:val=\"Cambria Math\"/>\n" +
		"\t   <m:brkBin m:val=\"before\"/>\n" +
		"\t   <m:brkBinSub m:val=\"&#45;-\"/>\n" +
		"\t   <m:smallFrac m:val=\"off\"/>\n" +
		"\t   <m:dispDef/>\n" +
		"\t   <m:lMargin m:val=\"0\"/>\n" +
		"\t   <m:rMargin m:val=\"0\"/>\n" +
		"\t   <m:defJc m:val=\"centerGroup\"/>\n" +
		"\t   <m:wrapIndent m:val=\"1440\"/>\n" +
		"\t   <m:intLim m:val=\"subSup\"/>\n" +
		"\t   <m:naryLim m:val=\"undOvr\"/>\n" +
		"\t  </m:mathPr></w:WordDocument>\n" +
		"\t</xml><![endif]--><!--[if gte mso 9]>\n" +
		"\t<xml>\n" +
		"\t\t<w:LatentStyles DefLockedState=\"false\" DefUnhideWhenUsed=\"false\"\n" +
		"\t\t\t\t\t\tDefSemiHidden=\"false\" DefQFormat=\"false\" DefPriority=\"99\"\n" +
		"\t\t\t\t\t\tLatentStyleCount=\"376\">\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"0\" QFormat=\"true\" Name=\"Normal\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"9\" QFormat=\"true\" Name=\"heading 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"9\" SemiHidden=\"true\"\n" +
		"\t\t\t\t\t\t\tUnhideWhenUsed=\"true\" QFormat=\"true\" Name=\"heading 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"9\" SemiHidden=\"true\"\n" +
		"\t\t\t\t\t\t\tUnhideWhenUsed=\"true\" QFormat=\"true\" Name=\"heading 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"9\" SemiHidden=\"true\"\n" +
		"\t\t\t\t\t\t\tUnhideWhenUsed=\"true\" QFormat=\"true\" Name=\"heading 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"9\" SemiHidden=\"true\"\n" +
		"\t\t\t\t\t\t\tUnhideWhenUsed=\"true\" QFormat=\"true\" Name=\"heading 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"9\" SemiHidden=\"true\"\n" +
		"\t\t\t\t\t\t\tUnhideWhenUsed=\"true\" QFormat=\"true\" Name=\"heading 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"9\" SemiHidden=\"true\"\n" +
		"\t\t\t\t\t\t\tUnhideWhenUsed=\"true\" QFormat=\"true\" Name=\"heading 7\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"9\" SemiHidden=\"true\"\n" +
		"\t\t\t\t\t\t\tUnhideWhenUsed=\"true\" QFormat=\"true\" Name=\"heading 8\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"9\" SemiHidden=\"true\"\n" +
		"\t\t\t\t\t\t\tUnhideWhenUsed=\"true\" QFormat=\"true\" Name=\"heading 9\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"index 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"index 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"index 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"index 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"index 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"index 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"index 7\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"index 8\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"index 9\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"39\" SemiHidden=\"true\"\n" +
		"\t\t\t\t\t\t\tUnhideWhenUsed=\"true\" Name=\"toc 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"39\" SemiHidden=\"true\"\n" +
		"\t\t\t\t\t\t\tUnhideWhenUsed=\"true\" Name=\"toc 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"39\" SemiHidden=\"true\"\n" +
		"\t\t\t\t\t\t\tUnhideWhenUsed=\"true\" Name=\"toc 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"39\" SemiHidden=\"true\"\n" +
		"\t\t\t\t\t\t\tUnhideWhenUsed=\"true\" Name=\"toc 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"39\" SemiHidden=\"true\"\n" +
		"\t\t\t\t\t\t\tUnhideWhenUsed=\"true\" Name=\"toc 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"39\" SemiHidden=\"true\"\n" +
		"\t\t\t\t\t\t\tUnhideWhenUsed=\"true\" Name=\"toc 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"39\" SemiHidden=\"true\"\n" +
		"\t\t\t\t\t\t\tUnhideWhenUsed=\"true\" Name=\"toc 7\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"39\" SemiHidden=\"true\"\n" +
		"\t\t\t\t\t\t\tUnhideWhenUsed=\"true\" Name=\"toc 8\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"39\" SemiHidden=\"true\"\n" +
		"\t\t\t\t\t\t\tUnhideWhenUsed=\"true\" Name=\"toc 9\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Normal Indent\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"footnote text\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"annotation text\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"header\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"footer\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"index heading\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"35\" SemiHidden=\"true\"\n" +
		"\t\t\t\t\t\t\tUnhideWhenUsed=\"true\" QFormat=\"true\" Name=\"caption\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"table of figures\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"envelope address\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"envelope return\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"footnote reference\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"annotation reference\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"line number\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"page number\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"endnote reference\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"endnote text\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"table of authorities\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"macro\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"toa heading\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"List\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"List Bullet\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"List Number\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"List 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"List 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"List 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"List 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"List Bullet 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"List Bullet 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"List Bullet 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"List Bullet 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"List Number 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"List Number 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"List Number 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"List Number 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"10\" QFormat=\"true\" Name=\"Title\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Closing\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Signature\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"1\" SemiHidden=\"true\"\n" +
		"\t\t\t\t\t\t\tUnhideWhenUsed=\"true\" Name=\"Default Paragraph Font\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Body Text\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Body Text Indent\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"List Continue\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"List Continue 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"List Continue 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"List Continue 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"List Continue 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Message Header\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"11\" QFormat=\"true\" Name=\"Subtitle\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Salutation\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Date\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Body Text First Indent\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Body Text First Indent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Note Heading\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Body Text 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Body Text 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Body Text Indent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Body Text Indent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Block Text\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Hyperlink\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"FollowedHyperlink\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"22\" QFormat=\"true\" Name=\"Strong\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"20\" QFormat=\"true\" Name=\"Emphasis\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Document Map\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Plain Text\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"E-mail Signature\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"HTML Top of Form\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"HTML Bottom of Form\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Normal (Web)\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"HTML Acronym\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"HTML Address\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"HTML Cite\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"HTML Code\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"HTML Definition\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"HTML Keyboard\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"HTML Preformatted\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"HTML Sample\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"HTML Typewriter\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"HTML Variable\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Normal Table\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"annotation subject\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"No List\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Outline List 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Outline List 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Outline List 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Simple 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Simple 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Simple 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Classic 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Classic 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Classic 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Classic 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Colorful 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Colorful 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Colorful 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Columns 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Columns 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Columns 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Columns 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Columns 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Grid 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Grid 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Grid 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Grid 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Grid 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Grid 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Grid 7\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Grid 8\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table List 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table List 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table List 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table List 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table List 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table List 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table List 7\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table List 8\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table 3D effects 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table 3D effects 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table 3D effects 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Contemporary\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Elegant\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Professional\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Subtle 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Subtle 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Web 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Web 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Web 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Balloon Text\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"39\" Name=\"Table Grid\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Table Theme\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" Name=\"Placeholder Text\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"1\" QFormat=\"true\" Name=\"No Spacing\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"60\" Name=\"Light Shading\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"61\" Name=\"Light List\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"62\" Name=\"Light Grid\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"63\" Name=\"Medium Shading 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"64\" Name=\"Medium Shading 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"65\" Name=\"Medium List 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"66\" Name=\"Medium List 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"67\" Name=\"Medium Grid 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"68\" Name=\"Medium Grid 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"69\" Name=\"Medium Grid 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"70\" Name=\"Dark List\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"71\" Name=\"Colorful Shading\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"72\" Name=\"Colorful List\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"73\" Name=\"Colorful Grid\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"60\" Name=\"Light Shading Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"61\" Name=\"Light List Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"62\" Name=\"Light Grid Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"63\" Name=\"Medium Shading 1 Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"64\" Name=\"Medium Shading 2 Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"65\" Name=\"Medium List 1 Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" Name=\"Revision\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"34\" QFormat=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"List Paragraph\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"29\" QFormat=\"true\" Name=\"Quote\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"30\" QFormat=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Intense Quote\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"66\" Name=\"Medium List 2 Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"67\" Name=\"Medium Grid 1 Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"68\" Name=\"Medium Grid 2 Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"69\" Name=\"Medium Grid 3 Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"70\" Name=\"Dark List Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"71\" Name=\"Colorful Shading Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"72\" Name=\"Colorful List Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"73\" Name=\"Colorful Grid Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"60\" Name=\"Light Shading Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"61\" Name=\"Light List Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"62\" Name=\"Light Grid Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"63\" Name=\"Medium Shading 1 Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"64\" Name=\"Medium Shading 2 Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"65\" Name=\"Medium List 1 Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"66\" Name=\"Medium List 2 Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"67\" Name=\"Medium Grid 1 Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"68\" Name=\"Medium Grid 2 Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"69\" Name=\"Medium Grid 3 Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"70\" Name=\"Dark List Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"71\" Name=\"Colorful Shading Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"72\" Name=\"Colorful List Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"73\" Name=\"Colorful Grid Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"60\" Name=\"Light Shading Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"61\" Name=\"Light List Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"62\" Name=\"Light Grid Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"63\" Name=\"Medium Shading 1 Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"64\" Name=\"Medium Shading 2 Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"65\" Name=\"Medium List 1 Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"66\" Name=\"Medium List 2 Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"67\" Name=\"Medium Grid 1 Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"68\" Name=\"Medium Grid 2 Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"69\" Name=\"Medium Grid 3 Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"70\" Name=\"Dark List Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"71\" Name=\"Colorful Shading Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"72\" Name=\"Colorful List Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"73\" Name=\"Colorful Grid Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"60\" Name=\"Light Shading Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"61\" Name=\"Light List Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"62\" Name=\"Light Grid Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"63\" Name=\"Medium Shading 1 Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"64\" Name=\"Medium Shading 2 Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"65\" Name=\"Medium List 1 Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"66\" Name=\"Medium List 2 Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"67\" Name=\"Medium Grid 1 Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"68\" Name=\"Medium Grid 2 Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"69\" Name=\"Medium Grid 3 Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"70\" Name=\"Dark List Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"71\" Name=\"Colorful Shading Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"72\" Name=\"Colorful List Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"73\" Name=\"Colorful Grid Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"60\" Name=\"Light Shading Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"61\" Name=\"Light List Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"62\" Name=\"Light Grid Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"63\" Name=\"Medium Shading 1 Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"64\" Name=\"Medium Shading 2 Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"65\" Name=\"Medium List 1 Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"66\" Name=\"Medium List 2 Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"67\" Name=\"Medium Grid 1 Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"68\" Name=\"Medium Grid 2 Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"69\" Name=\"Medium Grid 3 Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"70\" Name=\"Dark List Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"71\" Name=\"Colorful Shading Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"72\" Name=\"Colorful List Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"73\" Name=\"Colorful Grid Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"60\" Name=\"Light Shading Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"61\" Name=\"Light List Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"62\" Name=\"Light Grid Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"63\" Name=\"Medium Shading 1 Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"64\" Name=\"Medium Shading 2 Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"65\" Name=\"Medium List 1 Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"66\" Name=\"Medium List 2 Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"67\" Name=\"Medium Grid 1 Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"68\" Name=\"Medium Grid 2 Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"69\" Name=\"Medium Grid 3 Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"70\" Name=\"Dark List Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"71\" Name=\"Colorful Shading Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"72\" Name=\"Colorful List Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"73\" Name=\"Colorful Grid Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"19\" QFormat=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Subtle Emphasis\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"21\" QFormat=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Intense Emphasis\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"31\" QFormat=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Subtle Reference\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"32\" QFormat=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Intense Reference\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"33\" QFormat=\"true\" Name=\"Book Title\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"37\" SemiHidden=\"true\"\n" +
		"\t\t\t\t\t\t\tUnhideWhenUsed=\"true\" Name=\"Bibliography\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"39\" SemiHidden=\"true\"\n" +
		"\t\t\t\t\t\t\tUnhideWhenUsed=\"true\" QFormat=\"true\" Name=\"TOC Heading\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"41\" Name=\"Plain Table 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"42\" Name=\"Plain Table 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"43\" Name=\"Plain Table 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"44\" Name=\"Plain Table 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"45\" Name=\"Plain Table 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"40\" Name=\"Grid Table Light\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"46\" Name=\"Grid Table 1 Light\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"47\" Name=\"Grid Table 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"48\" Name=\"Grid Table 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"49\" Name=\"Grid Table 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"50\" Name=\"Grid Table 5 Dark\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"51\" Name=\"Grid Table 6 Colorful\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"52\" Name=\"Grid Table 7 Colorful\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"46\"\n" +
		"\t\t\t\t\t\t\tName=\"Grid Table 1 Light Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"47\" Name=\"Grid Table 2 Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"48\" Name=\"Grid Table 3 Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"49\" Name=\"Grid Table 4 Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"50\" Name=\"Grid Table 5 Dark Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"51\"\n" +
		"\t\t\t\t\t\t\tName=\"Grid Table 6 Colorful Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"52\"\n" +
		"\t\t\t\t\t\t\tName=\"Grid Table 7 Colorful Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"46\"\n" +
		"\t\t\t\t\t\t\tName=\"Grid Table 1 Light Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"47\" Name=\"Grid Table 2 Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"48\" Name=\"Grid Table 3 Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"49\" Name=\"Grid Table 4 Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"50\" Name=\"Grid Table 5 Dark Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"51\"\n" +
		"\t\t\t\t\t\t\tName=\"Grid Table 6 Colorful Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"52\"\n" +
		"\t\t\t\t\t\t\tName=\"Grid Table 7 Colorful Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"46\"\n" +
		"\t\t\t\t\t\t\tName=\"Grid Table 1 Light Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"47\" Name=\"Grid Table 2 Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"48\" Name=\"Grid Table 3 Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"49\" Name=\"Grid Table 4 Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"50\" Name=\"Grid Table 5 Dark Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"51\"\n" +
		"\t\t\t\t\t\t\tName=\"Grid Table 6 Colorful Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"52\"\n" +
		"\t\t\t\t\t\t\tName=\"Grid Table 7 Colorful Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"46\"\n" +
		"\t\t\t\t\t\t\tName=\"Grid Table 1 Light Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"47\" Name=\"Grid Table 2 Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"48\" Name=\"Grid Table 3 Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"49\" Name=\"Grid Table 4 Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"50\" Name=\"Grid Table 5 Dark Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"51\"\n" +
		"\t\t\t\t\t\t\tName=\"Grid Table 6 Colorful Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"52\"\n" +
		"\t\t\t\t\t\t\tName=\"Grid Table 7 Colorful Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"46\"\n" +
		"\t\t\t\t\t\t\tName=\"Grid Table 1 Light Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"47\" Name=\"Grid Table 2 Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"48\" Name=\"Grid Table 3 Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"49\" Name=\"Grid Table 4 Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"50\" Name=\"Grid Table 5 Dark Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"51\"\n" +
		"\t\t\t\t\t\t\tName=\"Grid Table 6 Colorful Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"52\"\n" +
		"\t\t\t\t\t\t\tName=\"Grid Table 7 Colorful Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"46\"\n" +
		"\t\t\t\t\t\t\tName=\"Grid Table 1 Light Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"47\" Name=\"Grid Table 2 Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"48\" Name=\"Grid Table 3 Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"49\" Name=\"Grid Table 4 Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"50\" Name=\"Grid Table 5 Dark Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"51\"\n" +
		"\t\t\t\t\t\t\tName=\"Grid Table 6 Colorful Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"52\"\n" +
		"\t\t\t\t\t\t\tName=\"Grid Table 7 Colorful Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"46\" Name=\"List Table 1 Light\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"47\" Name=\"List Table 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"48\" Name=\"List Table 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"49\" Name=\"List Table 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"50\" Name=\"List Table 5 Dark\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"51\" Name=\"List Table 6 Colorful\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"52\" Name=\"List Table 7 Colorful\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"46\"\n" +
		"\t\t\t\t\t\t\tName=\"List Table 1 Light Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"47\" Name=\"List Table 2 Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"48\" Name=\"List Table 3 Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"49\" Name=\"List Table 4 Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"50\" Name=\"List Table 5 Dark Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"51\"\n" +
		"\t\t\t\t\t\t\tName=\"List Table 6 Colorful Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"52\"\n" +
		"\t\t\t\t\t\t\tName=\"List Table 7 Colorful Accent 1\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"46\"\n" +
		"\t\t\t\t\t\t\tName=\"List Table 1 Light Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"47\" Name=\"List Table 2 Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"48\" Name=\"List Table 3 Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"49\" Name=\"List Table 4 Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"50\" Name=\"List Table 5 Dark Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"51\"\n" +
		"\t\t\t\t\t\t\tName=\"List Table 6 Colorful Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"52\"\n" +
		"\t\t\t\t\t\t\tName=\"List Table 7 Colorful Accent 2\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"46\"\n" +
		"\t\t\t\t\t\t\tName=\"List Table 1 Light Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"47\" Name=\"List Table 2 Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"48\" Name=\"List Table 3 Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"49\" Name=\"List Table 4 Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"50\" Name=\"List Table 5 Dark Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"51\"\n" +
		"\t\t\t\t\t\t\tName=\"List Table 6 Colorful Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"52\"\n" +
		"\t\t\t\t\t\t\tName=\"List Table 7 Colorful Accent 3\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"46\"\n" +
		"\t\t\t\t\t\t\tName=\"List Table 1 Light Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"47\" Name=\"List Table 2 Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"48\" Name=\"List Table 3 Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"49\" Name=\"List Table 4 Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"50\" Name=\"List Table 5 Dark Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"51\"\n" +
		"\t\t\t\t\t\t\tName=\"List Table 6 Colorful Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"52\"\n" +
		"\t\t\t\t\t\t\tName=\"List Table 7 Colorful Accent 4\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"46\"\n" +
		"\t\t\t\t\t\t\tName=\"List Table 1 Light Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"47\" Name=\"List Table 2 Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"48\" Name=\"List Table 3 Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"49\" Name=\"List Table 4 Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"50\" Name=\"List Table 5 Dark Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"51\"\n" +
		"\t\t\t\t\t\t\tName=\"List Table 6 Colorful Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"52\"\n" +
		"\t\t\t\t\t\t\tName=\"List Table 7 Colorful Accent 5\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"46\"\n" +
		"\t\t\t\t\t\t\tName=\"List Table 1 Light Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"47\" Name=\"List Table 2 Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"48\" Name=\"List Table 3 Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"49\" Name=\"List Table 4 Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"50\" Name=\"List Table 5 Dark Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"51\"\n" +
		"\t\t\t\t\t\t\tName=\"List Table 6 Colorful Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" Priority=\"52\"\n" +
		"\t\t\t\t\t\t\tName=\"List Table 7 Colorful Accent 6\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Mention\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Smart Hyperlink\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Hashtag\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Unresolved Mention\"/>\n" +
		"\t\t\t<w:LsdException Locked=\"false\" SemiHidden=\"true\" UnhideWhenUsed=\"true\"\n" +
		"\t\t\t\t\t\t\tName=\"Smart Link\"/>\n" +
		"\t\t</w:LatentStyles>\n" +
		"\t</xml><![endif]-->\n" +
		"\t<style>\n" +
		"        <!--\n" +
		"        /* Font Definitions */\n" +
		"        @font-face {\n" +
		"            font-family: \"Cambria Math\";\n" +
		"            panose-1: 2 4 5 3 5 4 6 3 2 4;\n" +
		"            mso-font-charset: 0;\n" +
		"            mso-generic-font-family: roman;\n" +
		"            mso-font-pitch: variable;\n" +
		"            mso-font-signature: 3 0 0 0 1 0;\n" +
		"        }\n" +
		"\n" +
		"        @font-face {\n" +
		"            font-family: Calibri;\n" +
		"            panose-1: 2 15 5 2 2 2 4 3 2 4;\n" +
		"            mso-font-charset: 204;\n" +
		"            mso-generic-font-family: swiss;\n" +
		"            mso-font-pitch: variable;\n" +
		"            mso-font-signature: -536858881 -1073732485 9 0 511 0;\n" +
		"        }\n" +
		"\n" +
		"        /* Style Definitions */\n" +
		"        p.MsoNormal, li.MsoNormal, div.MsoNormal {\n" +
		"            mso-style-unhide: no;\n" +
		"            mso-style-qformat: yes;\n" +
		"            mso-style-parent: \"\";\n" +
		"            margin-top: 0cm;\n" +
		"            margin-right: 0cm;\n" +
		"            margin-bottom: 8.0pt;\n" +
		"            margin-left: 0cm;\n" +
		"            line-height: 107%;\n" +
		"            mso-pagination: widow-orphan;\n" +
		"            font-size: 11.0pt;\n" +
		"            font-family: \"Calibri\", sans-serif;\n" +
		"            mso-ascii-font-family: Calibri;\n" +
		"            mso-ascii-theme-font: minor-latin;\n" +
		"            mso-fareast-font-family: Calibri;\n" +
		"            mso-fareast-theme-font: minor-latin;\n" +
		"            mso-hansi-font-family: Calibri;\n" +
		"            mso-hansi-theme-font: minor-latin;\n" +
		"            mso-bidi-font-family: \"Times New Roman\";\n" +
		"            mso-bidi-theme-font: minor-bidi;\n" +
		"            mso-fareast-language: EN-US;\n" +
		"        }\n" +
		"\n" +
		"        .MsoChpDefault {\n" +
		"            mso-style-type: export-only;\n" +
		"            mso-default-props: yes;\n" +
		"            font-family: \"Calibri\", sans-serif;\n" +
		"            mso-ascii-font-family: Calibri;\n" +
		"            mso-ascii-theme-font: minor-latin;\n" +
		"            mso-fareast-font-family: Calibri;\n" +
		"            mso-fareast-theme-font: minor-latin;\n" +
		"            mso-hansi-font-family: Calibri;\n" +
		"            mso-hansi-theme-font: minor-latin;\n" +
		"            mso-bidi-font-family: \"Times New Roman\";\n" +
		"            mso-bidi-theme-font: minor-bidi;\n" +
		"            mso-fareast-language: EN-US;\n" +
		"        }\n" +
		"\n" +
		"        .MsoPapDefault {\n" +
		"            mso-style-type: export-only;\n" +
		"            margin-bottom: 8.0pt;\n" +
		"            line-height: 107%;\n" +
		"        }\n" +
		"\n" +
		"        @page WordSection1 {\n" +
		"            size: 595.3pt 841.9pt;\n" +
		"            margin: 2.0cm 42.5pt 2.0cm 3.0cm;\n" +
		"            mso-header-margin: 35.4pt;\n" +
		"            mso-footer-margin: 35.4pt;\n" +
		"            mso-paper-source: 0;\n" +
		"        }\n" +
		"\n" +
		"        div.WordSection1 {\n" +
		"            page: WordSection1;\n" +
		"        }\n" +
		"\n" +
		"        -->\n" +
		"\t</style>\n" +
		"\t<!--[if gte mso 10]>\n" +
		"\t<style>\n" +
		"\t\t/* Style Definitions */\n" +
		"\t\ttable.MsoNormalTable {\n" +
		"\t\t\tmso-style-name: \"РћР±С‹С‡РЅР°СЏ С‚Р°Р±Р»РёС†Р°\";\n" +
		"\t\t\tmso-tstyle-rowband-size: 0;\n" +
		"\t\t\tmso-tstyle-colband-size: 0;\n" +
		"\t\t\tmso-style-noshow: yes;\n" +
		"\t\t\tmso-style-priority: 99;\n" +
		"\t\t\tmso-style-parent: \"\";\n" +
		"\t\t\tmso-padding-alt: 0cm 5.4pt 0cm 5.4pt;\n" +
		"\t\t\tmso-para-margin-top: 0cm;\n" +
		"\t\t\tmso-para-margin-right: 0cm;\n" +
		"\t\t\tmso-para-margin-bottom: 8.0pt;\n" +
		"\t\t\tmso-para-margin-left: 0cm;\n" +
		"\t\t\tline-height: 107%;\n" +
		"\t\t\tmso-pagination: widow-orphan;\n" +
		"\t\t\tfont-size: 11.0pt;\n" +
		"\t\t\tfont-family: \"Calibri\", sans-serif;\n" +
		"\t\t\tmso-ascii-font-family: Calibri;\n" +
		"\t\t\tmso-ascii-theme-font: minor-latin;\n" +
		"\t\t\tmso-hansi-font-family: Calibri;\n" +
		"\t\t\tmso-hansi-theme-font: minor-latin;\n" +
		"\t\t\tmso-bidi-font-family: \"Times New Roman\";\n" +
		"\t\t\tmso-bidi-theme-font: minor-bidi;\n" +
		"\t\t\tmso-fareast-language: EN-US;\n" +
		"\t\t}\n" +
		"\t</style>\n" +
		"\t<![endif]-->\n" +
		"</head>\n" +
		"\n" +
		"<body lang=RU style='tab-interval:35.4pt;word-wrap:break-word'>\n" +
		"<!--StartFragment-->\n" +
		"\n" +
		"<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 width=723\n" +
		"\t   style='width:542.4pt;margin-left:35.4pt;border-collapse:collapse;mso-yfti-tbllook:\n" +
		" 1184;mso-padding-alt:0cm 0cm 0cm 0cm'>\n" +
		"\t<tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:7.05pt'>\n" +
		"\t\t<td style='border:solid black 1.0pt;mso-border-alt:solid black .75pt; background:#DBF2F9;padding:1.5pt 2.25pt 1.5pt 2.25pt;height:7.05pt'>\n" +
		"\t\t\t<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;line-height:normal'>\n" +
		"\t\t\t\t<b>\n" +
		"\t\t\t\t\t<span style='font-size:10.0pt;font-family:\"Arial\",sans-serif;mso-fareast-font-family:\"Times New Roman\";color:black;mso-color-alt:windowtext;mso-fareast-language:RU'>Item:</span>\n" +
		"\t\t\t\t</b>\n" +
		"\t\t\t\t<b>\n" +
		"\t\t\t\t\t<span style='font-size:10.0pt;font-family:\"Arial\",sans-serif;mso-fareast-font-family:\"Times New Roman\";mso-fareast-language:RU'><o:p></o:p></span>\n" +
		"\t\t\t\t</b>\n" +
		"\t\t\t</p>\n" +
		"\t\t</td>\n" +
		"\t\t<td style='border:solid black 1.0pt;border-left:none;mso-border-left-alt:\n" +
		"  solid #CCCCCC .75pt;mso-border-alt:solid black .75pt;mso-border-left-alt:\n" +
		"  solid #CCCCCC .75pt;background:#DBF2F9;padding:1.5pt 2.25pt 1.5pt 2.25pt;\n" +
		"  height:7.05pt'>\n" +
		"\t\t\t<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;\n" +
		"  line-height:normal'><b><span style='font-size:10.0pt;font-family:\"Arial\",sans-serif;\n" +
		"  mso-fareast-font-family:\"Times New Roman\";color:black;mso-color-alt:windowtext;\n" +
		"  mso-fareast-language:RU'>Part number</span></b><b><span style='font-size:\n" +
		"  10.0pt;font-family:\"Arial\",sans-serif;mso-fareast-font-family:\"Times New Roman\";\n" +
		"  mso-fareast-language:RU'><o:p></o:p></span></b></p>\n" +
		"\t\t</td>\n" +
		"\t\t<td style='border:solid black 1.0pt;border-left:none;mso-border-left-alt:\n" +
		"  solid #CCCCCC .75pt;mso-border-alt:solid black .75pt;mso-border-left-alt:\n" +
		"  solid #CCCCCC .75pt;background:#DBF2F9;padding:1.5pt 2.25pt 1.5pt 2.25pt;\n" +
		"  height:7.05pt'>\n" +
		"\t\t\t<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;\n" +
		"  line-height:normal'><b><span style='font-size:10.0pt;font-family:\"Arial\",sans-serif;\n" +
		"  mso-fareast-font-family:\"Times New Roman\";color:black;mso-color-alt:windowtext;\n" +
		"  mso-fareast-language:RU'>Description</span></b><b><span style='font-size:\n" +
		"  10.0pt;font-family:\"Arial\",sans-serif;mso-fareast-font-family:\"Times New Roman\";\n" +
		"  mso-fareast-language:RU'><o:p></o:p></span></b></p>\n" +
		"\t\t</td>\n" +
		"\t\t<td style='border:solid black 1.0pt;border-left:none;mso-border-left-alt:\n" +
		"  solid #CCCCCC .75pt;mso-border-alt:solid black .75pt;mso-border-left-alt:\n" +
		"  solid #CCCCCC .75pt;background:#DBF2F9;padding:1.5pt 2.25pt 1.5pt 2.25pt;\n" +
		"  height:7.05pt'>\n" +
		"\t\t\t<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;\n" +
		"  line-height:normal'><b><span style='font-size:10.0pt;font-family:\"Arial\",sans-serif;\n" +
		"  mso-fareast-font-family:\"Times New Roman\";color:black;mso-color-alt:windowtext;\n" +
		"  mso-fareast-language:RU'>Qty,EA</span></b><b><span style='font-size:10.0pt;\n" +
		"  font-family:\"Arial\",sans-serif;mso-fareast-font-family:\"Times New Roman\";\n" +
		"  mso-fareast-language:RU'><o:p></o:p></span></b></p>\n" +
		"\t\t</td>\n" +
		"\t\t<td style='border:solid black 1.0pt;border-left:none;mso-border-left-alt:\n" +
		"  solid #CCCCCC .75pt;mso-border-alt:solid black .75pt;mso-border-left-alt:\n" +
		"  solid #CCCCCC .75pt;background:#DBF2F9;padding:1.5pt 2.25pt 1.5pt 2.25pt;\n" +
		"  height:7.05pt'>\n" +
		"\t\t\t<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;\n" +
		"  line-height:normal'><b><span style='font-size:10.0pt;font-family:\"Arial\",sans-serif;\n" +
		"  mso-fareast-font-family:\"Times New Roman\";color:black;mso-color-alt:windowtext;\n" +
		"  mso-fareast-language:RU'>MOQ</span></b><b><span style='font-size:10.0pt;\n" +
		"  font-family:\"Arial\",sans-serif;mso-fareast-font-family:\"Times New Roman\";\n" +
		"  mso-fareast-language:RU'><o:p></o:p></span></b></p>\n" +
		"\t\t</td>\n" +
		"\t\t<td style='border:solid black 1.0pt;border-left:none;mso-border-left-alt:\n" +
		"  solid #CCCCCC .75pt;mso-border-alt:solid black .75pt;mso-border-left-alt:\n" +
		"  solid #CCCCCC .75pt;background:#DBF2F9;padding:1.5pt 2.25pt 1.5pt 2.25pt;\n" +
		"  height:7.05pt'>\n" +
		"\t\t\t<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;\n" +
		"  line-height:normal'><b><span style='font-size:10.0pt;font-family:\"Arial\",sans-serif;\n" +
		"  mso-fareast-font-family:\"Times New Roman\";color:black;mso-color-alt:windowtext;\n" +
		"  mso-fareast-language:RU'>Unit price</span></b><b><span style='font-size:10.0pt;\n" +
		"  font-family:\"Arial\",sans-serif;mso-fareast-font-family:\"Times New Roman\";\n" +
		"  mso-fareast-language:RU'><o:p></o:p></span></b></p>\n" +
		"\t\t</td>\n" +
		"\t\t<td style='border:solid black 1.0pt;border-left:none;mso-border-left-alt:\n" +
		"  solid #CCCCCC .75pt;mso-border-alt:solid black .75pt;mso-border-left-alt:\n" +
		"  solid #CCCCCC .75pt;background:#DBF2F9;padding:1.5pt 2.25pt 1.5pt 2.25pt;\n" +
		"  height:7.05pt'>\n" +
		"\t\t\t<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;\n" +
		"  line-height:normal'><b><span style='font-size:10.0pt;font-family:\"Arial\",sans-serif;\n" +
		"  mso-fareast-font-family:\"Times New Roman\";color:black;mso-color-alt:windowtext;\n" +
		"  mso-fareast-language:RU'>Total Price</span></b><b><span style='font-size:\n" +
		"  10.0pt;font-family:\"Arial\",sans-serif;mso-fareast-font-family:\"Times New Roman\";\n" +
		"  mso-fareast-language:RU'><o:p></o:p></span></b></p>\n" +
		"\t\t</td>\n" +
		"\t\t<td style='border:solid black 1.0pt;border-left:none;mso-border-left-alt:\n" +
		"  solid #CCCCCC .75pt;mso-border-alt:solid black .75pt;mso-border-left-alt:\n" +
		"  solid #CCCCCC .75pt;background:#DBF2F9;padding:1.5pt 2.25pt 1.5pt 2.25pt;\n" +
		"  height:7.05pt'>\n" +
		"\t\t\t<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;\n" +
		"  line-height:normal'><b><span style='font-size:10.0pt;font-family:\"Arial\",sans-serif;\n" +
		"  mso-fareast-font-family:\"Times New Roman\";color:black;mso-color-alt:windowtext;\n" +
		"  mso-fareast-language:RU'>Certification</span></b><b><span style='font-size:\n" +
		"  10.0pt;font-family:\"Arial\",sans-serif;mso-fareast-font-family:\"Times New Roman\";\n" +
		"  mso-fareast-language:RU'><o:p></o:p></span></b></p>\n" +
		"\t\t</td>\n" +
		"\t\t<td style='border:solid black 1.0pt;border-left:none;mso-border-left-alt:\n" +
		"  solid #CCCCCC .75pt;mso-border-alt:solid black .75pt;mso-border-left-alt:\n" +
		"  solid #CCCCCC .75pt;background:#DBF2F9;padding:1.5pt 2.25pt 1.5pt 2.25pt;\n" +
		"  height:7.05pt'>\n" +
		"\t\t\t<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;\n" +
		"  line-height:normal'><b><span style='font-size:10.0pt;font-family:\"Arial\",sans-serif;\n" +
		"  mso-fareast-font-family:\"Times New Roman\";color:black;mso-color-alt:windowtext;\n" +
		"  mso-fareast-language:RU'>Lead time</span></b><b><span style='font-size:10.0pt;\n" +
		"  font-family:\"Arial\",sans-serif;mso-fareast-font-family:\"Times New Roman\";\n" +
		"  mso-fareast-language:RU'><o:p></o:p></span></b></p>\n" +
		"\t\t</td>\n" +
		"\t\t<td style='border:solid black 1.0pt;border-left:none;mso-border-left-alt:\n" +
		"  solid #CCCCCC .75pt;mso-border-alt:solid black .75pt;mso-border-left-alt:\n" +
		"  solid #CCCCCC .75pt;background:#DBF2F9;padding:1.5pt 2.25pt 1.5pt 2.25pt;\n" +
		"  height:7.05pt'>\n" +
		"\t\t\t<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;\n" +
		"  line-height:normal'><b><span style='font-size:10.0pt;font-family:\"Arial\",sans-serif;\n" +
		"  mso-fareast-font-family:\"Times New Roman\";color:black;mso-color-alt:windowtext;\n" +
		"  mso-fareast-language:RU'>Condition</span></b><b><span style='font-size:10.0pt;\n" +
		"  font-family:\"Arial\",sans-serif;mso-fareast-font-family:\"Times New Roman\";\n" +
		"  mso-fareast-language:RU'><o:p></o:p></span></b></p>\n" +
		"\t\t</td>\n" +
		"\t</tr>\n";
	return html;
}
function word_copy_footer()
{
	let html='';
	html+=
		"</table>\n" +
		"\n" +
		"<p class=MsoNormal>\n" +
		"\t<o:p>&nbsp;</o:p>\n" +
		"</p>\n" +
		"\n" +
		"<p class=MsoNormal>\n" +
		"\t<o:p>&nbsp;</o:p>\n" +
		"</p>\n" +
		"\n" +
		"<p class=MsoNormal>\n" +
		"\t<o:p>&nbsp;</o:p>\n" +
		"</p>\n" +
		"\n" +
		"<!--EndFragment-->\n" +
		"</body>\n" +
		"\n" +
		"</html>\n";
	return html;
}
export {word_copy_header, word_copy_footer};