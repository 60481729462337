<template>
	<td class="align-top">
		<div v-if="get_next_status!=false" class="col">
			<button @click="set_next_status()" type="button" class="btn btn-outline-primary btn-sm mb-2">{{get_next_status}}</button>
		</div>
	</td>
</template>

<script>
import axios from 'axios';
import config from '@/config.js'

export default {
	name: 'type-set-status-grid',
	props: {
		data: [Number],
		field: [Object],
		id: [Number],
		url: [String],
		name: [String],
	},
	methods: {
		set_next_status()
		{
			axios({url: config.API_LOCATION + this.url+ '/set_status/'+this.id+'/'+this.name+'/'+(this.data+1)+'/',  method: 'GET'})
				.then(resp => {
					if (resp.data.status == 'ok') {
						this.$parent.load();
					}
				})
				.catch(err => {

				})
		}
	},
	computed: {
		get_next_status()
		{
			let res='';
			let data = this.field.data;
			const next= this.data+1
			if(data.hasOwnProperty('id_'+next))
				return data['id_'+next].title
			else
				return false
		},
	}
}
</script>

<style scoped>

</style>
