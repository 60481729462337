<template>
	<div class="container-fluid">
		<div class="row justify-content-center">
			<div class="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5 align-self-center">

				<!-- Heading -->
				<h1 class="display-4 text-center mb-3">
					Личный кабинет
				</h1>

				<!-- Subheading -->
				<p class="text-muted text-center mb-5">
					Регистрация
				</p>

				<!-- Form -->



				<div class="form-group">

					<!-- Label -->
					<label class="form-label">
						Номер телефона
					</label>

					<!-- Input -->
					<input type="tel" v-model="phone" class="form-control" placeholder="+7 (999) 888-77-66" data-inputmask="'mask': '+ 7 (999) 999-99-99'">

				</div>

				<div class="form-group">

					<!-- Label -->
					<label class="form-label">
						Фамилия
					</label>

					<!-- Input -->
					<input type="text" v-model="surname" class="form-control" placeholder="Иванов" >

				</div>

				<div class="form-group">

					<!-- Label -->
					<label class="form-label">
						Имя
					</label>

					<!-- Input -->
					<input type="text" v-model="name" class="form-control" placeholder="Петр" >

				</div>

				<div class="form-group">

					<!-- Label -->
					<label class="form-label">
						Отчество
					</label>

					<!-- Input -->
					<input type="text" v-model="patronymic" class="form-control" placeholder="Сидорович" >

				</div>

				<div class="form-group">

					<!-- Label -->
					<label class="form-label">
						Дата рождения
					</label>

					<!-- Input -->
					<input type="text" v-model="birthday" class="form-control" placeholder="01.01.1990" data-inputmask="'mask': '99.99.9999'">
					<div v-if="error_1" class="badge bg-danger-soft">
						{{error_text_1}}
					</div>

				</div>

				<!-- Password -->
				<div class="form-group">
					<div class="row">
						<div class="col">

							<!-- Label -->
							<label v-if="sms_sent" class="form-label">
								Код
							</label>


						</div>
						<div class="col-auto">

							<!-- Help text -->
							<a  @click="$router.push('/login/code')" style="cursor: pointer;" class="form-text small text-muted">
								Войти по одноразовому паролю
							</a>

						</div>
					</div> <!-- / .row -->

					<!-- Input group -->
					<div v-if="sms_sent" class="input-group input-group-merge">

						<!-- Input -->
						<input v-model="code" class="form-control" type="text" placeholder="Введите код из смс" >

					</div>
					<div v-if="error_2" class="badge bg-danger-soft">
						{{error_text_2}}
					</div>
					<div v-if="timer>0">
						<label class="form-label">
							Повторно запросить можно через {{timer_text}}
						</label>
					</div>
					<a v-if="sms_sent&&timer==0" @click="send_sms" class="form-text small text-muted">Выслать код повторно</a>
					<div v-if="sms_sent" class="form-check form-switch">
						<input class="form-check-input" type="checkbox" v-model="not_save">
						<label class="form-check-label" for="switchOne"><small class="form-text text-muted">Не запоминать</small></label>
					</div>
				</div>

				<!-- Submit -->
				<button v-if="!sms_sent" @click="registration" :disabled="timer>0" class="btn btn-lg w-100 btn-primary mb-3" >Зарегистртироваться</button>
				<button v-if="sms_sent" @click="login" class="btn btn-lg w-100 btn-primary mb-3" >Войти</button>





			</div>
			<div class="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">

				<!-- Image -->
				<div class="bg-cover h-100 min-vh-100 mt-n1 me-n3" :style="ccsBackgroundImage"  ></div>

			</div>
		</div> <!-- / .row -->
	</div>

</template>
<script>
	import store from '@/store';
	import axios from 'axios';
	import config from '@/config.js'
	import Inputmask from 'inputmask'


	export default {
		components: {

		},
		store,
		data() {
			return {
				ccsBackgroundImage: {
					backgroundImage: `url(${require('@/img/dd/auth-side-cover-med.jpg')})`
				},
				phone : '',
				surname : '',
				name : '',
				patronymic : '',
				birthday : '',
				code : '',
				not_save : false,
				sms_sent : false,
				timer : 0,
				timer_text : '',
				timer_intervalid : 0,
				token_tmp :  localStorage.getItem('token_tmp') || '',
				error_1 : false,
				error_text_1 : '',
				error_2 : false,
				error_text_2 : ''
			}
		},
		methods: {
			registration: function ()
			{
				axios({url: config.API_LOCATION+'lk/login/registration/', data: {login:this.phone,surname:this.surname,name:this.name,patronymic:this.patronymic,birthday:this.birthday} , method: 'POST' })
					.then(resp => {
						console.log(resp)
						if(resp.data.status=='error'){
							this.error_1=true
							if(resp.data.error_code=='phone_use')
								this.error_text_1='Вы уже зарегистрированы. Попробуйте войти с помощью одноразового пароля'
							if(resp.data.error_code=='phone_error')
								this.error_text_1='Не корректный немер телефона'
							if(resp.data.error_code=='data_error')
								this.error_text_1='Не заполенны данные'
							if(resp.data.error_code=='sms_limit') {
								this.error_text_1 = 'Превышен лимит на отправку sms'
								this.sms_sent = true
								this.set_time(resp.data.sms_limit_timer)
							}
							if(resp.data.error_code=='sms_send_error') {
								this.error_text_1 = 'Не возможно отправить sms, обратитесь к администраторам центра'
							}
						}
						else {
							this.error_1=false
							this.error_2=false
							this.set_time(120)
							this.sms_sent = true
							localStorage.setItem('token_tmp', resp.data.token)
							this.token_tmp=resp.data.token
						}
					})
					.catch(err => {
						console.log(err)
					})
			},
			set_time: function (timer)
			{
				this.timer=timer
				this.timer_text=new Date(this.timer * 1000).toISOString().substr(14, 5);
				this.timer_intervalid = setInterval (function(){
					this.timer-=1
					if(this.timer==0) {
						clearInterval(this.timer_intervalid)
						this.timer_intervalid=0
					}
					else
						this.timer_text=new Date(this.timer * 1000).toISOString().substr(14, 5);
				}.bind(this), 1000);
			},
			login: function ()
			{
				let login = this.phone
				let surname=this.surname
				let name=this.name
				let patronymic=this.patronymic
				let birthday=this.birthday
				let code = this.code
				let token = this.token_tmp
				let not_save = this.not_save
				this.$store.dispatch('registration_sms', { login,surname,name,patronymic, birthday, code, token, not_save  })
					.then(function (){
						localStorage.removeItem('token_tmp')
						this.token_tmp=''
						if(this.timer_intervalid!=0)
							clearInterval(this.timer_intervalid)
						this.$router.push('/')
					}.bind(this))
					.catch(function (err){
						if(err.error_code=='phone_use') {
							this.error_1 = true
							this.error_text_1 = 'Вы уже зарегистрированы. Попробуйте войти с помощью одноразового пароля'
						}
						if(err.error_code=='phone_error') {
							this.error_1 = true
							this.error_text_1 = 'Не корректный немер телефона'
						}
						if(err.error_code=='data_error') {
							this.error_1 = true
							this.error_text_1 = 'Не заполенны данные'
						}
						if(err.error_code=='error_code'){
							this.error_2 = true
							this.error_text_2 = 'Неверный код'
						}
					}.bind(this))
			},
		},
		mounted ()
		{
			const toggles = document.querySelectorAll('[data-inputmask]');
			const options = {
				rightAlign: false,
			};
			Inputmask().mask(toggles);
		}

	}
</script>
<style>

</style>