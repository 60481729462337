<template>
	<td class="align-top">
		<div class="row">
			<div class="col-auto" style="padding-right: 5px;">
				<a href="#!" class="avatar avatar-sm">
					<img v-if="data.sex=='F'" src="@/img/avatars/profiles/w.jpg"  alt="..." class="avatar-img rounded-circle">
					<img v-else src="@/img/avatars/profiles/m.jpg"  alt="..." class="avatar-img rounded-circle">
				</a>
			</div>
			<div class="col ml-n2">
				<h4 class="mb-1 name">
					<a href="#!" style="white-space: normal;">{{data.fio}}</a>
				</h4>
				<p class="card-text small text-muted mb-1" v-for="phone in data.phone">
					+7 ({{ phone.code }}) {{phone.number}}
				</p>
			</div>
		</div>
	</td>
</template>

<script>
export default {
	name: 'type-person-grid',
	props: {
		data: [ Object]
	},
}
</script>

<style scoped>

</style>
