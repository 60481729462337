<template>
	<td class="align-top" style="white-space: normal; line-height: normal;">
		<template v-for="o in data">
			<b>{{o.n_view}}</b>
			<type-key-grid :data="o.status" :field="field.setting.field.status" />
			<br/>
		</template>
	</td>
</template>

<script>
export default {
	name: 'type-order-grid',
	props: {
		data: [ Object],
		field: [ Object],
	},
	methods: {

	},
}
</script>

<style scoped>

</style>
