<template>
	<div v-if="row.hasOwnProperty('client_order_file')" class="dropdown">
		<a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			<span  style="cursor: pointer;" class="fe fe-download ms-2"></span>
		</a>
		<div class="dropdown-menu dropdown-menu-end">
			<a v-if="row.client_order_file.id!=0" :href="'https://api-skyparts.litegroup.ru/release/element/'+row.__id+'/client_order_file/download/?token='+token" target="_blank" class="dropdown-item">Заказ клиента</a>
			<template v-for="o in row.order">
				<a :href="'https://api-skyparts.litegroup.ru/release/dowload_order/'+o.id+'/?token='+token" target="_blank" class="dropdown-item">Заказ {{o.n_view}}</a>
				<template v-for="(f,k) in o.file">
					<a v-if="f.file.id!=0" :href="'https://api-skyparts.litegroup.ru/release/child/'+row.__id+'/order/child/'+o.id+'/file/element/'+f.id+'/file/download/?token='+token" target="_blank" class="dropdown-item">Заказ {{o.n_view}} - {{f.title}}</a>
				</template>
			</template>
		</div>
	</div>
</template>
<script>
export default {

	props: {
		row: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			token: localStorage.getItem('token') || '',
		}
	},
}
</script>