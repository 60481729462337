<template>
	<lg-menu/>

	<div class="main-content">

		<div class="container-fluid">
			<div  class="row justify-content-center">
				<!-- HEADER -->
				<div class="header">
					<div class="container-fluid">

						<!-- Body -->
						<div class="header-body">
							<div class="row align-items-end">
								<div class="col">

									<!-- Pretitle -->
									<h6 class="header-pretitle">Рабочий стол / Отчеты </h6>

									<!-- Title -->
									<h1 class="header-title">Цены SPARES</h1>

								</div>


							</div> <!-- / .row -->
						</div> <!-- / .header-body -->

					</div>
				</div> <!-- / .header -->

				<!-- CARDS -->
				<div class="container-fluid">

					<div class="row">
						<div class="col-12 col-md-2">
							<div class="input-group input-group-merge input-group-reverse mb-3">
								<input class="form-control" placeholder="Offer" type="text"  v-model="filter.offer_n">
								<div class="input-group-text">
									<i class="las la-stream la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-2">
							<div class="input-group input-group-merge input-group-reverse mb-3">


							</div>
						</div>
						<div class="col-12 col-md-4">
							<div class="input-group input-group-merge input-group-reverse form-group">
								<input class="form-control" placeholder="P/N" type="text" v-model="filter.pn">
								<div class="input-group-text">
									<i class="las la-headset la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-12 col-md-4">
							<div class="input-group input-group-merge input-group-reverse form-group">
								<input class="form-control" placeholder="Клиент" type="text" v-model="filter.client">
								<div class="input-group-text">
									<i class="las la-user-circle la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-4">
							<div class="input-group input-group-merge input-group-reverse form-group">
								<input class="form-control" placeholder="Поставщик" type="text" v-model="filter.provider">
								<div class="input-group-text">
									<i class="las la-user-circle la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>

					</div>

					<div class="row">
						<lg-type-date-filter v-model="filter.date" :title="'Дата'"/>

						<div class="col-12 col-md-4">
							<div class="form-group">
								<label class="mb-3">&nbsp;</label>
								<div>
									<button @click="exec_filter()" class="btn btn-primary">Искать</button>
									<a @click="clear_filter()" class="btn btn-link text-muted">Очистить фильтр</a>
								</div>
							</div>
						</div>
					</div>





					<grid :url="url" :can_add="false" :can_edit="false" :can_del="false" ref="ref_grid"></grid>

				</div>
			</div>
		</div>
	</div>

</template>
<script>
import axios from 'axios';
import config from '@/config.js'
import Grid from "@/components/Grid";
export default {
	components: {
		Grid
	},
	data() {
		return {
			url: 'reports/pn',

			filter: {},
			default_filter: {
				offer_n: '', pn:'',  client: '',provider:'', date: {from:'',to:''},
			},
			filter_key: {},

			loading: true,
		}
	},
	mounted()
	{
		this.filter=structuredClone(this.default_filter);
	},
	methods: {
		async exec_filter ()
		{
			await this.$refs.ref_grid.exec_filter(this.filter);
		},
		load_filter(filter,key)
		{
			this.filter=filter;
			this.filter_key=key;
			this.loading=false;
		},
		async clear_filter(filter)
		{
			this.filter=structuredClone(this.default_filter);

//			console.log(this.$refs.filter_responsible.object.getValue(true));

//			console.log(this.$refs.filter_responsible.object.getValue(true));
			await this.$refs.ref_grid.exec_filter(this.filter);
		},
	}
}
</script>