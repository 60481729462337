<template>
	<lg-menu/>

	<div class="main-content">

		<div class="container-fluid">
			<div v-if="data!=null" class="row justify-content-center">


				<!-- Header -->
				<div class="header mt-md-5">
					<div class="header-body">
						<div class="row align-items-center">
							<div class="col">

								<!-- Pretitle -->
								<h6 class="header-pretitle">
									Рабочий стол / справочники
								</h6>

								<!-- Title -->
								<h1 class="header-title">
									Курсы валют
								</h1>

							</div>


							<div class="col">
								<div class="position-relative" style="height:44px;">
									<div v-if="Object.keys(save_list).length>0||data_saving" class="alert alert-light" role="alert">
										Данные сохраняются, не закрывайте вкладку!
									</div>
								</div>
							</div>

						</div> <!-- / .row -->

					</div>

				</div>

				<div class="card px-0">
					<div  class="">
						<table class="table table-sm table-nowrap table-nobcollapse card-table">
							<thead class="">
							<tr>
								<th>Из</th>
								<th>В</th>
								<th></th>
							</tr>
							</thead>
							<tbody class="list px-2">
							<tr v-for="(e,k) in data" :key="k">
								<td>
									{{e.from}}
								</td>
								<td>
									{{e.to}}
								</td>
								<td>
									<lg-input v-model="e.value" :type="'float'" :mask="'9{1,},9{4}'" size="8"/>
								</td>
							</tr>
							</tbody>

						</table>


					</div>
				</div>

			</div>
		</div>
	</div>
</template>
<script>
import axios from 'axios';
import {  updatedDiff } from 'deep-object-diff';
import objectAssignDeep from 'object-assign-deep'
import config from '@/config.js'

export default {
	data() {
		return {
			data: null,
			data_old: null,
			setting: null,

			save_list: {},
			data_saving: false,
			current: false,
			back_list: [],
		}
	},
	mounted ()
	{
		this.load()
	},
	methods: {
		async load() {
			axios({url: config.API_LOCATION + 'directories/exchange/'})
				.then(resp => {
					this.data = resp.data.data
					this.data_old = structuredClone(this.data)
					this.setting = resp.data.setting
				})
				.catch(err => {
					console.log(err)
				})

			setTimeout(this.save, 10000)
		},
		async save() {
			if (Object.keys(this.save_list).length == 0) {
				setTimeout(this.save, 10000)
				return
			}
			this.data_saving = true
			let save_data = {row: {}}
			for(const k in this.save_list)
				save_data.row[this.data[k].id] = structuredClone(this.save_list[k])
			this.save_list = {}

			axios({url: config.API_LOCATION + 'directories/exchange/save_list/', data: save_data, method: 'POST'})
				.then(resp => {
					if (resp.data.status == 'ok') {
						this.data_saving = false
						this.current = true
						setTimeout(this.save, 10000)
					}
				})
				.catch(err => {

				})
		},
		backspace() {
			if (this.back_list.length == 0)
				return
//			let t=this.data_old
			eval("this.data_old" + this.back_list[this.back_list.length - 1].name + "='" + this.back_list[this.back_list.length - 1].value + "'")
//			console.log("this.data_old"+this.back_list[this.back_list.length-1].name+"='"+this.back_list[this.back_list.length-1].value+"'")
//			console.log(t)
//			console.log(this.data_old)
			let update = updatedDiff(this.data, this.data_old)
			this.data = structuredClone(this.data_old)
			objectAssignDeep(this.save_list, update)
			this.back_list.pop()
//			console.log(this.back_list.slice(1,1))
		},
		save_empty() {
			if (this.current)
				return
			let save_data = {row: {}}
			save_data.row[this.data.__id] = {}
			axios({url: config.API_LOCATION + 'directories/exchange/save_list/', data: save_data, method: 'POST'})
				.then(resp => {
					if (resp.data.status == 'ok') {
						this.current = true
					}
				})
				.catch(err => {

				})
		},
	},
	watch: {
		data: {
			handler(){
//				console.log('up')
//				console.log(this.data_old)
//				console.log(updatedDiff(this.data_old, this.data));
				let update=updatedDiff(this.data_old, this.data)
				if(Object.keys(update).length === 0)
					return
				let get_name= function(o)
				{
					return "['"+Object.keys(o)[0]+"']"+(typeof o[Object.keys(o)[0]] === 'object' && !Array.isArray(o[Object.keys(o)[0]]) && o[Object.keys(o)[0]] !== null?get_name(o[Object.keys(o)[0]]):'')
				}
				let name=get_name(update)
//				console.log(name)
				let old_value=eval('this.data_old'+name)
//				console.log(old_value)
				this.back_list.push({name:name,value:old_value})
				objectAssignDeep(this.save_list,update)
				this.data_old=structuredClone(this.data)
			},
			deep: true
		}
	},
}
</script>