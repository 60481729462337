<template>
	<input v-model="value" @change="updateInput" type="checkbox" />
</template>
<script>
export default {
	name: 'lg-checkbox',

	data() {
		return {
			value: false,
		}
	},
	props: {
		modelValue: {
			type: [ Number]
		},
	},
	mounted ()
	{
		this.value=(this.modelValue===1)
	},
	methods: {
		updateInput(event)
		{
			this.$emit('update:modelValue', (this.value?1:0))
		},
		update_value(val)
		{
			this.value=(val===1)
		}
	},
}
</script>