import LgDate from "@/components/UI/Date";
import LgSelect from "@/components/UI/Select";
import LgSelectMulti from "@/components/UI/SelectMulti";
import LgSelectAutocomplete from "@/components/UI/SelectAutocomplete";
import LgInput from "@/components/UI/Input";
import LgInputAutocomplete from "@/components/UI/InputAutocomplete";
import LgCheckbox from "@/components/UI/Checkbox";
import TypeStringGrid from "@/components/UI/TypeStringGrid";
import TypeDateGrid from "@/components/UI/TypeDateGrid";
import TypeBoolGrid from "@/components/UI/TypeBoolGrid";
import TypeLinkGrid from "@/components/UI/TypeLinkGrid";
import TypeDateFilter from "@/components/UI/TypeDateFilter";
import TypePersonGrid from "@/components/UI/TypePersonGrid";
import TypeChildGridTwo from "@/components/UI/TypeChildGridTwo";
import TypeChildGridOne from "@/components/UI/TypeChildGridOne";
import TypeKeyGrid from "@/components/UI/TypeKeyGrid";
import TypeLogGrid from "@/components/UI/TypeLogGrid";
import TypeSetStatusGrid from "@/components/UI/TypeSetStatusGrid";
import TypeStringSeveralGrid from "@/components/UI/TypeStringSeveralGrid";
import TypeMoneyGrid from "@/components/UI/TypeMoneyGrid";
import TypeFileGrid from "@/components/UI/TypeFileGrid";

export default [
	LgDate,
	LgSelect,
	LgSelectMulti,
	LgSelectAutocomplete,
	LgInput,
	LgInputAutocomplete,
	LgCheckbox,
	TypeStringGrid,
	TypeDateGrid,
	TypeBoolGrid,
	TypeLinkGrid,
	TypeDateFilter,
	TypePersonGrid,
	TypeChildGridTwo,
	TypeChildGridOne,
	TypeKeyGrid,
	TypeLogGrid,
	TypeSetStatusGrid,
	TypeStringSeveralGrid,
	TypeMoneyGrid,
	TypeFileGrid
]