<template>
	<input v-model="value" @input="updateInput" type="text" class="form-control form-control-sm" :style="[width!=0 ? { width: width+'px'}:{}]" :placeholder="placeholder" :disabled="disabled">
</template>
<script>
export default {
	name: 'lg-input',

	data() {
		return {
			value: '',
		}
	},
	props: {
		modelValue: {
			type: [String, Number]
		},
		mask: {
			type: String,
			default: ''
		},
		width: {
			type: Number,
			default: 0
		},
		type: {
			type: String,
			default: 'string'
		},
		placeholder: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	mounted ()
	{
		if(this.type=='float')
			this.value=String(this.modelValue).replace(/\./, ',')
		else
			this.value=String(this.modelValue);
		if(this.type=='float'&&this.mask.indexOf(',9{2}')!=-1){
			if(this.value.indexOf(',')!=-1&&this.value.indexOf(',')==this.value.length-2)
				this.value+='0';
			if(this.value.indexOf(',')==-1)
				this.value+=',00';
		}

		if(this.mask!='')
			Inputmask({"mask": this.mask}).mask(this.$el);
	},
	methods: {
		updateInput(event)
		{
			let res=event.target.value;
			if(this.type=='int'||this.type=='float')
				res=res.replace(/,/, '.')
			if(this.type=='int')
				res=parseInt(res)
			if(this.type=='float')
				res=parseFloat(res)
			this.$emit('update:modelValue', res)
		},
		update_value(val)
		{
			if(this.type=='float')
				this.value=String(val).replace(/\./, ',')
			else
				this.value=String(val);
			if(this.type=='float'&&this.mask.indexOf(',9{2}')!=-1){
				if(this.value.indexOf(',')!=-1&&this.value.indexOf(',')==this.value.length-2)
					this.value+='0';
				if(this.value.indexOf(',')==-1)
					this.value+=',00';
			}
		}
	},
	watch: {
		/*modelValue() {
			this.value=String(this.modelValue).replace(/\./, ',')
			if(this.type=='float'&&this.mask.indexOf(',9{2}')!=-1){
				if(this.value.indexOf(',')!=-1&&this.value.indexOf(',')==this.value.length-2)
					this.value+='0';
				if(this.value.indexOf(',')==-1)
					this.value+=',00';
			}
		}*/
	}
}
</script>