<template>
	<lg-menu/>

	<div class="main-content">

		<div class="container-fluid">
			<div  class="row justify-content-center">
				<!-- HEADER -->
				<div class="header">
					<div class="container-fluid">

						<!-- Body -->
						<div class="header-body">
							<div class="row align-items-end">
								<div class="col">

									<!-- Pretitle -->
									<h6 class="header-pretitle">Рабочий стол</h6>

									<!-- Title -->
									<h1 class="header-title">Release</h1>

								</div>
								<div v-if="false" class="col-auto">
									<button type="button" class="btn btn-secondary btn-light btn-sm"><span class="fe fe-minimize-2"></span></button>
									<button type="button" class="btn btn-secondary btn-light btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="fe fe-filter"></span> Настройки фильтра</button>

									<div class="dropdown-menu">
										<a class="dropdown-item">Новые обращения</a>
										<a class="dropdown-item">В стадии решения</a>
										<a class="dropdown-item">Закрытые заявки</a>
									</div>
								</div>

							</div> <!-- / .row -->
						</div> <!-- / .header-body -->

					</div>
				</div> <!-- / .header -->

				<!-- CARDS -->
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-md-2">
							<div class="input-group input-group-merge input-group-reverse mb-3">
								<input class="form-control" placeholder="Release" type="text"  v-model="filter.n">
								<div class="input-group-text">
									<i class="las la-stream la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-2">
							<div class="input-group input-group-merge input-group-reverse mb-3">
								<input class="form-control" placeholder="Quote" type="text"  v-model="filter.quote">
								<div class="input-group-text">
									<i class="las la-stream la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-2">
							<div class="input-group input-group-merge input-group-reverse mb-3">
								<input class="form-control" placeholder="Ref" type="text"  v-model="filter.ref">
								<div class="input-group-text">
									<i class="las la-stream la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-2">
							<div class="input-group input-group-merge input-group-reverse mb-3">
								<input class="form-control" placeholder="Order" type="text"  v-model="filter.order">
								<div class="input-group-text">
									<i class="las la-stream la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>


					</div>
					<div class="row">
						<div class="col-12 col-md-2">
							<div class="input-group d-block mb-2">
								<lg-select-multi ref="filter_client" v-if="!loading" v-model="filter.client" :options="filter_key.client"  :placeholder="'Клиент'" class="form-control" />
							</div>
						</div>
						<div class="col-12 col-md-2">
							<div class="input-group input-group-merge input-group-reverse form-group">
								<input class="form-control" placeholder="P/N" type="text" v-model="filter.spares">
								<div class="input-group-text">
									<i class="las la-headset la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-2">
							<div class="input-group input-group-merge input-group-reverse mb-3">
								<input class="form-control" placeholder="Invoice" type="text"  v-model="filter.invoice">
								<div class="input-group-text">
									<i class="las la-stream la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-2">
							<div class="input-group input-group-merge input-group-reverse mb-3">
								<input class="form-control" placeholder="Client Order #" type="text"  v-model="filter.client_order_n">
								<div class="input-group-text">
									<i class="las la-stream la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12 col-md-2">
							<div class="input-group d-block mb-3">
								<lg-select-multi ref="filter_status" v-if="!loading" v-model="filter.status" :options="filter_key.status"  :placeholder="'Статус'" class="form-control" />
							</div>
						</div>
						<div class="col-12 col-md-2">
							<div class="input-group d-block mb-3">
								<lg-select-multi ref="filter_payed" v-if="!loading" v-model="filter.payed" :options="filter_key.payed"  :placeholder="'Оплата поставщику'" class="form-control" />
							</div>
						</div>
						<div class="col-12 col-md-2">
							<div class="input-group d-block mb-3">
								<lg-select-multi ref="filter_payed_customers" v-if="!loading" v-model="filter.payed_customers" :options="filter_key.payed_customers"  :placeholder="'Отлата от клиента'" class="form-control" />
							</div>
						</div>
						<div class="col-12 col-md-2">
							<div class="input-group input-group-merge input-group-reverse mb-3">
								<input class="form-control" placeholder="Proforma #" type="text"  v-model="filter.proforma">
								<div class="input-group-text">
									<i class="las la-stream la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12 col-md-4">
							<div class="input-group d-block mb-3">
								<lg-select-multi ref="filter_responsible" v-if="!loading" v-model="filter.responsible" :options="filter_key.responsible"  :placeholder="'Ответсвенный'" class="form-control" />
							</div>
						</div>
						<div class="col-12 col-md-2">
							<div class="input-group d-block mb-3">
								<lg-select-multi ref="filter_payed_customers_commit" v-if="!loading" v-model="filter.payed_customers_commit" :options="filter_key.payed_customers_commit"  :placeholder="'Усверждение отлаты от клиента'" class="form-control" />
							</div>
						</div>
						<div class="col-12 col-md-2">
							<div class="input-group d-block mb-3">
								<lg-select-multi ref="filter_unit" v-if="!loading" v-model="filter.unit" :options="filter_key.unit"  :placeholder="'Компания'" class="form-control" />
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12 col-md-2">
							<div class="input-group input-group-merge input-group-reverse mb-3">
								<lg-input-autocomplete v-if="!loading" v-model="filter.invoice_intermediary" :placeholder="'Посредник инвойс'" :url="'release/child/0/invoice_intermediary/element/0/intermediary/autocomplete/'" />
							</div>
						</div>
						<div class="col-12 col-md-2">
							<div class="input-group input-group-merge input-group-reverse mb-3">
								<lg-input-autocomplete v-if="!loading" v-model="filter.proforma_intermediary" :placeholder="'Посредник проформа'" :url="'release/child/0/proforma_intermediary/element/0/intermediary/autocomplete/'" />
							</div>
						</div>
						<div class="col-12 col-md-2">
							<div class="input-group input-group-merge input-group-reverse mb-3">
								<input class="form-control" placeholder="Местоположение груза" type="text"  v-model="filter.invoice_location">
								<div class="input-group-text">
									<i class="las la-stream la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-2">
							<div class="input-group d-block mb-3">
								<lg-select-multi ref="filter_finance_exist" v-if="!loading" v-model="filter.finance_exist" :options="filter_key.finance_exist"  :placeholder="'Есть order без потока'" class="form-control" />
							</div>
						</div>
					</div>

					<div class="row">
						<lg-type-date-filter v-model="filter.date" :title="'Дата'"/>
						<div v-if="false" class="col-12 col-md-2">
							<div class="form-group">
								<label class="mb-3">Дата изменения</label>
								<div class="input-group input-group-merge">
									<input id="date" type="text" class="form-control" placeholder="ДД.ММ.ГГГГ">
									<div class="input-group-text">
										<span class="fe fe-x mr-2"></span>
										<span class="fe fe-calendar"></span>
									</div>
								</div>
							</div>
						</div>

						<div v-if="false" class="col-12 col-md-2">
							<div class="form-group">
								<label class="mb-3">&nbsp;</label>
								<div class="input-group input-group-merge">
									<input id="date" type="text" class="form-control" placeholder="ДД.ММ.ГГГГ">
									<div class="input-group-text">
										<span class="fe fe-x mr-2"></span>
										<span class="fe fe-calendar"></span>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-4">
							<div class="form-group">
								<label class="mb-3">&nbsp;</label>
								<div>
									<button @click="exec_filter()" class="btn btn-primary">Искать</button>
									<a @click="clear_filter()" class="btn btn-link text-muted">Очистить фильтр</a>
								</div>
							</div>
						</div>
					</div>

					<grid :url="url" :can_add="false" :release_menu="true" ref="ref_grid"></grid>

				</div>
			</div>
		</div>
	</div>

</template>
<script>
import Grid from "@/components/Grid";
import axios from 'axios';
import config from '@/config.js'
import LgSelectMulti from "../../components/UI/SelectMulti.vue";


export default {
	components: {
		LgSelectMulti,
		Grid
	},
	data() {
		return {
			url: 'release',
			filter: {},
			default_filter: {
				unit: [], n: '', quote:'', responsible:[], client: [], date: {from:'',to:''},spares:[], status: [], payed: [], ref: '', order: '', client_order_n: '', invoice: '', proforma: '', payed_customers: [], payed_customers_commit: [], invoice_intermediary: '', proforma_intermediary: '', invoice_location: ''
			},
			filter_key: {},

			loading: true,
		}
	},
	mounted()
	{
		this.filter=structuredClone(this.default_filter);
	},
	methods: {
		async exec_filter ()
		{
			await this.$refs.ref_grid.exec_filter(this.filter);
		},
		load_filter(filter,key)
		{
			this.filter=filter;
			this.filter_key=key;
			this.loading=false;
		},
		async clear_filter(filter)
		{
			this.$refs.filter_client.object.removeActiveItems();
//			this.$refs.filter_unit.object.removeActiveItems();
			this.$refs.filter_responsible.object.removeActiveItems();
			this.$refs.filter_status.object.removeActiveItems();
			this.$refs.filter_payed.object.removeActiveItems();
			this.$refs.filter_payed_customers.object.removeActiveItems();
			this.$refs.filter_payed_customers_commit.object.removeActiveItems();
			let unit=structuredClone(this.filter.unit);
			this.filter=structuredClone(this.default_filter);
			this.filter.unit=unit;

//			console.log(this.$refs.filter_responsible.object.getValue(true));

//			console.log(this.$refs.filter_responsible.object.getValue(true));
			await this.$refs.ref_grid.exec_filter(this.filter);
		}
	}
}
</script>


<style>
.card-table thead th:first-child, .card-table tbody td:first-child {
	padding-left: 0!important;
}
.card-table thead tr th {
	font-size: 0.66em!important;
	text-align: center;
}
.card-table tbody tr td {
	font-size: 0.86em;
}

.filter-item div {
	border: 1px dashed #ccc;
    padding: 0.25em 0.5em;
    border-radius: 0.5em;
    margin-bottom: 0.5em;
}
.filter-item div {
	border: 1px dashed #ccc;
    padding: 0.25em 0.5em;
    border-radius: 0.5em;
    margin-bottom: 0.5em;
	cursor: pointer;
}
.form-control-flush {
	outline: 0;
}
</style>