<template>


	<lg-menu/>

	<div class="main-content">

		<div class="container-fluid">
			<div v-if="data!=null" class="row justify-content-center">


				<!-- Header -->
				<div class="header mt-md-5">
					<div class="header-body">
						<div class="row align-items-center">
							<div class="col">

								<!-- Pretitle -->
								<h6 class="header-pretitle">
									Рабочий стол
								</h6>

								<!-- Title -->
								<h1 class="header-title">
									Поток № {{data.n}}
								</h1>

							</div>


							<div class="col">
								<div class="position-relative" style="height:44px;">
									<div v-if="Object.keys(save_list).length>0||data_saving" class="alert alert-light" role="alert">
										Данные сохраняются, не закрывайте вкладку!
									</div>
								</div>
							</div>

							<div class="col">
								<div class="position-relative" style="height:44px;">
									<div v-if="error!=''" class="alert alert-danger" role="alert">
										{{error}}
									</div>
								</div>
							</div>

						</div> <!-- / .row -->

					</div>

				</div>







				<div class="col">
					<div class="row gx-3">


						<div class="col-12 col-md-2">

							<!-- Birthday -->
							<div class="form-group">

								<!-- Label -->
								<label class="form-label">
									Дата
								</label>

								<!-- Input -->
								<lg-date class="form-control-sm" v-model="data.date"/>

							</div>

						</div>
						<div class="col-12 col-md-1">

							<!-- First name -->
							<div class="form-group">

								<!-- Label -->
								<label class="form-label">
									# Release
								</label>

								<!-- Input -->
								<lg-input v-model="data.release_n" />

							</div>

						</div>
						<div class="col-12 col-md-1">

							<!-- First name -->
							<div class="form-group">

								<!-- Label -->
								<label class="form-label">
									# Order
								</label>

								<!-- Input -->
								<lg-input v-model="data.order_n" />

							</div>

						</div>
						<div class="col-12 col-md-1">

							<!-- First name -->
							<div class="form-group">

								<!-- Label -->
								<label class="form-label">
									# Client order
								</label>

								<!-- Input -->
								<lg-input v-model="data.client_order_n" />

							</div>

						</div>


						<div class="col-12 col-md-6 text-md-end d-flex justify-content-md-end">


							<div class="form-group float-end">
								<label class="form-label invisible d-none d-md-block">
									К списку
								</label>
								<button @click="save_manual()" type="button" class="btn btn-success" :disabled="data.client==0"> Сохранить</button>
							</div>
							<div class="form-group float-end">
								<label class="form-label invisible d-none d-md-block">
									К списку
								</label>
								<button @click="save_and_exit()" type="button" class="btn btn-secondary ms-2 mb-2">К списку</button>

							</div>
						</div>

					</div> <!-- / .row -->


					<div class="row gx-3">


						<div class="col-12 col-md-12">

							<!-- Birthday -->
							<div class="form-group">

								<!-- Label -->
								<label class="form-label">
									Файлы:
								</label>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								<span v-for="f in file"><a :href="'https://api-skyparts.litegroup.ru/'+f.link+'?token='+token" target="_blank" >{{f.title}}</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

							</div>

						</div>
					</div>
				</div>

				<div class="header-body">
					<div class="row align-items-center">

						<div class="col order-last order-md-first">

							<!-- Nav -->
							<ul class="nav nav-tabs nav-overflow header-tabs">
								<li class="nav-item">
									<a @click="tab_active='USD'" class="nav-link" :class="{active:tab_active=='USD'}" style="cursor: pointer;">

									</a>
								</li>

							</ul>

						</div>

					</div>

				</div>

				<div v-if="tab_active=='USD'" class="card px-0">
					<div  class="">
						<table class="table table-sm table-nowrap table-nobcollapse card-table">
							<thead class="">
							<tr>
								<th style="" class="text-start">Платильщик</th>
								<th style="">Продавец</th>
								<th style="" class="text-center">№</th>
								<th style="" class="text-center">Сумма</th>
								<th style="">Сумма факт RUB</th>
								<th style="">Сумма факт USD</th>
								<th style="" class="text-center">Дата оплаты</th>
								<th style="" class="text-center">Дата получения</th>
								<th style="width:15px;"></th>
							</tr>
							</thead>
							<tbody class="list px-2">
								<tr>
									<td><lg-select v-model="data.client" :options="setting.field.client.data"/></td>
									<td>
										<lg-select-autocomplete v-model="data.intermediary_client" :placeholder="'Посредник'" :data="setting.field.intermediary_client.data" :url="'payment/finance/element/0/intermediary_client/autocomplete/'" />
									</td>
									<td><lg-input v-model="data.client_n" class="mb-3" /></td>
									<td><lg-input v-model="data.client_sum" :type="'float'" :mask="'9{1,},9{2}'" :width="70" class="d-inline-flex" /></td>
									<td><lg-input v-model="data.client_sum_fact_rub" :type="'float'" :mask="'9{1,},9{2}'" :width="70" class="d-inline-flex" /></td>
									<td><lg-input v-model="data.client_sum_fact_usd" :type="'float'" :mask="'9{1,},9{2}'" :width="70" class="d-inline-flex" /></td>
									<td><lg-date class="form-control-sm" v-model="data.client_date_pay"/></td>
									<td><lg-date class="form-control-sm" v-model="data.client_date_get"/></td>
									<td class="text-center"></td>
								</tr>
								<tr v-for="(s,k) in step" :key="k">
									<td>
										<lg-select-autocomplete v-model="s.from" :placeholder="'Посредник'" :data="setting.field.intermediary_provider.data" :url="'payment/finance/element/0/intermediary_provider/autocomplete/'" />
									</td>
									<td>
										<lg-select-autocomplete v-model="s.to" :placeholder="'Посредник'" :data="setting.field.intermediary_provider.data" :url="'payment/finance/element/0/intermediary_provider/autocomplete/'" />
									</td>
									<td><lg-input v-model="s.n" class="mb-3" /></td>
									<td><lg-input v-model="s.sum" :type="'float'" :mask="'9{1,},9{2}'" :width="70" class="d-inline-flex" /></td>
									<td><lg-input v-model="s.sum_fact_rub" :type="'float'" :mask="'9{1,},9{2}'" :width="70" class="d-inline-flex" /></td>
									<td><lg-input v-model="s.sum_fact_usd" :type="'float'" :mask="'9{1,},9{2}'" :width="70" class="d-inline-flex" /></td>
									<td><lg-date class="form-control-sm" v-model="s.date_pay"/></td>
									<td><lg-date class="form-control-sm" v-model="s.date_get"/></td>
									<td class="text-center">
										<div class="col">
											<span  @click="sort_step(s.id,k,-1)" style="cursor: pointer;" class="fe fe-arrow-up ms-2"></span>
											<span  @click="sort_step(s.id,k,1)" style="cursor: pointer;" class="fe fe-arrow-down ms-2"></span>
											<span  @click="delete_step(s.id,k)" style="cursor: pointer;" class="fe fe-trash ms-2"></span>
										</div>
									</td>
								</tr>
								<tr>
									<td>
										<lg-select-autocomplete v-model="data.intermediary_provider" :placeholder="'Посредник'" :data="setting.field.intermediary_provider.data" :url="'payment/finance/element/0/intermediary_provider/autocomplete/'" />
									</td>
									<td>
										<lg-select-autocomplete v-model="data.provider" :placeholder="'Поставщик'" :data="setting.field.provider.data" :url="'payment/finance/element/0/provider/autocomplete/'" />
									</td>
									<td><lg-input v-model="data.provider_n" class="mb-3" /></td>
									<td><lg-input v-model="data.provider_sum" :type="'float'" :mask="'9{1,},9{2}'" :width="70" class="d-inline-flex" /></td>
									<td><lg-input v-model="data.provider_sum_fact_rub" :type="'float'" :mask="'9{1,},9{2}'" :width="70" class="d-inline-flex" /></td>
									<td><lg-input v-model="data.provider_sum_fact_usd" :type="'float'" :mask="'9{1,},9{2}'" :width="70" class="d-inline-flex" /></td>
									<td><lg-date class="form-control-sm" v-model="data.provider_date_pay"/></td>
									<td><lg-date class="form-control-sm" v-model="data.provider_date_get"/></td>
									<td class="text-center"></td>
								</tr>
							</tbody>
						</table>

						<div class="kanban-add">
							<div class="card card-sm card-flush mb-0">
								<div class="card-body">

									<!-- Link -->
									<div class="text-center">
										<a @click="add_step()" class="kanban-add-link" style="cursor: pointer;">Добавить</a>
									</div>



								</div>
							</div>
						</div>

					</div>
				</div>






			</div>
		</div> <!-- / .row -->
	</div>

	<confirm_delete ref="confirm_delete" @del="do_delete_step" :text="'Вы уверенные что хотите удалить?'"/>

</template>
<script>
import axios from 'axios';
import { Modal } from 'bootstrap';
import {  updatedDiff } from 'deep-object-diff';
import objectAssignDeep from 'object-assign-deep'
import config from '@/config.js'
import router from "@/router/router";
import confirm_delete from '@/components/UI/ConfirmDelete.vue'

export default {
	components: { confirm_delete },
	data() {
		return {
			data: null,
			data_old: null,
			data_step: [],
			setting: null,
			currency: null,
			option: null,
			formatter_money: null,
			file: null,
			save_list: {},
			data_saving: false,
			current: false,
			back_list: [],
			tab_active: 'USD',
			sort: 0,

			error: '',

			token: localStorage.getItem('token') || '',

			delete_step_id: 0,
			delete_step_k: '',
		}
	},
	mounted ()
	{
		this.load()
	},
	methods: {
		async load()
		{
			axios({url: config.API_LOCATION+'payment/finance/edit/'+this.$route.params.id+'/' })
				.then(resp => {
					let data=resp.data.data[Object.keys(resp.data.data)[0]]
					this.data=data
					this.data_old=structuredClone(this.data)
					this.setting=resp.data.setting
					this.currency=resp.data.currency
					this.option=resp.data.option
					this.file=resp.data.file
					for(const s_k in this.data.step) {
						if (this.sort < this.data.step[s_k].sort)
							this.sort = this.data.step[s_k].sort;
					}
					this.sort++

					this.check_error()
				})
				.catch(err => {
					console.log(err)
				})

			this.formatter_money={
				USD:new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'USD'}),
				EUR:new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'EUR'}),
				RUB:new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB'}),
				AED:new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'AED'}),
			}
			setTimeout(this.save,10000)
		},
		async save()
		{
			if(Object.keys(this.save_list).length==0||this.data_saving) {
				setTimeout(this.save, 10000)
				return
			}
			this.data_saving=true
			let save_data={row:{}}
			save_data.row[this.data.__id]=structuredClone(this.save_list)
			this.save_list={}

			axios({url: config.API_LOCATION+'payment/finance/save_list/', data: save_data, method: 'POST' })
				.then(resp => {
					if(resp.data.status=='ok') {
						this.data_saving=false
						this.current=true
						setTimeout(this.save,10000)
					}
				})
				.catch(err => {

				})
		},
		async save_and_exit()
		{
			this.data_saving=true
			let save_data={row:{}}
			save_data.row[this.data.__id]=structuredClone(this.save_list)
			this.save_list={}

			axios({url: config.API_LOCATION+'payment/finance/save_list/', data: save_data, method: 'POST' })
				.then(resp => {
					if(resp.data.status=='ok') {
						this.data_saving=false
						this.current=true
						router.push('/payment/finance')
					}
				})
				.catch(err => {

				})
		},
		async save_manual()
		{
			this.data_saving=true
			let save_data={row:{}}
			save_data.row[this.data.__id]=structuredClone(this.save_list)
			this.save_list={}

			axios({url: config.API_LOCATION+'payment/finance/save_list/', data: save_data, method: 'POST' })
				.then(resp => {
					if(resp.data.status=='ok') {
						this.data_saving=false
						this.current=true
					}
				})
				.catch(err => {

				})
		},
		save_empty()
		{
			if(this.current)
				return
			let save_data={row:{}}
			save_data.row[this.data.__id]={}
			axios({url: config.API_LOCATION+'payment/finance/save_list/', data: save_data, method: 'POST' })
				.then(resp => {
					if(resp.data.status=='ok') {
						this.current=true
					}
				})
				.catch(err => {

				})
		},
		cancel()
		{
			axios({url: config.API_LOCATION+'payment/finance/cancel/'+this.data.__id+'/' })
				.then(resp => {
					if(resp.data.status=='ok') {
						this.save_and_exit()
					}
				})
				.catch(err => {
					console.log(err)
				})
		},
		check_error()
		{
			if(this.data.client==0){
				this.error='Не выбран клиент';
				return;
			}
			if(this.data.provider==0){
				this.error='Не выбран поставщик';
				return;
			}
			if(String(this.data.order_n)==''){
				this.error='Пустой order #';
				return;
			}
			if(String(this.data.client_order_n)==''){
				this.error='Пустой client order #';
				return;
			}
			let price=this.data.client_sum;
			let to=this.data.intermediary_client;
			for(let k in this.step){
				if(price>0&&price<this.data.step[k].sum){
					this.error='Сумма покупки выше продажи';
					return;
				}
				if(to!=this.data.step[k].from){
					this.error='Разрыв потока';
					return;
				}
				price=this.data.step[k].sum;
				to=this.data.step[k].to;
			}
			if(price>0&&price<this.data.provider_sum){
				this.error='Сумма покупки выше продажи';
				return;
			}
			if(to!=this.data.intermediary_provider){
				this.error='Разрыв потока';
				return;
			}
			price=this.data.client_sum_fact_usd;
			for(let k in this.step){
				if(price>0&&price<this.data.step[k].sum_fact_usd){
					this.error='Фактическая сумма покупки выше продажи';
					return;
				}
				price=this.data.step[k].sum_fact_usd;
			}
			if(price<this.data.provider_sum_fact_usd){
				this.error='Фактическая сумма покупки выше продажи';
				return;
			}
			this.error='';
		},
		add_step()
		{
			axios({url: config.API_LOCATION+'payment/finance/child/'+this.data.__id+'/step/add/' })
				.then(resp => {
					if(resp.data.status=='ok') {
						let id=Object.keys(resp.data.data)[0]
						this.data_old.step[id]=resp.data.data[id]
						this.data=structuredClone(this.data_old)
						this.data.step[id].sort=this.sort
						this.sort++
						this.save_empty()
						this.check_error()
					}
				})
				.catch(err => {

				})
		},
		sort_step(id,k,to)
		{
			let to_k=''
			let to_s=(to==-1?0:9999999999999);
			for(let s_k in this.data.step){
				if(to==-1) {
					if (this.data.step[s_k].sort>to_s&&this.data.step[s_k].sort<this.data.step[k].sort) {
						to_s = this.data.step[s_k].sort
						to_k=s_k
					}
				}
				if(to==1) {
					if (this.data.step[s_k].sort<to_s&&this.data.step[s_k].sort>this.data.step[k].sort) {
						to_s = this.data.step[s_k].sort
						to_k=s_k
					}
				}
			}
			if(to_k!='') {
				this.data.step[to_k].sort=this.data.step[k].sort
				this.data.step[k].sort=to_s
			}
		},
		delete_step(id,k)
		{
			this.delete_step_id=id
			this.delete_step_k=k
			this.$refs['confirm_delete'].show()
		},
		do_delete_step()
		{
			axios({url: config.API_LOCATION+'payment/finance/child/'+this.data.__id+'/step/delete/'+this.delete_step_id+'/' })
				.then(resp => {
					if(resp.data.status=='ok') {
						delete this.data_old.step[this.delete_step_k]
						this.data=structuredClone(this.data_old)
						this.save_empty()
						this.check_error()
						this.delete_step_k=''
					}
				})
				.catch(err => {

				})
			this.delete_step_id=0
		},
	},
	watch: {
		data: {
			handler(){
//				console.log('up')
//				console.log(this.data_old)
//				console.log(updatedDiff(this.data_old, this.data));
				let update=updatedDiff(this.data_old, this.data)
				if(Object.keys(update).length === 0)
					return
				let get_name= function(o)
				{
					return "['"+Object.keys(o)[0]+"']"+(typeof o[Object.keys(o)[0]] === 'object' && !Array.isArray(o[Object.keys(o)[0]]) && o[Object.keys(o)[0]] !== null?get_name(o[Object.keys(o)[0]]):'')
				}
				let get_id= function(o)
				{
					return Object.keys(o)[0]+"_"+(typeof o[Object.keys(o)[0]] === 'object' && !Array.isArray(o[Object.keys(o)[0]]) && o[Object.keys(o)[0]] !== null?get_id(o[Object.keys(o)[0]]):'')
				}
				let name=get_name(update)
				let id=get_id(update)
				id=id.substring(0,id.length-1)
//				console.log(name)
				let old_value=eval('this.data_old'+name)
//				console.log(old_value)
				this.back_list.push({name:name,id:id,value:old_value})
				objectAssignDeep(this.save_list,update)
				this.data_old=structuredClone(this.data)

				this.check_error()
			},
			deep: true
		}
	},
	computed: {
		step: function (){
			return Object.entries(this.data.step)
				.sort(([, a], [, b]) => { if(a.sort<b.sort) return -1; else return 1; })
				.reduce(
					(r, [k, v]) => ({
						...r,
						[k]: v
					}),
					{}
				)
		}
	}
}
</script>
<style>
.table-nobcollapse {
	border-collapse: separate;
}
.card-table .form-select {
	height: fit-content;
}

.card-table th {
	position: sticky;
	top: 0;
	z-index: 9;
}

.card-table thead tr th {
	font-size: 0.86em;
	white-space: normal;
}

.card-table tbody td {
	vertical-align: top;
}

.modal-card .choices__list.dropdown-menu {
	position: fixed;
}

.collapse-selects .form-select-sm .choices__list {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.w-150 .choices__list {

}

.cert-chars {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 20ch;
	display: inline-flex;
}
.mt-negative svg {
	margin-top:-0.25em;
}
.fixed-w1 .choices__list.choices__list--single {
	width: 80px!important;
}

span.text-reset i:hover,
.fe-plus-circle:hover,
.fe-edit:hover,
.fe-copy:hover,
.fe-trash:hover,
.fe-info:hover,
.fe-message-circle:hover {
	color: #2C7BE5;
}
</style>