<template>

	<div class="modal fade" id="modalMembers" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-card card" data-list="{&quot;valueNames&quot;: [&quot;name&quot;]}">
					<div class="card-header">

						<!-- Title -->
						<h4 class="card-header-title" id="exampleModalCenterTitle">
							Сертификаты
						</h4>

						<!-- Close -->
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

					</div>
					<div class="card-body">

						<!-- List group -->
						<ul v-if="certificate_active_id!=0" class="list-group list-group-flush list my-n3">
							<li v-for="(c,k) in data.spares[certificate_active_id].certificate" class="list-group-item">
							<div class="row align-items-center">
								<div class="col-auto">

									<lg-date v-model="c.date" class="form-control-sm" />

								</div>
								<div class="col ms-n2">

									<lg-select style="width: 300px;" v-model="c.type" :options="this.setting.field.spares.setting.field.certificate.setting.field.type.data"/>

								</div>
								<div class="col-auto">
									
									<span class="text-reset me-3" style="cursor: pointer;" title="Загрузить" >
										<label :for="'file-input'+k">
											<i class="fe fe-paperclip"></i>
										</label>
										<input class="d-none" :id="'file-input'+k" type="file" v-on:change="upload_spares_certificate(c.id,k,$event)"/>
									</span>
									<a v-if="c.file.id!=0" @click="download_spares_certificate(c.id,c.file.name,c.file.type)" class="text-reset me-3" style="cursor: pointer;" title="Скачать">
										<i class="fe fe-download"></i>
									</a>
									<a @click="delete_spares_certificate(c.id,k)" class="text-reset me-3" style="cursor: pointer;" title="Удалить" >
										<i class="fe fe-trash-2"></i>
									</a>

								</div>
							</div> <!-- / .row -->
						</li>
							</ul>

						<div class="kanban-add">
							<div class="card card-sm card-flush mb-0">
								<div class="card-body">

									<!-- Link -->
									<div class="text-center">
										<a @click="add_spares_certificate()" class="kanban-add-link" style="cursor: pointer;" >Добавить</a>
									</div>



								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade" id="modal_create_release" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-card card" data-list="{&quot;valueNames&quot;: [&quot;name&quot;]}">
					<div class="card-header">

						<!-- Title -->
						<h4 class="card-header-title" id="exampleModalCenterTitle">
							Создание Release
						</h4>

						<!-- Close -->
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

					</div>
					<div  class="card-body">
						<!-- List group -->
						<ul v-if="data!=null" class="list-group list-group-flush list my-n3">
							<template v-for="(c,k) in data.spares">
								<li v-if="create_release_spares_select.hasOwnProperty(k)" class="list-group-item">
									<div class="row align-items-center">
										<div class="col-auto">

											<lg-checkbox v-model="create_release_spares_select[k]" />
											{{c.pn}}
										</div>
									</div> <!-- / .row -->
								</li>
							</template>
						</ul>


					</div>
					<div class="btn-group mt-2">
						<button type="button" class="btn btn-success" @click="add_release();"><span class="fe fe-save mr-3"></span> Создать</button>

					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade" id="modal_pn_history" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-card card" data-list="{&quot;valueNames&quot;: [&quot;name&quot;]}">
					<div class="card-header">

						<!-- Title -->
						<h4 class="card-header-title" id="exampleModalCenterTitle">
							История P/N {{pn_history_selected}}
						</h4>

						<!-- Close -->
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

					</div>
					<div  class="card-body">
						<ul v-if="pn_history_list!=null" class="list-group list-group-flush list my-n3">
							<template v-for="s in pn_history_list">
								<li class="list-group-item">
									<div class="row align-items-center">
										<div class="d-flex justify-content-between">
											<div style="min-width:100px;">{{s.date}}</div>
											<div>{{s.provider}}</div>
											<div>{{formatter_money[s.currency_provider].format(s.price_provider)}}</div>
										</div>
									</div> <!-- / .row -->
								</li>
							</template>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>

	<lg-menu/>

	<div class="main-content">

		<div class="container-fluid">
			<div v-if="data!=null" class="row justify-content-center">


					<!-- Header -->
					<div class="header mt-md-5">
						<div class="header-body">
							<div class="row align-items-center">
								<div class="col">

									<!-- Pretitle -->
									<h6 class="header-pretitle">
										Рабочий стол
									</h6>

									<!-- Title -->
									<h1 class="header-title">
										Offer № {{data.n}}
									</h1>

								</div>
								
								
								<div class="col">
									<div class="position-relative" style="height:44px;">
										<div v-if="Object.keys(save_list).length>0||data_saving" class="alert alert-light" role="alert">
											Данные сохраняются, не закрывайте вкладку!
										</div>
									</div>
								</div>

								<div class="col">
									<div class="position-relative" style="height:44px;">
										<div v-if="error!=''" class="alert alert-danger" role="alert">
											{{error}}
										</div>
									</div>
								</div>

							</div> <!-- / .row -->

						</div>

					</div>
				






				<div class="col">
						<div class="row gx-3">
							<div class="col-12 col-md-1">

								<!-- First name -->
								<div class="form-group">

									<!-- Label -->
									<label class="form-label">
										Компания
									</label>

									<!-- Input -->
									<lg-select v-model="data.unit" :options="setting.field.unit.data"/>

								</div>

							</div>
							<div class="col-12 col-md-1">
								<div class="form-group">

									<!-- Label -->
									<label class="form-label d-block">
										Статус
									</label>
									<div class="badge d-inline" :class="setting.field.status.data['id_'+data.status].color">
										<strong>{{setting.field.status.data['id_'+data.status].title}}</strong>
									</div>
								</div>
							</div>
							<div class="col-12 col-md-2">

								<!-- Birthday -->
								<div class="form-group">

									<!-- Label -->
									<label class="form-label">
										Дата
									</label>

									<!-- Input -->
									<lg-date class="form-control-sm" v-model="data.date"/>

								</div>

							</div>

							<div class="col-12 col-md-2">

								<!-- First name -->
								<div class="form-group">

									<!-- Label -->
									<label class="form-label">
										Ответственный
									</label>

									<!-- Input -->
									<lg-select v-model="data.responsible" :options="setting.field.responsible.data"/>

								</div>

							</div>
							<div class="col-12 col-md-6 text-md-end d-flex justify-content-md-end">
								<div class="form-group float-start">	
									<!-- Label -->
									<label class="form-label invisible d-none d-md-block">
										Действия
									</label>
								
									<div class="dropdown mb-2 me-2">
										<button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButtonTwo1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :disabled="error!=''">
											Копировать
										</button>
										<div class="dropdown-menu" aria-labelledby="dropdownMenuButtonTwo1" style="">
											<a @click="clipboard_insert2(data.currency,'price_usd')" class="dropdown-item" style="cursor: pointer;">Предложение</a>
											<a @click="clipboard_insert2('USD','price_result_usd')" class="dropdown-item" style="cursor: pointer;">Предложение с доставкой в USD</a>
											<a @click="clipboard_insert2('RUB','price_result_rub')" class="dropdown-item" style="cursor: pointer;">Предложение с доставкой в RUB</a>
										</div>
									</div>

								</div>
								<div class="form-group float-start">
									<!-- Label -->
									<label class="form-label invisible d-none d-md-block">
										Действия
									</label>

									<div class="dropdown mb-2 me-2">
										<button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButtonTwo" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :disabled="error!=''">
											Действия
										</button>
										<div class="dropdown-menu" aria-labelledby="dropdownMenuButtonTwo" style="">
											<a @click="send_usd()" class="dropdown-item" style="cursor: pointer;">Отправить предложение</a>
											<a @click="send_rub()" class="dropdown-item" style="cursor: pointer;">Отправить предложение в рублях</a>
											<a @click="show_add_release()" class="dropdown-item" style="cursor: pointer;">Создать поставку</a>
											<a @click="cancel()" class="dropdown-item" style="cursor: pointer;">Клиент отказался</a>
											<a @click="reset()" class="dropdown-item" style="cursor: pointer;">Сбросить статус</a>
											<a @click="copy_offer()"  class="dropdown-item" style="cursor: pointer;">Копировать offer</a>
										</div>
									</div>

								</div>
								<div class="form-group float-end">
									<label class="form-label invisible d-none d-md-block">
										К списку
									</label>
									<button @click="save_manual()" type="button" class="btn btn-success" :disabled="data.client==0"> Сохранить</button>
								</div>
								<div class="form-group float-end">
									<label class="form-label invisible d-none d-md-block">
										К списку
									</label>
									<button @click="save_and_exit()" type="button" class="btn btn-secondary ms-2 mb-2">К списку</button>

								</div>
							</div>

						</div> <!-- / .row -->
						<div class="row gx-3">
							<div class="col-12 col-md-3">

								<!-- First name -->
								<div class="form-group">

									<!-- Label -->
									<label class="form-label">
										Клиент
									</label>

									<!-- Input -->
									<lg-select v-model="data.client" :options="setting.field.client.data"/>

								</div>

							</div>
							<div class="col-12 col-md-2">

								<!-- First name -->
								<div class="form-group">

									<!-- Label -->
									<label class="form-label">
										Номер запроса
									</label>

									<!-- Input -->
									<lg-input :ref="'ref'" v-model="data.ref" />

								</div>

							</div>
							<div class="col-12 col-md-1">

								<!-- First name -->
								<div class="form-group">

									<!-- Label -->
									<label class="form-label">
										MOV $
									</label>

									<!-- Input -->
									<lg-input :ref="'mov'" v-model="data.mov" :type="'float'" :mask="'9{1,},9{2}'" :width="70" />

								</div>

							</div>
							<div class="col-12 col-md-2">

								<!-- First name -->
								<div class="form-group">

									<!-- Label -->
									<label class="form-label">
										Условия оплаты
									</label>

									<!-- Input -->
									<lg-select v-model="data.terms" :options="setting.field.terms.data"/>

								</div>

							</div>
							<div class="col-12 col-md-2">

								<!-- First name -->
								<div class="form-group">

									<!-- Label -->
									<label class="form-label">
										Валюта
									</label>

									<!-- Input -->
									<lg-select v-model="data.currency" :options="setting.field.currency.data"/>

								</div>

							</div>
							<div class="col-12 col-md-2">

								<!-- First name -->
								<div class="form-group">

									<!-- Label -->
									<label class="form-label">
										НДС
									</label>

									<!-- Input -->
									<lg-select v-model="data.nds" :options="setting.field.nds.data"/>

								</div>

							</div>
						</div>
				</div>

					<div class="header-body">
					<div class="row align-items-center">

						<div class="col order-last order-md-first">

							<!-- Nav -->
							<ul class="nav nav-tabs nav-overflow header-tabs">
								<li class="nav-item">
									<a @click="tab_active='USD'" class="nav-link" :class="{active:tab_active=='USD'}" style="cursor: pointer;">
										Расчёт цены
									</a>
								</li>
								<li class="nav-item">
									<a @click="tab_active='RUB'" class="nav-link" :class="{active:tab_active=='RUB'}" style="cursor: pointer;">
										Расчёт цены с доставкой
									</a>
								</li>
								<li class="nav-item">
									<a @click="tab_active='RELEASE'" class="nav-link" :class="{active:tab_active=='RELEASE'}" style="cursor: pointer;">
										Release
									</a>
								</li>
							</ul>

						</div>
						<div class="col-auto col-12 col-md-5 order-first order-md-last text-md-end">
							<a :href="'https://api-skyparts.litegroup.ru/offer/dowload_usd/'+this.data.id+'/?token='+token" target="_blank" v-if="error==''&&Object.keys(save_list).length==0&&!data_saving"><button type="button" class="btn btn-primary mb-2" >Скачать offer</button></a>
							&nbsp;
							<a :href="'https://api-skyparts.litegroup.ru/offer/dowload_rub/'+this.data.id+'/?token='+token" target="_blank" v-if="error==''&&Object.keys(save_list).length==0&&!data_saving"><button type="button" class="btn btn-primary mb-2">Скачать offer с доставкой</button></a>
							<button @click="backspace()" type="button" class="btn btn-secondary ms-2 mb-2">Отменить изменения</button>
						</div>
					</div>

					</div>

					<div v-if="tab_active=='USD'" class="card px-0">
						<div  class="">
							<table class="table table-sm table-nowrap table-nobcollapse card-table">
								<thead class="">
								<tr>
									<th style="width:10px;">#</th>
									<th style="" class="text-start">P/N</th>
									<th style="width:105px;">Кондиция</th>
									<th style="width:75px;" class="text-center">Отгрузка</th>
									<th style="width: 120px;" class="text-center">Кол-во</th>
									<th style="width: 150px;">Цена поставщика</th>
									<th style="width:105px;" class="text-center">Наценка</th>
									<th style="width:105px;" class="text-center" title="Цена без доставки">Цена <i class="fe fe-help-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Цена без доставки"></i></th>
									<th style="width:105px;">Прибыль</th>
									<th style="width:15px;"></th>
								</tr>
								</thead>
								<tbody class="list px-2">
								<tr v-for="(s,k) in data.spares" :key="k">
									<td>{{s.n}}</td>
									<td style="">
										<div class="d-flex justify-content-start align-items-center flex-wrap">
											<div class="col w-100">
												<div class="d-flex flex-nowrap">
													<offer-edit-pn :ref="'spares_'+k+'_pn'" v-model="s.pn" :placeholder="'Поставщик'" :url="'offer/pn_autocomplete/'+data.id+'/'" />
													<!-- Dropdown -->
													<div class="dropdown d-flex order-2">
														<a href="#" class="text-dark" role="button" data-bs-toggle="dropdown" aria-haspopup="true" data-expanded="false">
															<span style="cursor: pointer;font-size:14px;" class="fe fe-message-circle ms-3 mt-2 d-inline-flex"></span>
														</a>
														<div class="dropdown-menu p-3" style="width:250px;">
															<p><textarea v-model="s.alt" class="form-control form-control-sm" placeholder="Альтернатива"></textarea></p>
														</div>
													</div>
												</div>
												<div class="row">
													<p><textarea v-model="s.discription" class="form-control form-control-sm mt-3" placeholder="Описание" rows="3"></textarea></p>
												</div>
											</div>
											<div class="mt-1 mb-3" style="flex-basis: 100%;">
												<lg-select-autocomplete :ref="'spares_'+k+'_provider'" v-model="s.provider" :placeholder="'Поставщик'" :data="setting.field.spares.setting.field.provider.data" :url="'offer/child/'+data.id+'/spares/element/'+s.id+'/provider/autocomplete/'" />
											</div>
										</div>
									</td>
									<td class="">
										<div class="collapse-selects d-flex justify-content-start align-items-center flex-wrap">
											<div class="col">
												<lg-select v-model="s.condition" :options="setting.field.spares.setting.field.condition.data" :width="100" class=""/>
												
												<div class="mt-2">
													<p v-for="(cert,cert_k) in s.certificate">
														<div class="d-flex">
															<lg-input :ref="'spares_'+k+'_date'" v-model="cert.date" :mask="'9{2}.9{2}.9{4}'" :width="80" class="me-1" />
															<div class="fixed-w1">
																<lg-select style="width: 100px;" v-model="cert.type" :options="this.setting.field.spares.setting.field.certificate.setting.field.type.data" class="form-control-sm" />
															</div>
														</div>
														
														<div class="text-end mt-2">
															<span class="text-reset me-3" title="Загрузить" >
																<label :for="'file-input'+cert_k">
																	<i class="fe fe-paperclip" style="cursor: pointer;--hover-color: green"></i>
																</label>
																<input class="d-none" :id="'file-input'+cert_k" type="file" v-on:change="upload_spares_certificate(k,cert.id,cert_k,$event)"/>
															</span>
															<a v-if="cert.file.id!=0" @click="download_spares_certificate(k,cert.id,cert.file.name,cert.file.type)" class="text-reset me-3" style="cursor: pointer;" title="Скачать">
																<i class="fe fe-download"></i>
															</a>
															<a @click="delete_spares_certificate(k,cert.id,cert_k)" class="text-reset me-3" style="cursor: pointer;" title="Удалить" >
																<i class="fe fe-trash-2"></i>
															</a>
														</div>
													</p>
													<span  @click="add_spares_certificate(k)" style="cursor: pointer;"  class="fe fe-plus-circle ms-2 order-1"></span>
												</div>
											</div>
										
										</div>
									</td>
									<td class="d-flex justify-content-start">
										<div class="d-flex justify-content-start">
										<div class="col text-center">
												<lg-input :ref="'spares_'+k+'_dispatch_date'" v-model="s.dispatch_date" :type="'string'" :width="70"  :placeholder="'Срок'" class="mb-3" />
												<lg-input :ref="'spares_'+k+'_dispatch_place'" v-model="s.dispatch_place" :type="'string'" :width="70" :placeholder="'Место'" />
										</div>
										</div>
									</td>
									<td>
										<div class="d-flex justify-content-start">
											<div class="me-2">
												<lg-input :ref="'spares_'+k+'_order_count'" v-model="s.order_count" :type="'int'" :mask="'9{1,}'" :width="40" class="mb-3" />
												<h4><span onclick="navigator.clipboard.writeText(this.innerText);" style="cursor: pointer;" class="badge bg-primary-soft ms-1 mt-n1" >{{data_calc[k].price_provider_total}}</span></h4>
												<h4><span onclick="navigator.clipboard.writeText(this.innerText);" style="cursor: pointer;" class="badge bg-primary-soft ms-1 mt-n1">{{data_calc[k].price_provider_total_usd}}</span></h4>
											</div>
											<div class="">
												<lg-select v-model="s.uom" :options="setting.field.spares.setting.field.uom.data" :width="70" />
												<lg-input :ref="'spares_'+k+'_moq'" v-model="s.moq" :type="'float'" :width="40" class="mt-1" />
											</div>
										</div>
									</td>
									<td class="">
										<div class="d-flex justify-content-start">
											<div class="col">
												<lg-input :ref="'spares_'+k+'_price_provider'" v-model="s.price_provider" :type="'float'" :mask="'9{1,},9{2}'" :width="70" class="mb-1" />
												<h4><span onclick="navigator.clipboard.writeText(this.innerText);" style="cursor: pointer;" class="badge bg-primary-soft ms-1 mt-1">{{data_calc[k].price_provider_usd}}</span></h4>
											</div>
											<lg-select v-model="s.currency_provider" :options="setting.field.spares.setting.field.currency_provider.data" :width="70"/>
										</div>
									</td>
									<td class="text-center">
										<lg-input :ref="'spares_'+k+'_margin'" v-model="s.margin" :type="'int'" :mask="'9{1,4}'" :width="70" style="margin: 0 auto;" />
										<h4><span onclick="navigator.clipboard.writeText(this.innerText);" style="cursor: pointer;" class="badge bg-primary-soft ms-1 mt-1">{{data_calc[k].price_demo}}</span></h4>
										<h4><span onclick="navigator.clipboard.writeText(this.innerText);" style="cursor: pointer;" class="badge bg-primary-soft ms-1 mt-n1">{{data_calc[k].price_demo_total}}</span></h4>
									</td>
									<td class="d-flex justify-content-end ">
										<div class="col w-100">
											<div class="d-flex flex-nowrap">
												<lg-input :ref="'spares_'+k+'_price_usd'" v-model="s.price_usd" :type="'float'" :mask="'9{1,},9{2}'" :width="70" :disabled="s.price_usd_save==0" class="d-inline-flex" />
												<div v-if="s.price_usd_save==0" class="dropdown d-inline-flex order-2">
													<span @click="price_usd_save(k)" style="cursor: pointer;font-size:14px;" class="fe fe-edit-2 ms-3"></span>
												</div>
											</div>
											<div class="row">
												<h3><span onclick="navigator.clipboard.writeText(this.innerText);" style="cursor: pointer;" class="badge bg-success ms-1 mt-1">{{data_calc[k].price_usd_total}}</span></h3>
											</div>
										</div>
									</td>
									<td class="text-center">
										<div class="col text-center">
											<h3><span onclick="navigator.clipboard.writeText(this.innerText);" style="cursor: pointer;" class="badge bg-success ms-1 mt-n1">{{data_calc[k].profit}}</span></h3>
										</div>
									</td>
									<td class="text-center">
										<div class="col">
											<span  @click="copy_spares(s.id)" style="cursor: pointer;" class="fe fe-copy "></span>
											<span  @click="delete_spares(s.id,k)" style="cursor: pointer;" class="fe fe-trash ms-2"></span>
										</div>
									</td>
								</tr>
						</tbody>
						
							<tfoot>
							<tr>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									<th>{{price_usd_total_sum}}</th>
									<th>{{profit_total_sum}}</th>
									<th></th>

								</tr>
							</tfoot>
							</table>

							<div class="kanban-add">
								<div class="card card-sm card-flush mb-0">
									<div class="card-body">

										<!-- Link -->
										<div class="text-center">
											<a @click="add_spares()" class="kanban-add-link" style="cursor: pointer;">Добавить</a>
										</div>



									</div>
								</div>
							</div>

						</div>
					</div>

				<div v-if="tab_active=='RUB'" class="card px-0">
					<div  class="">
						<table class="table table-sm table-nowrap table-nobcollapse card-table">
							<thead class="">
							<tr>
								<th style="width:10px;">#</th>
								<th>P/N</th>
								<th>Кол-во</th>
								<th>ОГАО</th>
								<th>% пошлины</th>
								<th>Сумма пошлины </th>
								<th>Таможенные процедуры $</th>
								<th>НДС ({{option.nds*100}} %)</th>
								<th>Итоговая цена USD</th>
								<th>Итоговая цена RUB</th>
								<th></th>
							</tr>
							</thead>
							<tbody ref="price_result_rub" class="list px-2">
							<tr v-for="(s,k) in data.spares" :key="k">
								<td>{{s.n}}</td>
								<td style="width:250px;">
									{{s.pn}}
								</td>
								<td style="width:50px;">
									{{s.order_count}}
								</td>
								<td>
									<lg-input :ref="'spares_'+k+'_ogao'" v-model="s.ogao" :type="'string'"  />
								</td>
								<td>
									<lg-input :ref="'spares_'+k+'_tax'" v-model="s.tax" :type="'float'" :mask="'9{1,},9{2}'" :width="70"/>
								</td>
								<td>
									<h4><span onclick="navigator.clipboard.writeText(this.innerText);" style="cursor: pointer;" class="badge bg-primary-soft ms-1 mt-n1">{{data_calc[k].price_tax}}</span></h4>
									<h4><span onclick="navigator.clipboard.writeText(this.innerText);" style="cursor: pointer;" class="badge bg-primary-soft ms-1 mt-n1">{{data_calc[k].price_tax_total}}</span></h4>
								</td>
								<td>
									<lg-input :ref="'spares_'+k+'_customs_price_usd'" v-model="s.customs_price_usd" :type="'float'" :mask="'9{1,},9{2}'" :width="70"/>
								</td>
								<td>
									<h4><span onclick="navigator.clipboard.writeText(this.innerText);" style="cursor: pointer;" class="badge bg-primary-soft ms-1 mt-n1">{{data_calc[k].price_nds}}</span></h4>
									<h4><span onclick="navigator.clipboard.writeText(this.innerText);" style="cursor: pointer;" class="badge bg-primary-soft ms-1 mt-n1">{{data_calc[k].price_nds_total}}</span></h4>
								</td>
								<td>
									<div class="col w-100">
										<div class="row">
											<h3><span onclick="navigator.clipboard.writeText(this.innerText);" style="cursor: pointer;" class="badge bg-success ms-1 mt-n1">{{data_calc[k].price_result_usd_demo}}</span></h3>
										</div>
										<div class="d-flex flex-nowrap">
											<lg-input :ref="'spares_'+k+'_price_result_usd'" v-model="s.price_result_usd" :type="'float'" :mask="'9{1,},9{2}'" :width="120" :disabled="s.price_result_usd_save==0" class="d-inline-flex" />
											<div v-if="s.price_result_usd_save==0" class="dropdown d-inline-flex order-2">
												<span @click="price_result_usd_save(k)" style="cursor: pointer;font-size:14px;" class="fe fe-edit-2 ms-3"></span>
											</div>
										</div>
										<div class="row">
											<h3><span onclick="navigator.clipboard.writeText(this.innerText);" style="cursor: pointer;" class="badge bg-success ms-1 mt-1">{{data_calc[k].price_result_usd_total}}</span></h3>
										</div>
									</div>
								</td>
								<td>
									<div class="col w-100">
										<div class="row">
											<h3><span onclick="navigator.clipboard.writeText(this.innerText);" style="cursor: pointer;" class="badge bg-success ms-1 mt-n1">{{data_calc[k].price_result_rub_demo}}</span></h3>
										</div>
										<div class="d-flex flex-nowrap">
											<lg-input :ref="'spares_'+k+'_price_result_rub'" v-model="s.price_result_rub" :type="'float'" :mask="'9{1,},9{2}'" :width="120" :disabled="s.price_result_rub_save==0" class="d-inline-flex" />
											<div v-if="s.price_result_rub_save==0" class="dropdown d-inline-flex order-2">
												<span @click="price_result_rub_save(k)" style="cursor: pointer;font-size:14px;" class="fe fe-edit-2 ms-3"></span>
											</div>
										</div>
										<div class="row">
											<h3><span onclick="navigator.clipboard.writeText(this.innerText);" style="cursor: pointer;" class="badge bg-success ms-1 mt-1">{{data_calc[k].price_result_rub_total}}</span></h3>
										</div>
									</div>
								</td>
								<td>
									<div class="col">
										<span  @click="copy_spares(s.id)" style="cursor: pointer;" class="fe fe-copy "></span>
										<span  @click="delete_spares(s.id,k)" style="cursor: pointer;" class="fe fe-trash ms-2"></span>
									</div>
								</td>
							</tr>
							</tbody>

							<tfoot>
							<tr>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th>{{price_result_usd_sum}}</th>
								<th>{{price_result_rub_sum}}</th>
								<th></th>
							</tr>
							</tfoot>
						</table>


					</div>
				</div>

				<div v-if="tab_active=='RELEASE'" class="card px-0">
					<div  class="">
						<table class="table table-sm table-nowrap table-nobcollapse card-table">
							<thead class="">
							<tr>
								<th style="width:10px;">#</th>
								<th style="" >Дата</th>
							</tr>
							</thead>
							<tbody class="list px-2">
							<tr v-for="(r,k) in data.release" :key="k">
								<type-link-grid :data="{value:r.n,link:'/release/edit/'+r.id}" />
								<td>{{r.date}}</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>


				</div>
			</div> <!-- / .row -->
		</div>

	<confirm_delete ref="confirm_delete" @del="do_delete_spares" :text="'Вы уверенные что хотите удалить P/N'"/>

</template>
<script>
import axios from 'axios';
import { Modal } from 'bootstrap';
import {  updatedDiff } from 'deep-object-diff';
import objectAssignDeep from 'object-assign-deep'
import config from '@/config.js'
import router from "../../router/router";
import {word_copy_header, word_copy_footer} from "@/pages/offer/word_copy.js";
import OfferEditPN from "@/pages/offer/OfferEditPN";
import confirm_delete from '@/components/UI/ConfirmDelete.vue'

export default {
	components: { 'offer-edit-pn': OfferEditPN, confirm_delete },
	data() {
		return {
			data: null,
			data_old: null,
			data_calc: {},
			calc_empty: {
				price_provider_total:'',
				price_provider_total_usd:'',
				price_provider_usd:'',
				price_demo:'',
				price_demo_total:'',
				price_usd_total:'',
				profit:'',
				price_tax:'',
				price_tax_total:'',
				price_nds:'',
				price_nds_total:'',
				price_result_usd_demo:'',
				price_result_usd_total:'',
				price_result_rub_demo:'',
				price_result_rub_total:'',
			},
			setting: null,
			currency: null,
			option: null,
			formatter_money: null,

			save_list: {},
			data_saving: false,
			current: false,
			back_list: [],

			certificate_modal: null,
			certificate_active_id:0,

			modal_create_release: null,
			create_release_spares_select: {},

			modal_pn_history: null,
			pn_history_selected: '',
			pn_history_list: null,

			spares_n: 0,

			calc_provider_margin: 1.15,

			tab_active: 'USD',

			error: '',

			token: localStorage.getItem('token') || '',

			delete_spares_id: 0,
			delete_spares_k: '',
		}
	},
	mounted ()
	{
		this.load()
	},
	methods: {
		async load()
		{
			axios({url: config.API_LOCATION+'offer/edit/'+this.$route.params.id+'/' })
				.then(resp => {
					let data=resp.data.data[Object.keys(resp.data.data)[0]]
					this.spares_n=1
					for(const s_k in data.spares) {
						this.data_calc[s_k] = structuredClone(this.calc_empty)
						data.spares[s_k]['n']=this.spares_n++
						this.create_release_spares_select[s_k]=1
					}
					this.data=data
					this.data_old=structuredClone(this.data)
					this.setting=resp.data.setting
					this.currency=resp.data.currency
					this.option=resp.data.option

					for(const s_k in data.spares)
						this.calc_all(s_k)

					this.check_error()
				})
				.catch(err => {
					console.log(err)
				})

			this.certificate_modal = new Modal(document.getElementById('modalMembers'))
			this.modal_create_release = new Modal(document.getElementById('modal_create_release'))
			this.modal_pn_history = new Modal(document.getElementById('modal_pn_history'))
			this.formatter_money={
				USD:new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'USD'}),
				EUR:new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'EUR'}),
				RUB:new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB'}),
				AED:new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'AED'}),
			}
			setTimeout(this.save,10000)
		},

		calc_all(s_k)
		{
			this.calc_price_provider_total(s_k)
			this.calc_price_provider_total_usd(s_k)
			this.calc_price_provider_usd(s_k)
			this.calc_price_demo(s_k)
			this.calc_price_demo_total(s_k)
			this.calc_price_usd_total(s_k)
			this.calc_profit(s_k)
			this.calc_price_tax(s_k)
			this.calc_price_tax_total(s_k)
			this.calc_price_nds(s_k)
			this.calc_price_nds_total(s_k)
			this.calc_price_result_usd_demo(s_k)
			this.calc_price_result_usd_total(s_k)
			this.calc_price_result_rub_demo(s_k)
			this.calc_price_result_rub_total(s_k)
		},
		calc_price_provider_total(k)
		{
			let res = this.data.spares[k].order_count * this.data.spares[k].price_provider
			if(isNaN(res))
				res=''
			else
				res=this.formatter_money[this.data.spares[k].currency_provider].format(res)
			this.data_calc[k].price_provider_total=res;
		},
		calc_price_provider_total_usd(k)
		{
			let res=''
			if(this.data.spares[k].currency_provider!='USD'){
				res = this.data.spares[k].order_count * this.data.spares[k].price_provider*this.currency[this.data.spares[k].currency_provider].USD
				if(isNaN(res))
					res=''
				else
					res=this.formatter_money.USD.format(res)
			}
			this.data_calc[k].price_provider_total_usd=res;
		},
		calc_price_provider_usd(k)
		{
			let res=''
			if(this.data.spares[k].currency_provider!='USD'){
				res = this.data.spares[k].price_provider*this.currency[this.data.spares[k].currency_provider].USD
				if(isNaN(res))
					res=''
				else
					res=this.formatter_money.USD.format(res)
			}
			this.data_calc[k].price_provider_usd=res;
		},
		calc_price_demo(k)
		{
			let price=this.data.spares[k].price_provider
			price*=this.currency[this.data.spares[k].currency_provider][this.data.currency]
			let res=price*(this.data.spares[k].margin/100+1)
			if(isNaN(res))
				res=''
			else {
//				res=Math.round(res*100)/100
				res=Math.ceil(res)
				if(this.data.spares[k].price_usd_save==0) {
					this.data.spares[k].price_usd = res;
					if(this.$refs['spares_'+k+'_price_usd']!==undefined&&this.$refs['spares_'+k+'_price_usd'].length==1)
						this.$refs['spares_'+k+'_price_usd'][0].update_value(this.data.spares[k].price_usd);
				}
				res = this.formatter_money[this.data.currency].format(res)
			}
			this.data_calc[k].price_demo=res;
		},
		calc_price_demo_total(k)
		{
			let price=this.data.spares[k].price_provider
			price*=this.currency[this.data.spares[k].currency_provider][this.data.currency]
			let res=price*(this.data.spares[k].margin/100+1)
			if(isNaN(res))
				res=''
			else{
				res=Math.ceil(res)*this.data.spares[k].order_count
				if(isNaN(res))
					res=''
				else
					res=this.formatter_money[this.data.currency].format(res)
			}
			this.data_calc[k].price_demo_total=res;
		},
		calc_price_usd_total(k)
		{
			let res = this.data.spares[k].order_count * this.data.spares[k].price_usd
			if(isNaN(res))
				res=''
			else
				res=this.formatter_money[this.data.currency].format(res)
			this.data_calc[k].price_usd_total=res;
		},
		calc_profit(k)
		{
			let res = this.data.spares[k].order_count * (this.data.spares[k].price_usd*(this.data.currency!='USD'?this.currency[this.data.currency].USD:1) - this.data.spares[k].price_provider*(this.data.spares[k].currency_provider!='USD'? this.currency[this.data.spares[k].currency_provider].USD:1))
			if(isNaN(res))
				res=''
			else
				res=this.formatter_money.USD.format(res)
			this.data_calc[k].profit=res;
		},
		calc_price_tax(k)
		{
			let res = this.data.spares[k].price_provider*this.currency[this.data.spares[k].currency_provider].USD* this.calc_provider_margin * this.data.spares[k].tax * 0.01
			if(isNaN(res))
				res=''
			else
				res=this.formatter_money.USD.format(res)
			this.data_calc[k].price_tax=res;
		},
		calc_price_tax_total(k)
		{
			let res = this.data.spares[k].order_count * this.data.spares[k].price_provider*this.currency[this.data.spares[k].currency_provider].USD* this.calc_provider_margin * this.data.spares[k].tax * 0.01
			if(isNaN(res))
				res=''
			else
				res=this.formatter_money.USD.format(res)
			this.data_calc[k].price_tax_total=res;
		},
		calc_price_nds(k)
		{
			let res = this.data.spares[k].price_provider*this.currency[this.data.spares[k].currency_provider].USD* this.calc_provider_margin * this.option.nds
			if(isNaN(res))
				res=''
			else
				res=this.formatter_money.USD.format(res)
			this.data_calc[k].price_nds=res;
		},
		calc_price_nds_total(k)
		{
			let res = this.data.spares[k].order_count * this.data.spares[k].price_provider*this.currency[this.data.spares[k].currency_provider].USD* this.calc_provider_margin * this.option.nds
			if(isNaN(res))
				res=''
			else
				res=this.formatter_money.USD.format(res)
			this.data_calc[k].price_nds_total=res;
		},
		calc_price_result_usd_demo(k)
		{
			let p= this.data.spares[k].price_usd
			p+= this.data.spares[k].price_provider*this.currency[this.data.spares[k].currency_provider].USD* this.calc_provider_margin * this.data.spares[k].tax * 0.01
			p+=(this.data.spares[k].customs_price_usd/this.data.spares[k].order_count)
			p+= this.data.spares[k].price_provider*this.currency[this.data.spares[k].currency_provider].USD* this.calc_provider_margin * this.option.nds
//			p=Math.round(p*100)/100
			p=Math.ceil(p)
			if(isNaN(p))
				p=''
			else {
				if(this.data.spares[k].price_result_usd_save==0) {
					this.data.spares[k].price_result_usd = p
					if(this.$refs['spares_'+k+'_price_result_usd']!==undefined&&this.$refs['spares_'+k+'_price_result_usd'].length==1)
						this.$refs['spares_'+k+'_price_result_usd'][0].update_value(p);
				}
				p = this.formatter_money.USD.format(p)
			}
			this.data_calc[k].price_result_usd_demo=p;
		},
		calc_price_result_usd_total(k)
		{
			let p=this.data.spares[k].order_count * this.data.spares[k].price_result_usd
			p=Math.round(p*100)/100
			if(isNaN(p))
				p=''
			else {
				p = this.formatter_money.USD.format(p)
			}
			this.data_calc[k].price_result_usd_total=p;
		},
		calc_price_result_rub_demo(k)
		{
			let p= this.data.spares[k].price_usd
			p+= this.data.spares[k].price_provider * this.currency[this.data.spares[k].currency_provider].USD * this.calc_provider_margin * this.data.spares[k].tax * 0.01
			p+=(this.data.spares[k].customs_price_usd/this.data.spares[k].order_count)
			p+= this.data.spares[k].price_provider*this.currency[this.data.spares[k].currency_provider].USD* this.calc_provider_margin * this.option.nds
			p*=this.data.exchange_long_USD_RUB
//			p=Math.round(p*100)/100
			p=Math.ceil(p)
			if(isNaN(p))
				p=''
			else {
				if(this.data.spares[k].price_result_rub_save==0) {
					this.data.spares[k].price_result_rub = p
					if(this.$refs['spares_'+k+'_price_result_rub']!==undefined&&this.$refs['spares_'+k+'_price_result_rub'].length==1)
						this.$refs['spares_'+k+'_price_result_rub'][0].update_value(p);
				}
				//console.log(this.$refs.price_result_rub)
				p = this.formatter_money.RUB.format(p)
			}
			this.data_calc[k].price_result_rub_demo=p;
		},
		calc_price_result_rub_total(k)
		{
			let p=this.data.spares[k].order_count * this.data.spares[k].price_result_rub
			p=Math.round(p*100)/100
			if(isNaN(p))
				p=''
			else {
				p = this.formatter_money.RUB.format(p)
			}
			this.data_calc[k].price_result_rub_total=p;
		},

		async save()
		{
			if(Object.keys(this.save_list).length==0||this.data_saving) {
				setTimeout(this.save, 10000)
				return
			}
			this.data_saving=true
			let save_data={row:{}}
			save_data.row[this.data.__id]=structuredClone(this.save_list)
			this.save_list={}

			axios({url: config.API_LOCATION+'offer/save_list/', data: save_data, method: 'POST' })
				.then(resp => {
					if(resp.data.status=='ok') {
						this.data_saving=false
						this.current=true
						setTimeout(this.save,10000)
					}
				})
				.catch(err => {

				})
		},
		async save_and_exit()
		{
			this.data_saving=true
			let save_data={row:{}}
			save_data.row[this.data.__id]=structuredClone(this.save_list)
			this.save_list={}

			axios({url: config.API_LOCATION+'offer/save_list/', data: save_data, method: 'POST' })
				.then(resp => {
					if(resp.data.status=='ok') {
						this.data_saving=false
						this.current=true
						router.push('/offer')
					}
				})
				.catch(err => {

				})
		},
		async save_manual()
		{
			this.data_saving=true
			let save_data={row:{}}
			save_data.row[this.data.__id]=structuredClone(this.save_list)
			this.save_list={}

			axios({url: config.API_LOCATION+'offer/save_list/', data: save_data, method: 'POST' })
				.then(resp => {
					if(resp.data.status=='ok') {
						this.data_saving=false
						this.current=true
					}
				})
				.catch(err => {

				})
		},
		backspace()
		{
			if(this.back_list.length==0)
				return
//			let t=this.data_old
			eval("this.data_old"+this.back_list[this.back_list.length-1].name+"='"+this.back_list[this.back_list.length-1].value+"'")
//			console.log("this.data_old"+this.back_list[this.back_list.length-1].name+"='"+this.back_list[this.back_list.length-1].value+"'")
//			console.log(t)
//			console.log(this.$refs[this.back_list[this.back_list.length-1].id])
//			console.log(this.$refs[this.back_list[this.back_list.length-1].id].length)
			let update=updatedDiff(this.data, this.data_old)
			this.data=structuredClone(this.data_old)
			objectAssignDeep(this.save_list,update)
			if(this.$refs[this.back_list[this.back_list.length-1].id]!==undefined){
				let el=this.$refs[this.back_list[this.back_list.length-1].id]
				if(Array.isArray(el)&&el.length>0)
					el=el[0]
				el.update_value(this.back_list[this.back_list.length-1].value);
			}
			this.back_list.pop()
//			console.log(this.back_list.slice(1,1))
		},
		save_empty()
		{
			if(this.current)
				return
			let save_data={row:{}}
			save_data.row[this.data.__id]={}
			axios({url: config.API_LOCATION+'offer/save_list/', data: save_data, method: 'POST' })
				.then(resp => {
					if(resp.data.status=='ok') {
						this.current=true
					}
				})
				.catch(err => {

				})
		},
		add_spares()
		{
			axios({url: config.API_LOCATION+'offer/child/'+this.data.__id+'/spares/add/' })
				.then(resp => {
					if(resp.data.status=='ok') {
						let id=Object.keys(resp.data.data)[0]
						this.data_calc[id]=structuredClone(this.calc_empty)
						this.data_old.spares[id]=resp.data.data[id]
						this.data_old.spares[id].n=this.spares_n++
						this.data=structuredClone(this.data_old)
						this.create_release_spares_select[id]=1
						this.save_empty()
						this.calc_all(id)
						this.add_spares_certificate(id)
						this.check_error()
					}
				})
				.catch(err => {

				})
		},
		copy_spares(src_id)
		{
			src_id='id_'+src_id
			axios({url: config.API_LOCATION+'offer/child/'+this.data.__id+'/spares/add/' })
				.then(resp => {
					if(resp.data.status=='ok') {
						let k=Object.keys(resp.data.data)[0]
						this.data_calc[k]=structuredClone(this.calc_empty)
						this.data_old.spares[k]=resp.data.data[k]
						this.data.spares[k]=structuredClone(this.data.spares[src_id])
						this.data.spares[k].id=resp.data.data[k].id
						this.data.spares[k].spares_id=resp.data.data[k].spares_id
						this.data.spares[k].price_usd_save=0
						this.data.spares[k].price_result_usd_save=0
						this.data.spares[k].price_result_rub_save=0
						this.data.spares[k].n=this.spares_n++
						this.data.spares[k].certificate={}
						this.create_release_spares_select[k]=1
						for(let c_k in this.data.spares[src_id].certificate){
							axios({url: config.API_LOCATION+'offer/child/'+this.data.__id+'/spares/child/'+this.data.spares[k].spares_id+'/certificate/add/' })
								.then(resp => {
									if(resp.data.status=='ok') {
										let s_c_id=Object.keys(resp.data.data)[0]
										this.data_old.spares[k].certificate[s_c_id]=resp.data.data[s_c_id]
										this.data.spares[k].certificate[s_c_id]=structuredClone(this.data_old.spares[k].certificate[s_c_id])
										this.data.spares[k].certificate[s_c_id].date=this.data.spares[src_id].certificate[c_k].date
										this.data.spares[k].certificate[s_c_id].type=this.data.spares[src_id].certificate[c_k].type
										this.calc_all(k)
										this.check_error()
									}
								})
								.catch(err => {

								})
						}
						if(Object.keys(this.data.spares[src_id].certificate).length==0){
							this.calc_all(k)
							this.check_error()
						}
					}
				})
				.catch(err => {

				})
		},
		delete_spares(id,k)
		{
			this.delete_spares_id=id
			this.delete_spares_k=k
			this.$refs['confirm_delete'].show()
		},
		do_delete_spares()
		{
			this.certificate_active_id=0;
			axios({url: config.API_LOCATION+'offer/child/'+this.data.__id+'/spares/delete/'+this.delete_spares_id+'/' })
				.then(resp => {
					if(resp.data.status=='ok') {
						delete this.data_old.spares[this.delete_spares_k]
						this.data=structuredClone(this.data_old)
						delete this.create_release_spares_select[this.delete_spares_k]
						this.save_empty()
						this.check_error()
						this.delete_spares_k=''
					}
				})
				.catch(err => {

				})
			this.delete_spares_id=0
		},
		certificate(id)
		{
			this.certificate_active_id=id
			this.certificate_modal.show()
		},
		add_spares_certificate(s_k)
		{
			axios({url: config.API_LOCATION+'offer/child/'+this.data.__id+'/spares/child/'+this.data.spares[s_k].spares_id+'/certificate/add/' })
				.then(resp => {
					if(resp.data.status=='ok') {
						let s_c_id=Object.keys(resp.data.data)[0]
						this.data_old.spares[s_k].certificate[s_c_id]=resp.data.data[s_c_id]
						this.data=structuredClone(this.data_old)
						this.save_empty()
						this.check_error()
					}
				})
				.catch(err => {

				})
		},
		delete_spares_certificate(s_k,s_c_id,s_c_k)
		{
			axios({url: config.API_LOCATION+'offer/child/'+this.data.__id+'/spares/child/'+this.data.spares[s_k].spares_id+'/certificate/delete/'+s_c_id+'/' })
				.then(resp => {
					if(resp.data.status=='ok') {
						delete this.data_old.spares[s_k].certificate[s_c_k]
						this.data=structuredClone(this.data_old)
						this.save_empty()
						this.check_error()
					}
				})
				.catch(err => {

				})
		},
		upload_spares_certificate(s_k,s_c_id,s_c_k,e)
		{
			let formData = new FormData();
			formData.append('file', e.target.files[0]);
			axios({
				url: config.API_LOCATION+'offer/child/'+this.data.__id+'/spares/child/'+this.data.spares[s_k].id+'/certificate/element/'+s_c_id+'/file/upload/',
				data: formData,
				method: 'POST',
				headers: {'Content-Type': 'multipart/form-data'}
			})
				.then(resp => {
					if(resp.data.status=='ok') {
						this.data_old.spares[s_k].certificate[s_c_k].file=resp.data.data.file
						this.data=structuredClone(this.data_old)
						this.save_empty()
					}
				})
				.catch(err => {

				})
		},
		download_spares_certificate(s_k,s_c_id,file_name,file_type)
		{
			axios.get(config.API_LOCATION+'offer/child/'+this.data.__id+'/spares/child/'+this.data.spares[s_k].id+'/certificate/element/'+s_c_id+'/file/download/', { responseType: 'blob' })
				.then(response => {
					const blob = new Blob([response.data], { type: file_type })
					const link = document.createElement('a')
					link.href = URL.createObjectURL(blob)
					link.download = file_name
					link.click()
					URL.revokeObjectURL(link.href)
				}).catch(console.error)
		},
		show_history(pn)
		{
//			console.log(pn)
			if(String(pn).trim().length<5) {
				this.pn_history_selected=''
				this.pn_history_list=null
				return
			}
			axios({url: config.API_LOCATION+'offer/pn_history/'+this.data.id+'/'+pn+'/' })
				.then(resp => {
					if(Object.keys(resp.data.data).length>0) {
						this.pn_history_selected = pn
						this.pn_history_list = resp.data.data
						this.modal_pn_history.show()
					}
				})
				.catch(err => {
					console.log(err)
				})
		},
		price_usd_save(k)
		{
			this.data.spares[k].price_usd_save=1
		},
		price_result_usd_save(k)
		{
			this.data.spares[k].price_result_usd_save=1
		},
		price_result_rub_save(k)
		{
			this.data.spares[k].price_result_rub_save=1
		},
		send_usd()
		{
			this.clipboard_insert2('USD','price_usd');
			axios({url: config.API_LOCATION+'offer/send_usd/'+this.data.__id+'/' })
				.then(resp => {
					if(resp.data.status=='ok') {
						this.save_and_exit()
					}
				})
				.catch(err => {
					console.log(err)
				})
		},
		send_rub()
		{
			this.clipboard_insert2('RUB','price_result_rub');
			axios({url: config.API_LOCATION+'offer/send_rub/'+this.data.__id+'/' })
				.then(resp => {
					if(resp.data.status=='ok') {
						this.save_and_exit()
					}
				})
				.catch(err => {
					console.log(err)
				})
		},
		show_add_release()
		{
			this.modal_create_release.show()
		},
		async add_release()
		{
			this.modal_create_release.hide();
			await this.save_manual()
			axios({url: config.API_LOCATION+'offer/add_release/',data: {offer___id:this.data.__id,spares:this.create_release_spares_select}, method: 'POST' })
				.then(resp => {
					if(resp.data.status=='ok') {
						router.push('/release/edit/'+resp.data.release_id);
					}
				})
				.catch(err => {
					console.log(err)
				})
		},
		cancel()
		{
			axios({url: config.API_LOCATION+'offer/cancel/'+this.data.__id+'/' })
				.then(resp => {
					if(resp.data.status=='ok') {
						this.save_and_exit()
					}
				})
				.catch(err => {
					console.log(err)
				})
		},
		async copy_offer()
		{
			await this.save_manual()
			axios({url: config.API_LOCATION+'offer/copy/'+this.data.id+'/' })
				.then(resp => {
					if(resp.data.status=='ok') {
						router.push('/offer/edit/'+resp.data.id);
					}
				})
				.catch(err => {
					console.log(err)
				})
		},
		async reset()
		{
			await this.save_empty();
			axios({url: config.API_LOCATION+'offer/reset/'+this.data.__id+'/' })
				.then(resp => {
					if(resp.data.status=='ok') {
						this.load();
					}
				})
				.catch(err => {
					console.log(err)
				})
		},
		clipboard_insert2(currency,price)
		{
			let html=word_copy_header();
			let n=0;
				for(let id in this.data.spares){
					n++;
					let certificate='';
					for(let c_id in this.data.spares[id].certificate)
						if(this.data.spares[id].certificate[c_id].type!=0)
							certificate+=this.setting.field.spares.setting.field.certificate.setting.field.type.data['id_'+this.data.spares[id].certificate[c_id].type].title+' '+this.data.spares[id].certificate[c_id].date+", ";
					certificate=certificate.substring(0,certificate.length-2);
					html+="<tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes;height:7.05pt'>\n" +
						"\t\t<td style='border:solid black 1.0pt;border-top:none;mso-border-top-alt:solid #CCCCCC .75pt;\n" +
						"  mso-border-alt:solid black .75pt;mso-border-top-alt:solid #CCCCCC .75pt;\n" +
						"  background:#DBF2F9;padding:1.5pt 2.25pt 1.5pt 2.25pt;height:7.05pt'>" +
						"\t\t\t<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;\n" +
						"  line-height:normal'><b><span style='font-size:10.0pt;font-family:\"Arial\",sans-serif;\n" +
						"  mso-fareast-font-family:\"Times New Roman\";color:black;mso-color-alt:windowtext;\n" +
						"  mso-fareast-language:RU'>"+n+"</span></b><b><span style='font-size:10.0pt;\n" +
						"  font-family:\"Arial\",sans-serif;mso-fareast-font-family:\"Times New Roman\";\n" +
						"  mso-fareast-language:RU'><o:p></o:p></span></b></p>\n" +
						"\t\t</td>\n" +
						"\t\t<td style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;\n" +
						"  border-right:solid black 1.0pt;mso-border-top-alt:solid #CCCCCC .75pt;\n" +
						"  mso-border-left-alt:solid #CCCCCC .75pt;mso-border-top-alt:#CCCCCC;\n" +
						"  mso-border-left-alt:#CCCCCC;mso-border-bottom-alt:black;mso-border-right-alt:\n" +
						"  black;mso-border-style-alt:solid;mso-border-width-alt:.75pt;background:#DBF2F9;\n" +
						"  padding:1.5pt 2.25pt 1.5pt 2.25pt;height:7.05pt'>\n" +
						"\t\t\t<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;\n" +
						"  line-height:normal'><b><span style='font-size:10.0pt;font-family:\"Arial\",sans-serif;\n" +
						"  mso-fareast-font-family:\"Times New Roman\";color:black;mso-color-alt:windowtext;\n" +
						"  mso-fareast-language:RU'>"+this.data.spares[id].pn+"</span></b><b><span style='font-size:\n" +
						"  10.0pt;font-family:\"Arial\",sans-serif;mso-fareast-font-family:\"Times New Roman\";\n" +
						"  mso-fareast-language:RU'><o:p></o:p></span></b></p>\n" +
						"\t\t</td>\n" +
						"\t\t<td style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;\n" +
						"  border-right:solid black 1.0pt;mso-border-top-alt:solid #CCCCCC .75pt;\n" +
						"  mso-border-left-alt:solid #CCCCCC .75pt;mso-border-top-alt:#CCCCCC;\n" +
						"  mso-border-left-alt:#CCCCCC;mso-border-bottom-alt:black;mso-border-right-alt:\n" +
						"  black;mso-border-style-alt:solid;mso-border-width-alt:.75pt;background:#DBF2F9;\n" +
						"  padding:1.5pt 2.25pt 1.5pt 2.25pt;height:7.05pt'>\n" +
						"\t\t\t<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;\n" +
						"  line-height:normal'><b><span style='font-size:10.0pt;font-family:\"Arial\",sans-serif;\n" +
						"  mso-fareast-font-family:\"Times New Roman\";color:black;mso-color-alt:windowtext;\n" +
						"  mso-fareast-language:RU'>"+this.data.spares[id].discription+"</span></b><b><span\n" +
						"\t\t\t\t\tstyle='font-size:10.0pt;font-family:\"Arial\",sans-serif;mso-fareast-font-family:\n" +
						"  \"Times New Roman\";mso-fareast-language:RU'><o:p></o:p></span></b></p>\n" +
						"\t\t</td>\n" +
						"\t\t<td style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;\n" +
						"  border-right:solid black 1.0pt;mso-border-top-alt:solid #CCCCCC .75pt;\n" +
						"  mso-border-left-alt:solid #CCCCCC .75pt;mso-border-top-alt:#CCCCCC;\n" +
						"  mso-border-left-alt:#CCCCCC;mso-border-bottom-alt:black;mso-border-right-alt:\n" +
						"  black;mso-border-style-alt:solid;mso-border-width-alt:.75pt;background:#DBF2F9;\n" +
						"  padding:1.5pt 2.25pt 1.5pt 2.25pt;height:7.05pt'>\n" +
						"\t\t\t<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;\n" +
						"  line-height:normal'><b><span style='font-size:10.0pt;font-family:\"Arial\",sans-serif;\n" +
						"  mso-fareast-font-family:\"Times New Roman\";color:black;mso-color-alt:windowtext;\n" +
						"  mso-fareast-language:RU'>"+this.data.spares[id].order_count+"</span></b><b><span style='font-size:10.0pt;\n" +
						"  font-family:\"Arial\",sans-serif;mso-fareast-font-family:\"Times New Roman\";\n" +
						"  mso-fareast-language:RU'><o:p></o:p></span></b></p>\n" +
						"\t\t</td>\n" +
						"\t\t<td style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;\n" +
						"  border-right:solid black 1.0pt;mso-border-top-alt:solid #CCCCCC .75pt;\n" +
						"  mso-border-left-alt:solid #CCCCCC .75pt;mso-border-top-alt:#CCCCCC;\n" +
						"  mso-border-left-alt:#CCCCCC;mso-border-bottom-alt:black;mso-border-right-alt:\n" +
						"  black;mso-border-style-alt:solid;mso-border-width-alt:.75pt;background:#DBF2F9;\n" +
						"  padding:1.5pt 2.25pt 1.5pt 2.25pt;height:7.05pt'>\n" +
						"\t\t\t<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;\n" +
						"  line-height:normal'><b><span style='font-size:10.0pt;font-family:\"Arial\",sans-serif;\n" +
						"  mso-fareast-font-family:\"Times New Roman\";color:black;mso-color-alt:windowtext;\n" +
						"  mso-fareast-language:RU'>"+this.data.spares[id].moq+"</span></b><b><span style='font-size:10.0pt;\n" +
						"  font-family:\"Arial\",sans-serif;mso-fareast-font-family:\"Times New Roman\";\n" +
						"  mso-fareast-language:RU'><o:p></o:p></span></b></p>\n" +
						"\t\t</td>\n" +
						"\t\t<td style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;\n" +
						"  border-right:solid black 1.0pt;mso-border-top-alt:solid #CCCCCC .75pt;\n" +
						"  mso-border-left-alt:solid #CCCCCC .75pt;mso-border-top-alt:#CCCCCC;\n" +
						"  mso-border-left-alt:#CCCCCC;mso-border-bottom-alt:black;mso-border-right-alt:\n" +
						"  black;mso-border-style-alt:solid;mso-border-width-alt:.75pt;background:#DBF2F9;\n" +
						"  padding:1.5pt 2.25pt 1.5pt 2.25pt;height:7.05pt'>\n" +
						"\t\t\t<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;\n" +
						"  line-height:normal'><b><span style='font-size:10.0pt;font-family:\"Arial\",sans-serif;\n" +
						"  mso-fareast-font-family:\"Times New Roman\";color:black;mso-color-alt:windowtext;\n" +
						"  mso-fareast-language:RU'>"+this.formatter_money[currency].format(this.data.spares[id][price])+"</span></b><b><span style='font-size:10.0pt;\n" +
						"  font-family:\"Arial\",sans-serif;mso-fareast-font-family:\"Times New Roman\";\n" +
						"  mso-fareast-language:RU'><o:p></o:p></span></b></p>\n" +
						"\t\t</td>\n" +
						"\t\t<td style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;\n" +
						"  border-right:solid black 1.0pt;mso-border-top-alt:solid #CCCCCC .75pt;\n" +
						"  mso-border-left-alt:solid #CCCCCC .75pt;mso-border-top-alt:#CCCCCC;\n" +
						"  mso-border-left-alt:#CCCCCC;mso-border-bottom-alt:black;mso-border-right-alt:\n" +
						"  black;mso-border-style-alt:solid;mso-border-width-alt:.75pt;background:#DBF2F9;\n" +
						"  padding:1.5pt 2.25pt 1.5pt 2.25pt;height:7.05pt'>\n" +
						"\t\t\t<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;\n" +
						"  line-height:normal'><b><span style='font-size:10.0pt;font-family:\"Arial\",sans-serif;\n" +
						"  mso-fareast-font-family:\"Times New Roman\";color:black;mso-color-alt:windowtext;\n" +
						"  mso-fareast-language:RU'>"+this.formatter_money[currency].format(this.data.spares[id][price]*this.data.spares[id].order_count)+"</span></b><b><span style='font-size:10.0pt;\n" +
						"  font-family:\"Arial\",sans-serif;mso-fareast-font-family:\"Times New Roman\";\n" +
						"  mso-fareast-language:RU'><o:p></o:p></span></b></p>\n" +
						"\t\t</td>\n" +
						"\t\t<td style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;\n" +
						"  border-right:solid black 1.0pt;mso-border-top-alt:solid #CCCCCC .75pt;\n" +
						"  mso-border-left-alt:solid #CCCCCC .75pt;mso-border-top-alt:#CCCCCC;\n" +
						"  mso-border-left-alt:#CCCCCC;mso-border-bottom-alt:black;mso-border-right-alt:\n" +
						"  black;mso-border-style-alt:solid;mso-border-width-alt:.75pt;background:#DBF2F9;\n" +
						"  padding:1.5pt 2.25pt 1.5pt 2.25pt;height:7.05pt'>\n" +
						"\t\t\t<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;\n" +
						"  line-height:normal'><b><span style='font-size:10.0pt;font-family:\"Arial\",sans-serif;\n" +
						"  mso-fareast-font-family:\"Times New Roman\";color:black;mso-color-alt:windowtext;\n" +
						"  mso-fareast-language:RU'>"+certificate+"</span></b><b><span style='font-size:10.0pt;\n" +
						"  font-family:\"Arial\",sans-serif;mso-fareast-font-family:\"Times New Roman\";\n" +
						"  mso-fareast-language:RU'><o:p></o:p></span></b></p>\n" +
						"\t\t</td>\n" +
						"\t\t<td style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;\n" +
						"  border-right:solid black 1.0pt;mso-border-top-alt:solid #CCCCCC .75pt;\n" +
						"  mso-border-left-alt:solid #CCCCCC .75pt;mso-border-top-alt:#CCCCCC;\n" +
						"  mso-border-left-alt:#CCCCCC;mso-border-bottom-alt:black;mso-border-right-alt:\n" +
						"  black;mso-border-style-alt:solid;mso-border-width-alt:.75pt;background:#DBF2F9;\n" +
						"  padding:1.5pt 2.25pt 1.5pt 2.25pt;height:7.05pt'>\n" +
						"\t\t\t<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;\n" +
						"  line-height:normal'><b><span lang=EN-US style='font-size:10.0pt;font-family:\n" +
						"  \"Arial\",sans-serif;mso-fareast-font-family:\"Times New Roman\";color:black;\n" +
						"  mso-color-alt:windowtext;mso-ansi-language:EN-US;mso-fareast-language:RU'>" +
						this.data.spares[id].dispatch_date+', '+this.data.spares[id].dispatch_place+
						"  </span></b><b><span lang=EN-US style='font-size:10.0pt;\n" +
						"  font-family:\"Arial\",sans-serif;mso-fareast-font-family:\"Times New Roman\";\n" +
						"  mso-ansi-language:EN-US;mso-fareast-language:RU'><o:p></o:p></span></b></p>\n" +
						"\t\t</td>\n" +
						"\t\t<td style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;\n" +
						"  border-right:solid black 1.0pt;mso-border-top-alt:solid #CCCCCC .75pt;\n" +
						"  mso-border-left-alt:solid #CCCCCC .75pt;mso-border-top-alt:#CCCCCC;\n" +
						"  mso-border-left-alt:#CCCCCC;mso-border-bottom-alt:black;mso-border-right-alt:\n" +
						"  black;mso-border-style-alt:solid;mso-border-width-alt:.75pt;background:#DBF2F9;\n" +
						"  padding:1.5pt 2.25pt 1.5pt 2.25pt;height:7.05pt'>\n" +
						"\t\t\t<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;\n" +
						"  line-height:normal'><b><span style='font-size:10.0pt;font-family:\"Arial\",sans-serif;\n" +
						"  mso-fareast-font-family:\"Times New Roman\";color:black;mso-color-alt:windowtext;\n" +
						"  mso-fareast-language:RU'>"+this.data.spares[id].condition+"</span></b><b><span style='font-size:10.0pt;\n" +
						"  font-family:\"Arial\",sans-serif;mso-fareast-font-family:\"Times New Roman\";\n" +
						"  mso-fareast-language:RU'><o:p></o:p></span></b></p>\n" +
						"\t\t</td>\n" +
						"\t</tr>";
				}
			html+=word_copy_footer();

			let type = "text/html";
			let blob = new Blob([html], { type });
			let data = [new ClipboardItem({ [type]: blob })];

			navigator.clipboard.write(data).then(
				function () {
					/* success */
				},
				function () {
					/* failure */
				}
			);
		},
		check_error()
		{
			if(this.data.client==0){
				this.error='Не выбран клиент';
				return;
			}
			if(this.data.unit==0){
				this.error='Не выбрана компания';
				return;
			}
			for(const s_k in this.data.spares){
				if(String(this.data.spares[s_k].pn).trim()==''){
					this.error='В строке '+this.data.spares[s_k].n+' не задан P/N';
					return;
				}
				if(this.data.spares[s_k].provider==0){
					this.error='У P/N '+this.data.spares[s_k].pn+' не выбран поставщик';
					return;
				}
				if(this.data.spares[s_k].price_provider==0||isNaN(this.data.spares[s_k].price_provider)){
					this.error='У P/N '+this.data.spares[s_k].pn+' не заданна цена поставщика';
					return;
				}
				if(this.data.spares[s_k].price_result_usd==0||isNaN(this.data.spares[s_k].price_result_usd)){
					this.error='У P/N '+this.data.spares[s_k].pn+' не заданна итоговая цена USD';
					return;
				}
				if(this.data.spares[s_k].price_result_rub==0||isNaN(this.data.spares[s_k].price_result_rub)){
					this.error='У P/N '+this.data.spares[s_k].pn+' не заданна итоговая цена RUB';
					return;
				}
				for(const s_c_k in this.data.spares[s_k].certificate){
					if(this.data.spares[s_k].certificate[s_c_k].type==0){
						this.error='У P/N '+this.data.spares[s_k].pn+' не заданн тип сертификата';
						return;
					}
				}
			}
			this.error='';
		}
	},
	watch: {
		data: {
			handler(){
//				console.log('up')
//				console.log(this.data_old)
//				console.log(updatedDiff(this.data_old, this.data));
				let update=updatedDiff(this.data_old, this.data)
				if(Object.keys(update).length === 0)
					return
				let get_name= function(o)
				{
					 return "['"+Object.keys(o)[0]+"']"+(typeof o[Object.keys(o)[0]] === 'object' && !Array.isArray(o[Object.keys(o)[0]]) && o[Object.keys(o)[0]] !== null?get_name(o[Object.keys(o)[0]]):'')
				}
				let get_id= function(o)
				{
					return Object.keys(o)[0]+"_"+(typeof o[Object.keys(o)[0]] === 'object' && !Array.isArray(o[Object.keys(o)[0]]) && o[Object.keys(o)[0]] !== null?get_id(o[Object.keys(o)[0]]):'')
				}
				let name=get_name(update)
				let id=get_id(update)
				id=id.substring(0,id.length-1)
//				console.log(name)
				let old_value=eval('this.data_old'+name)
//				console.log(old_value)
				this.back_list.push({name:name,id:id,value:old_value})
				objectAssignDeep(this.save_list,update)
				this.data_old=structuredClone(this.data)

				if(Object.keys(update)[0]=='currency'||Object.keys(update)[0]=='nds'){

					if(Object.keys(update)[0]=='nds')
						this.option.nds=update.nds/100

					for(const s_k in this.data.spares) {
						this.calc_all(s_k)
					}
				}

				if(Object.keys(update)[0]=='spares'){
					let s_k=Object.keys(update.spares)[0]
					let s_var=Object.keys(update.spares[s_k])[0]
					this.calc_all(s_k)
					/*if(s_var=='order_count'){
						this.calc_price_provider_total(s_k)
						this.calc_price_provider_total_usd(s_k)
						this.calc_price_demo_total(s_k)

						this.calc_price_usd_total(s_k)

						this.calc_profit(s_k)
						this.calc_price_tax(s_k)
						this.calc_price_tax_total(s_k)
						this.calc_price_result_usd_demo(s_k)
						this.calc_price_result_usd_total(s_k)
						this.calc_price_result_rub_demo(s_k)
						this.calc_price_result_rub_total(s_k)
					}
					if(s_var=='price_provider'){
						this.calc_price_provider_total(s_k)
						this.calc_price_provider_total_usd(s_k)
						this.calc_price_provider_usd(s_k)
						this.calc_price_demo(s_k)
						this.calc_price_demo_total(s_k)

						this.calc_price_usd_total(s_k)

						this.calc_profit(s_k)
						this.calc_price_tax(s_k)
						this.calc_price_tax_total(s_k)
						this.calc_price_nds(s_k)
						this.calc_price_nds_total(s_k)
						this.calc_price_result_usd_demo(s_k)
						this.calc_price_result_usd_total(s_k)
						this.calc_price_result_rub_demo(s_k)
						this.calc_price_result_rub_total(s_k)
					}
					if(s_var=='currency_provider'){
						this.calc_price_provider_total_usd(s_k)
						this.calc_price_provider_usd(s_k)
						this.calc_price_demo(s_k)
						this.calc_price_demo_total(s_k)

						this.calc_price_usd_total(s_k)

						this.calc_profit(s_k)
						this.calc_price_tax(s_k)
						this.calc_price_tax_total(s_k)
						this.calc_price_nds(s_k)
						this.calc_price_nds_total(s_k)
						this.calc_price_result_usd_demo(s_k)
						this.calc_price_result_usd_total(s_k)
						this.calc_price_result_rub_demo(s_k)
						this.calc_price_result_rub_total(s_k)
					}
					if(s_var=='margin'){
						this.calc_price_demo(s_k)
						this.calc_price_demo_total(s_k)

						this.calc_price_usd_total(s_k)
						this.calc_profit(s_k)
					}
					if(s_var=='price_usd'){
						this.calc_price_usd_total(s_k)
						this.calc_profit(s_k)
						this.calc_price_result_usd_demo(s_k)
						this.calc_price_result_usd_total(s_k)
						this.calc_price_result_rub_demo(s_k)
						this.calc_price_result_rub_total(s_k)
					}
					if(s_var=='tax'){
						this.calc_price_tax(s_k)
						this.calc_price_tax_total(s_k)
						this.calc_price_result_usd_demo(s_k)
						this.calc_price_result_usd_total(s_k)
						this.calc_price_result_rub_demo(s_k)
						this.calc_price_result_rub_total(s_k)
					}
					if(s_var=='customs_price_usd'){
						this.calc_price_result_usd_demo(s_k)
						this.calc_price_result_usd_total(s_k)
						this.calc_price_result_rub_demo(s_k)
						this.calc_price_result_rub_total(s_k)
					}*/
				}
				this.check_error()
			},
			deep: true
		}
	},
	computed: {

		price_usd_total_sum()
		{
			let res=0
			for(const k in this.data.spares){
				let s = this.data.spares[k].order_count * this.data.spares[k].price_usd
				if(!isNaN(s))
					res+=s
			}
			return this.formatter_money[this.data.currency].format(res)
		},
		profit_total_sum()
		{
			let res=0
			for(const k in this.data.spares){
				let s = this.data.spares[k].order_count * (this.data.spares[k].price_usd*(this.data.currency!='USD'?this.currency[this.data.currency].USD:1) - this.data.spares[k].price_provider*(this.data.spares[k].currency_provider!='USD'? this.currency[this.data.spares[k].currency_provider].USD:1))
				if(!isNaN(s))
					res+=s
			}
			return this.formatter_money.USD.format(res)
		},
		price_result_usd_sum()
		{
			let sum=0
			for(const k in this.data.spares){
				let p=this.data.spares[k].order_count * this.data.spares[k].price_result_usd
				p=Math.round(p*100)/100
				if(!isNaN(p))
					sum+=p
			}
			return this.formatter_money.USD.format(sum)
		},
		price_result_rub_sum()
		{
			let sum=0
			for(const k in this.data.spares){
				let p=this.data.spares[k].order_count * this.data.spares[k].price_result_rub
				p=Math.round(p*100)/100
				if(!isNaN(p))
					sum+=p
			}
			return this.formatter_money.RUB.format(sum)
		},
	}
}
</script>
<style>
.table-nobcollapse {
	border-collapse: separate;
}
.card-table .form-select {
	height: fit-content;
}

.card-table th {
	position: sticky;
	top: 0;
	z-index: 9;
}

.card-table thead tr th {
	font-size: 0.86em;
	white-space: normal;
}

.card-table tbody td {
	vertical-align: top;
}

.modal-card .choices__list.dropdown-menu {
	position: fixed;
}

.collapse-selects .form-select-sm .choices__list {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.w-150 .choices__list {
    
}

.cert-chars {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 20ch;
	display: inline-flex;
}
.mt-negative svg {
	margin-top:-0.25em;
}
.fixed-w1 .choices__list.choices__list--single {
		width: 80px!important;
	}
	
span.text-reset i:hover,
.fe-plus-circle:hover,
.fe-edit:hover,
.fe-copy:hover,
.fe-trash:hover,
.fe-info:hover,
.fe-message-circle:hover {
	color: #2C7BE5;
}
</style>