<template>
	<td class="align-top" :title="get_title()">{{get_value()}}</td>
</template>

<script>
export default {
	name: 'type-string-grid',
	props: {
		data: [String, Number],
		max_len: [Number]
	},
	methods: {
		get_value ()
		{
			if(this.max_len==0)
				return this.data
			return String(this.data).substring(0,this.max_len)
		},
		get_title ()
		{
			if(this.max_len==0)
				return ''
			if(this.max_len<String(this.data).length)
				return this.data
			return ''
		}
	}
}
</script>

<style scoped>

</style>
