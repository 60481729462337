<template>
	<td class="align-top">
		<h3><span @click="$router.push(data.link);" style="cursor: pointer;" class="badge bg-primary-soft ms-1 mt-1">{{data.value}}</span></h3>
	</td>
</template>

<script>
export default {
	name: 'type-link-grid',
	props: {
		data: [Object]
	},
}
</script>

<style scoped>

</style>
