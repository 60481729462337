<template>
	<td class="align-top">
		<template v-if="typeof data === 'object'">
			{{data.big}}<br><span class="small">{{data.small}}</span>
		</template>
		<template v-else>{{data}}</template>
	</td>
</template>

<script>
export default {
	name: 'type-date-grid',
	props: {
		data: [String, Object]
	},
}
</script>

<style scoped>

</style>
