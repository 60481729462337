<template>
	<td class="align-top">
		<div class="badge d-inline" :class="{'bg-info':data==1,'bg-secondary':data!=1}">
			<strong>{{(data==1?'Да':'Нет')}}</strong>
		</div>
	</td>
</template>

<script>
export default {
	name: 'type-bool-grid',
	props: {
		data: [Number]
	},
}
</script>

<style scoped>

</style>
