<template>

	<lg-menu/>

	<div class="main-content">
		<div class="container-fluid">
			<div  class="row justify-content-center">
				<div class="header">
					<div class="container-fluid">
						<div class="header-body">
							<div class="row align-items-end">
								<div class="col">
									<h6 class="header-pretitle">Рабочий стол / Отчеты </h6>
									<h1 class="header-title">PN</h1>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="container-fluid">
					<div class="row">
						<lg-type-date-filter v-model="filter.date" :title="'Дата'"/>
						<div class="col-12 col-md-4">
							<div class="form-group">
								<label class="mb-3">&nbsp;</label>
								<div>
									<button @click="exec_filter()" class="btn btn-primary">Искать</button>
								</div>
							</div>
						</div>
					</div>

					<grid :url="url" :can_add="false" :can_edit="false" :can_del="false" ref="ref_grid"></grid>

				</div>
			</div>
		</div>
	</div>

</template>
<script>
import axios from 'axios';
import config from '@/config.js'
import Grid from "@/components/Grid";
export default {
	components: {
		Grid
	},
	data() {
		return {
			url: 'reports/client',

			filter: {},
			default_filter: {
				date: {from:'',to:''}
			},
			filter_key: {},

			loading: true,
		}
	},
	mounted()
	{
		this.filter=structuredClone(this.default_filter);
	},
	methods: {
		async exec_filter ()
		{
			await this.$refs.ref_grid.exec_filter(this.filter);
		},
		load_filter(filter,key)
		{
			this.filter=filter;
			this.filter_key=key;
			this.loading=false;
		},
		async clear_filter(filter)
		{
			this.filter=structuredClone(this.default_filter);

//			console.log(this.$refs.filter_responsible.object.getValue(true));

//			console.log(this.$refs.filter_responsible.object.getValue(true));
			await this.$refs.ref_grid.exec_filter(this.filter);
		},
	}
}
</script>