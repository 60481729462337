<template>
	<td class="align-top" style="white-space: normal; line-height: normal;">
		<type-key-grid :data="data.status" :field="field.status" /><br/>
		<template v-for="i in data.invoice">
			<b>{{i.n_view}}</b>
			<type-key-grid :data="i.status" :field="field.invoice.setting.field.status" />
			<br/>
		</template>
	</td>
</template>

<script>
export default {
	name: 'type-status-grid',
	props: {
		data: [ Object],
		field: [ Object],
	},
	methods: {

	},
}
</script>

<style scoped>

</style>
