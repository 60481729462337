<template>
	<lg-menu/>
	<div class="main-content">
		<div class="container-fluid">
			<div  class="row justify-content-center">
	<!-- HEADER -->
	<div class="header">
		<div class="container-fluid">

			<!-- Body -->
			<div class="header-body">
				<div class="row align-items-end">
					<div class="col">

						<!-- Pretitle -->
						<h6 class="header-pretitle">Рабочий стол</h6>

						<!-- Title -->
						<h1 class="header-title">Offers</h1>

					</div>
					<div v-if="false" class="col-auto">
						<button type="button" class="btn btn-secondary btn-light btn-sm"><span class="fe fe-minimize-2"></span></button>
						<button type="button" class="btn btn-secondary btn-light btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="fe fe-filter"></span> Настройки фильтра</button>

						<div class="dropdown-menu">
							<a class="dropdown-item">Новые обращения</a>
							<a class="dropdown-item">В стадии решения</a>
							<a class="dropdown-item">Закрытые заявки</a>
						</div>
					</div>

				</div> <!-- / .row -->
			</div> <!-- / .header-body -->

		</div>
	</div> <!-- / .header -->

	<!-- CARDS -->
	<div class="container-fluid">
		<div class="row">
			<div class="col-12 col-md-2">
				<div class="input-group input-group-merge input-group-reverse mb-3">
					<input class="form-control" placeholder="Quote" type="text"  v-model="filter.n">
					<div class="input-group-text">
						<i class="las la-stream la-lg" style="padding-bottom: 0;"></i>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-2">
				<div class="input-group input-group-merge input-group-reverse mb-3">
					<input class="form-control" placeholder="Номер запроса" type="text"  v-model="filter.ref">
					<div class="input-group-text">
						<i class="las la-stream la-lg" style="padding-bottom: 0;"></i>
					</div>
				</div>
			</div>

			<div class="col-12 col-md-4">
				<div class="input-group d-block mb-3">
					<lg-select-multi ref="filter_responsible" v-if="!loading" v-model="filter.responsible" :options="filter_key.responsible"  :placeholder="'Ответсвенный'" class="form-control" />
				</div>
			</div>

		</div>
		<div class="row">
			<div class="col-12 col-md-4">
				<div class="input-group input-group-merge input-group-reverse form-group">
					<input class="form-control" placeholder="Клиент" type="text" v-model="filter.client">
					<div class="input-group-text">
						<i class="las la-user-circle la-lg" style="padding-bottom: 0;"></i>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-4">
				<div class="input-group input-group-merge input-group-reverse form-group">
					<input class="form-control" placeholder="P/N" type="text" v-model="filter.spares">
					<div class="input-group-text">
						<i class="las la-headset la-lg" style="padding-bottom: 0;"></i>
					</div>
				</div>
			</div>

		</div>

		<div class="row">
			<lg-type-date-filter v-model="filter.date" :title="'Дата'"/>
			<div v-if="false" class="col-12 col-md-2">
				<div class="form-group">
					<label class="mb-3">Дата изменения</label>
					<div class="input-group input-group-merge">
						<input id="date" type="text" class="form-control" placeholder="ДД.ММ.ГГГГ">
						<div class="input-group-text">
							<span class="fe fe-x mr-2"></span>
							<span class="fe fe-calendar"></span>
						</div>
					</div>
				</div>
			</div>

			<div v-if="false" class="col-12 col-md-2">
				<div class="form-group">
					<label class="mb-3">&nbsp;</label>
					<div class="input-group input-group-merge">
						<input id="date" type="text" class="form-control" placeholder="ДД.ММ.ГГГГ">
						<div class="input-group-text">
							<span class="fe fe-x mr-2"></span>
							<span class="fe fe-calendar"></span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-4">
				<div class="input-group d-block mb-3">
					<label class="mb-3">&nbsp;</label>
					<lg-select-multi ref="filter_unit" v-if="!loading" v-model="filter.unit" :options="filter_key.unit"  :placeholder="'Компания'" class="form-control" />
				</div>
			</div>
			<div class="col-12 col-md-4">
				<div class="form-group">
					<label class="mb-3">&nbsp;</label>
					<div>
						<button @click="exec_filter()" class="btn btn-primary">Искать</button>
						<a @click="clear_filter()" class="btn btn-link text-muted">Очистить фильтр</a>
					</div>
				</div>
			</div>
		</div>

		<div class="row mb-4" style="margin-top: -1rem;">
			<div class="col">
				<div v-if="!loading_status&&!loading" class="filter-item">
					<div @click="clear_status_filter()">Все статусы</div>
					<div @click="set_status_filter('1')" :class="{'filter-selected-item': filter.status.indexOf('1')!==-1}">Новое <span class="badge bg-danger-soft">{{status_count[1].count}}</span> <button v-if="filter.status.indexOf('1')!==-1" type="button" class="button-small-remove">x</button></div>
					<div @click="set_status_filter('2')" :class="{'filter-selected-item': filter.status.indexOf('2')!==-1}">Ожидание ответа клиента USD <span class="badge bg-warning-soft">{{status_count[2].count}}</span> <button v-if="filter.status.indexOf('2')!==-1" type="button" class="button-small-remove">x</button></div>
					<div @click="set_status_filter('3')" :class="{'filter-selected-item': filter.status.indexOf('3')!==-1}">Ожидание ответа клиента RUB <span class="badge bg-warning-soft">{{status_count[3].count}}</span> <button v-if="filter.status.indexOf('3')!==-1" type="button" class="button-small-remove">x</button></div>
					<div @click="set_status_filter('4')" :class="{'filter-selected-item': filter.status.indexOf('4')!==-1}">Создана поставка <span class="badge bg-secondary-soft">{{status_count[4].count}}</span> <button v-if="filter.status.indexOf('4')!==-1" type="button" class="button-small-remove">x</button></div>
					<div @click="set_status_filter('5')" :class="{'filter-selected-item': filter.status.indexOf('5')!==-1}">Отклонен клиентом <span class="badge bg-info-soft">{{status_count[5].count}}</span> <button v-if="filter.status.indexOf('5')!==-1" type="button" class="button-small-remove">x</button></div>
				</div>
			</div>
		</div>

		<grid :url="url" ref="ref_grid"></grid>

	</div>
			</div>
		</div>
	</div>

</template>
<script>
import Grid from "@/components/Grid";
import LgSelectMulti from "../../components/UI/SelectMulti";
import LgTypeDateFilter from "../../components/UI/TypeDateFilter";
import axios from 'axios';
import config from '@/config.js'
export default {
	components: {
		LgTypeDateFilter,
		LgSelectMulti,
		Grid
	},
	data() {
		return {
			url: 'offer',
			filter: {},
			default_filter: {
				unit: [] ,n: '', ref:'', responsible:[], client: '', date: {from:'',to:''}, status: [], spares: '',
			},
			filter_key: {},

			status_count: {},
			loading: true,
			loading_status: true,
		}
	},
	mounted()
	{
		this.filter=structuredClone(this.default_filter);
		this.load_status_count();
	},
	methods: {
		load_status_count()
		{
			this.loading_status=true;
			axios({url: config.API_LOCATION+'offer/get_status_count/' })
				.then(resp => {
					this.status_count=resp.data.status_count;
					for(let i=1;i<6;i++){
						if(!this.status_count.hasOwnProperty(i))
							this.status_count[i]={status:i,count:0};
					}
					this.loading_status=false;
				})
				.catch(err => {
					console.log(err)
				})
		},
		async exec_filter ()
		{
			await this.$refs.ref_grid.exec_filter(this.filter);
			this.load_status_count();
		},
		load_filter(filter,key)
		{
			this.filter=filter;
			this.filter_key=key;
			this.loading=false;
		},
		async clear_filter(filter)
		{
//			this.$refs.filter_unit.object.removeActiveItems();
			this.$refs.filter_responsible.object.removeActiveItems();
			let unit=structuredClone(this.filter.unit);
			this.filter=structuredClone(this.default_filter);
			this.filter.unit=unit;

//			console.log(this.$refs.filter_responsible.object.getValue(true));

//			console.log(this.$refs.filter_responsible.object.getValue(true));
			await this.$refs.ref_grid.exec_filter(this.filter);
			this.load_status_count();
		},
		set_status_filter(status)
		{
			if(this.filter.status.indexOf(status)===-1){
				this.filter.status.push(status);
			}
			else{
				this.filter.status.splice(this.filter.status.indexOf(status),1);
			}
			this.$refs.ref_grid.exec_filter(this.filter);
		},
		clear_status_filter()
		{
			this.filter.status=[];
			this.$refs.ref_grid.exec_filter(this.filter);
		},
	}
}
</script>


<style>


</style>