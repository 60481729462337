<template>
	<td class="align-top">
		<div v-for="name in field.string_several">
			{{getData[name]}}
			<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-1 border-bottom"></div>
		</div>
	</td>
</template>

<script>
export default {
	name: 'type-string-several-grid',
	props: {
		data: [ Object],
		field: [Object],
	},
	computed: {
		getData() {
			let res = {}
			let name=''
			for (const k in this.field.string_several) {
				name=this.field.string_several[k]
				if (typeof this.data[name] === 'object')
					res[name] = this.data[name][Object.keys(this.data[name])[0]].title;
				else
					res[name]=this.data[name]
			}
			return res;
		},
	}
}
</script>

<style scoped>

</style>
