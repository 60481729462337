<template>
	<lg-menu/>
	<div class="main-content">
		<div class="container-fluid">
			<div  class="row justify-content-center">
				<!-- HEADER -->
				<div class="header">
					<div class="container-fluid">

						<!-- Body -->
						<div class="header-body">
							<div class="row align-items-end">
								<div class="col">

									<!-- Pretitle -->
									<h6 class="header-pretitle">Рабочий стол</h6>

									<!-- Title -->
									<h1 class="header-title">Поток</h1>

								</div>
								<div v-if="false" class="col-auto">
									<button type="button" class="btn btn-secondary btn-light btn-sm"><span class="fe fe-minimize-2"></span></button>
									<button type="button" class="btn btn-secondary btn-light btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="fe fe-filter"></span> Настройки фильтра</button>

									<div class="dropdown-menu">
										<a class="dropdown-item">Новые обращения</a>
										<a class="dropdown-item">В стадии решения</a>
										<a class="dropdown-item">Закрытые заявки</a>
									</div>
								</div>

							</div> <!-- / .row -->
						</div> <!-- / .header-body -->

					</div>
				</div> <!-- / .header -->

				<!-- CARDS -->
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-md-2">
							<div class="input-group input-group-merge input-group-reverse mb-3">
								<input class="form-control" placeholder="Поток #" type="text"  v-model="filter.n">
								<div class="input-group-text">
									<i class="las la-stream la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-2">
							<div class="input-group input-group-merge input-group-reverse mb-3">
								<input class="form-control" placeholder="Release #" type="text"  v-model="filter.release_n">
								<div class="input-group-text">
									<i class="las la-stream la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-2">
							<div class="input-group input-group-merge input-group-reverse mb-3">
								<input class="form-control" placeholder="Order #" type="text"  v-model="filter.order_n">
								<div class="input-group-text">
									<i class="las la-stream la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-2">
							<div class="input-group input-group-merge input-group-reverse mb-3">
								<input class="form-control" placeholder="Client order #" type="text"  v-model="filter.client_order_n">
								<div class="input-group-text">
									<i class="las la-stream la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>

					</div>
					<div class="row">
						<div class="col-12 col-md-2">
							<div class="input-group d-block mb-3">
								<lg-select-multi ref="filter_status" v-if="!loading" v-model="filter.status" :options="filter_key.status"  :placeholder="'Оплата'" class="form-control" />
							</div>
						</div>
						<div class="col-12 col-md-2">
							<div class="input-group d-block mb-3">
								<lg-select-multi ref="filter_ok" v-if="!loading" v-model="filter.ok" :options="filter_key.ok"  :placeholder="'Полнота'" class="form-control" />
							</div>
						</div>
						<div class="col-12 col-md-2">
							<div class="input-group input-group-merge input-group-reverse form-group">
								<input class="form-control" placeholder="Посредник" type="text" v-model="filter.intermediary_client">
								<div class="input-group-text">
									<i class="las la-user-circle la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-4">
							<div class="form-group">
								<label class="mb-3">&nbsp;</label>
								<div>
									<button @click="exec_filter()" class="btn btn-primary">Искать</button>
									<a @click="clear_filter()" class="btn btn-link text-muted">Очистить фильтр</a>
								</div>
							</div>
						</div>
					</div>






					<grid :url="url" ref="ref_grid" :do_sort="true"></grid>

				</div>
			</div>
		</div>
	</div>

</template>
<script>
import Grid from "@/components/Grid";
import axios from 'axios';
import config from '@/config.js'
import LgSelectMulti from "@/components/UI/SelectMulti.vue";
export default {
	components: {
		LgSelectMulti,
		Grid
	},
	data() {
		return {
			url: 'payment/finance',
			filter: {},
			default_filter: {
				n: '', release_n:'', order_n: '', client_order_n: '', status: [], ok: [], intermediary_client: ''
			},
			filter_key: {},

			status_count: {},
			loading: true,
			loading_status: true,
		}
	},
	mounted()
	{
		this.filter=structuredClone(this.default_filter);
	},
	methods: {

		async exec_filter ()
		{
			await this.$refs.ref_grid.exec_filter(this.filter);
		},
		load_filter(filter,key)
		{
			console.log(filter);
			console.log(key);
			this.filter=filter;
			this.filter_key=key;
			this.loading=false;
		},
		async clear_filter(filter)
		{
			this.$refs.filter_status.object.removeActiveItems();
			this.$refs.filter_ok.object.removeActiveItems();
			this.filter=structuredClone(this.default_filter);
			await this.$refs.ref_grid.exec_filter(this.filter);
		},
	}
}
</script>


<style>


</style>