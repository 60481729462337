<template>
	<td class="align-top" style="white-space: normal; line-height: normal;">
		<type-key-grid :data="data.urgency" :field="field.urgency" />
		<template v-for="o in data.order">
			<template v-for="s in o.spares">
				{{s.pn}} - {{s.scheduled_delivery_date}}<br/>
				<template v-if="s.scheduled_delivery_date_count>=0">
					Дней до: {{s.scheduled_delivery_date_count}}<br/>
				</template>
			</template>
		</template>
	</td>
</template>

<script>
export default {
	name: 'type-delivery-grid',
	props: {
		data: [ Object],
		field: [ Object],
	},
	methods: {

	},
}
</script>

<style scoped>

</style>
