<template>
	<td>
		<div class="col	">
			<span @click="$router.push(url)" style="cursor: pointer;" class="fe fe-edit"></span>
		</div>
	</td>
</template>

<script>
export default {
	name: 'type-link-pn-grid',
	props: {
		url: [ String],
	},
	methods: {

	},
}
</script>

<style scoped>

</style>
