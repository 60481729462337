<template>
	<select v-model="modelValue" class="form-control mb-3" :style="[width!=0 ? { width: width+'px'}:{}]" :placeholder="placeholder" multiple>
		<option
				v-for="(v,k) in options"
				:key="k"
				:value="v.id"
		>
			{{ v.title }}
		</option>
	</select>
</template>
<script>
	import Choices from 'choices.js';
	import axios from 'axios';
	import config from '@/config.js'
	export default {
		name: 'lg-select-multi',
		props: {
			modelValue: {
				type: [Array]
			},
			options: {
				type: Object,
				default: {}
			},
			width: {
				type: Number,
				default: 0
			},
			param: {
				type: Object,
				default: {}
			},
			placeholder: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				object: null,
				default_param: {
					searchEnabled: false,
					classNames: {
						containerInner: "form-select form-select-sm",
						input: 'form-control',
						inputCloned: 'form-control-sm',
						listDropdown: 'dropdown-menu',
						itemChoice: 'dropdown-item',
						activeState: 'show',
						selectedState: 'active',
					},
					shouldSort: false,
					allowHTML: true,
					searchFields: ['label'],
					removeItemButton: true,
//				searchChoices: false,
					callbackOnCreateTemplates: function (template) {
						return {
							choice: (classNames, data) => {
								const classes = `${classNames.item} ${classNames.itemChoice} ${
									data.disabled ? classNames.itemDisabled : classNames.itemSelectable
								}`;
								const disabled = data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable';
								const role = data.groupId > 0 ? 'role="treeitem"' : 'role="option"';
								const selectText = this.config.itemSelectText;

								const label =
									data.customProperties && data.customProperties.avatarSrc
										? `
            <div class="avatar avatar-xs me-3">
              <img class="avatar-img rounded-circle" src="${data.customProperties.avatarSrc}" alt="${data.label}" >
            </div> ${data.label}
          `
										: data.label;

								return template(`
            <div class="${classes}" data-select-text="${selectText}" data-choice ${disabled} data-id="${data.id}" data-value="${data.value}" ${role}>
              ${label}
            </div>
          `);
							},
						};
					},
				}
			}
		},
		methods: {
		},
		mounted ()
		{
			if(this.placeholder!='') {
				this.param['placeholder'] = true;
				this.param['placeholderValue'] = this.placeholder;
			}
			const options = {
				...this.default_param,
				...this.param,
			};
			this.object=new Choices(this.$el,options)
			let obj=this;
			this.object.passedElement.element.addEventListener(
				'addItem',
				function(event) {
					obj.$emit('update:modelValue', obj.object.getValue(true));
				},
				false,
			);
			this.object.passedElement.element.addEventListener(
				'removeItem',
				function(event) {
					obj.$emit('update:modelValue', obj.object.getValue(true));
				},
				false,
			);
		}
	}
</script>


<style>
	.choices__list div {
		padding-right: 0.375rem !important;
		padding-left: 0.375rem !important;
		line-height: 1.75;
		font-size: 0.8125rem;
	}
	.input-group .choices__list.choices__list--multiple + .form-control {
		padding: 0.5rem 0.75rem;
		font-size: 0.9375rem;
	}
	.input-group.input-group-merge.input-group-reverse .choices__list.choices__list--multiple + .form-control {
		padding: 0.5rem 0.75rem;
		font-size: 0.9375rem;
		min-width: 12ch!important;
	}
	
.fixed-w1 .choices__list.choices__list--single .choices__item.choices__item--selectable {
		min-width: 8ch!important;
	}
</style>