import {createRouter, createWebHistory} from "vue-router";
import store from '@/store';
import Index from "@/pages/Index";
import Login from "@/pages/login/Login";
import LoginCode from "@/pages/login/Code";
import Registration from "@/pages/login/Registration";
import ChangePassword from "@/pages/ChangePassword";
import OfferGrid from '@/pages/offer/OfferGrid.vue'
import OfferEdit from '@/pages/offer/OfferEdit.vue'
import ReleaseGrid from '@/pages/release/ReleaseGrid.vue'
import ReleaseGridPN from '@/pages/release/ReleaseGridPN.vue'
import ReleaseEdit from '@/pages/release/ReleaseEdit.vue'
import Exchange from '@/pages/directories/Exchange.vue'
import ClientGrid from '@/pages/directories/ClientGrid.vue'
import ClientEdit from '@/pages/directories/ClientEdit.vue'
import ProviderGrid from '@/pages/directories/ProviderGrid.vue'
import ProviderEdit from '@/pages/directories/ProviderEdit.vue'
import ForwarderGrid from '@/pages/directories/ForwarderGrid.vue'
import ForwarderEdit from '@/pages/directories/ForwarderEdit.vue'
import ShipToGrid from '@/pages/directories/ShipToGrid.vue'
import ShipToEdit from '@/pages/directories/ShipToEdit.vue'
import BankGrid from '@/pages/directories/BankGrid.vue'
import BankEdit from '@/pages/directories/BankEdit.vue'
import IntermediaryGrid from '@/pages/directories/IntermediaryGrid.vue'
import IntermediaryEdit from '@/pages/directories/IntermediaryEdit.vue'
import UnitGrid from '@/pages/directories/UnitGrid.vue'
import UnitEdit from '@/pages/directories/UnitEdit.vue'
import LocationGrid from '@/pages/directories/LocationGrid.vue'
import LocationEdit from '@/pages/directories/LocationEdit.vue'
import UserGrid from '@/pages/settings/users/UserGrid.vue'
import UserEdit from '@/pages/settings/users/UserEdit.vue'
import PaymentProvider from '@/pages/payment/PaymentProvider.vue'
import PaymentInvoice from '@/pages/payment/Invoice.vue'
import PaymentClientGrid from '@/pages/payment/client/PaymentClientGrid.vue'
import PaymentFinanceGrid from '@/pages/payment/finance/FinanceGrid.vue'
import PaymentFinanceEdit from '@/pages/payment/finance/FinanceEdit.vue'
import LogisticsSpares from '@/pages/logistics/LogisticsSpares.vue'
import LogisticsOrder from '@/pages/logistics/LogisticsOrder.vue'
import LogisticsInvoice from '@/pages/logistics/LogisticsInvoice.vue'
import PN from '@/pages/reports/PN.vue'
import Staff from '@/pages/reports/Staff.vue'
import Release from '@/pages/reports/Release.vue'
import Spares from '@/pages/reports/Spares.vue'
import Period from '@/pages/reports/Period.vue'
import PNsum from '@/pages/reports/PNsum.vue'
import Price from '@/pages/reports/Price.vue'
import OfferOnly from '@/pages/reports/OfferOnly.vue'
import Client from '@/pages/reports/Client.vue'


const routes = [
	{
		path: '/',
		component: Index,
		name: {l1: 'index'},
	},
	{
		path: '/login',
		component: Login,
		meta: {
			requiresAuth: false
		}
	},
	{
		path: '/login/code',
		component: LoginCode,
		meta: {
			requiresAuth: false
		}
	},
	{
		path: '/login/registration',
		component: Registration,
		meta: {
			requiresAuth: false
		}
	},
	{
		path: '/change-password',
		component: ChangePassword
	},
	{
		path: '/offer',
		component: OfferGrid,
		name: {l1: 'offer'},
	},
	{
		path: '/offer/edit/:id',
		component: OfferEdit,
		name: {l1: 'offer'},
	},
	{
		path: '/release',
		component: ReleaseGrid,
		name: {l1: 'release'},
	},
	{
		path: '/release/index_pn',
		component: ReleaseGridPN,
		name: {l1: 'release',l2:'index_pn'},
	},
	{
		path: '/release/edit/:id',
		component: ReleaseEdit,
		name: {l1: 'release'},
	},
	{
		path: '/release/index_pn/edit/:id',
		component: ReleaseEdit,
		name: {l1: 'release'},
	},
	{
		path: '/logistics/spares',
		component: LogisticsSpares,
		name: {l1: 'logistics', l2:'spares'},
	},
	{
		path: '/logistics/order',
		component: LogisticsOrder,
		name: {l1: 'logistics', l2:'order'},
	},
	{
		path: '/logistics/invoice',
		component: LogisticsInvoice,
		name: {l1: 'logistics', l2:'invoice'},
	},
	{
		path: '/payment/provider',
		component: PaymentProvider,
		name: {l1: 'payment', l2:'provider'},
	},
	{
		path: '/payment/invoice',
		component: PaymentInvoice,
		name: {l1: 'payment', l2:'invoice'},
	},
	{
		path: '/payment/client',
		component: PaymentClientGrid,
		name: {l1: 'payment', l2:'client'},
	},
	{
		path: '/payment/finance',
		component: PaymentFinanceGrid,
		name: {l1: 'payment', l2:'finance'},
	},
	{
		path: '/payment/finance/edit/:id',
		component: PaymentFinanceEdit,
		name: {l1: 'payment', l2:'finance'},
	},
	{
		path: '/directories/exchange',
		component: Exchange,
		name: {l1: 'directories', l2:'exchange'},
	},
	{
		path: '/directories/client',
		component: ClientGrid,
		name: {l1: 'directories', l2:'client'},
	},
	{
		path: '/directories/client/edit/:id',
		component: ClientEdit,
		name: {l1: 'directories', l2:'client'},
	},
	{
		path: '/directories/provider',
		component: ProviderGrid,
		name: {l1: 'directories', l2:'provider'},
	},
	{
		path: '/directories/provider/edit/:id',
		component: ProviderEdit,
		name: {l1: 'directories', l2:'provider'},
	},
	{
		path: '/directories/forwarder',
		component: ForwarderGrid,
		name: {l1: 'directories', l2:'forwarder'},
	},
	{
		path: '/directories/forwarder/edit/:id',
		component: ForwarderEdit,
		name: {l1: 'directories', l2:'forwarder'},
	},
	{
		path: '/directories/ship_to',
		component:ShipToGrid,
		name: {l1: 'directories', l2:'ship_to'},
	},
	{
		path: '/directories/ship_to/edit/:id',
		component: ShipToEdit,
		name: {l1: 'directories', l2:'ship_to'},
	},
	{
		path: '/directories/bank',
		component:BankGrid,
		name: {l1: 'directories', l2:'bank'},
	},
	{
		path: '/directories/bank/edit/:id',
		component: BankEdit,
		name: {l1: 'directories', l2:'bank'},
	},
	{
		path: '/directories/intermediary',
		component:IntermediaryGrid,
		name: {l1: 'directories', l2:'intermediary'},
	},
	{
		path: '/directories/intermediary/edit/:id',
		component: IntermediaryEdit,
		name: {l1: 'directories', l2:'intermediary'},
	},
	{
		path: '/directories/unit',
		component:UnitGrid,
		name: {l1: 'directories', l2:'unit'},
	},
	{
		path: '/directories/unit/edit/:id',
		component: UnitEdit,
		name: {l1: 'directories', l2:'unit'},
	},
	{
		path: '/directories/location',
		component:LocationGrid,
		name: {l1: 'directories', l2:'location'},
	},
	{
		path: '/directories/location/edit/:id',
		component: LocationEdit,
		name: {l1: 'directories', l2:'location'},
	},
	{
		path: '/reports/pn',
		component:PN,
		name: {l1: 'reports', l2:'pn'},
	},
	{
		path: '/reports/staff',
		component:Staff,
		name: {l1: 'reports', l2:'staff'},
	},
	{
		path: '/reports/release',
		component:Release,
		name: {l1: 'reports', l2:'release'},
	},
	{
		path: '/reports/spares',
		component:Spares,
		name: {l1: 'reports', l2:'spares'},
	},
	{
		path: '/reports/period',
		component:Period,
		name: {l1: 'reports', l2:'period'},
	},
	{
		path: '/reports/pn_sum',
		component:PNsum,
		name: {l1: 'reports', l2:'pn_sum'},
	},
	{
		path: '/reports/price',
		component:Price,
		name: {l1: 'reports', l2:'price'},
	},
	{
		path: '/reports/offer_only',
		component:OfferOnly,
		name: {l1: 'reports', l2:'offer_only'},
	},
	{
		path: '/reports/client',
		component:Client,
		name: {l1: 'reports', l2:'client'},
	},
	{
		path: '/settings/users',
		component: UserGrid,
		name: {l1: 'settings', l2:'users'},
	},
	{
		path: '/settings/users/edit/:id',
		component: UserEdit,
		name: {l1: 'settings', l2:'users'},
	},
]

const router = createRouter({
	routes,
	history: createWebHistory(process.env.BASE_URL)
})

router.beforeEach((to, from, next) => {
	if(to.matched[0].meta.requiresAuth!=false) {
		if (store.getters.isLoggedIn) {
			next()
			return
		}
		next('/login')
	} else {
		if (store.getters.isLoggedIn) {
			next('/')
			return
		}
		next()
	}
})

export default router;



