<template>
	<td class="align-top">
		<a v-if="data.id!=0" :href="'https://api-skyparts.litegroup.ru/payment/provider/element/'+id+'/proform/download/?token='+token" target="_blank" class="dropdown-item">Скачать</a>
	</td>
</template>

<script>
export default {
	name: 'type-file-grid',
	props: {
		data: [Object],
		id: [String],
	},
	data() {
		return {
			token: localStorage.getItem('token') || '',
		}
	}
}
</script>

<style scoped>

</style>
