<template>
	<div class="bg-gray-50 min-w-screen min-h-screen flex justify-center items-center">
		<div class="max-w-xs relative space-y-3">
			<input
				type="text"
				@change="unselect()"
				@keyup="do_search()"
				v-model="search_term"
				:placeholder="placeholder"
				class="p-2 mb-0.5 w-full border border-gray-300 rounded w-100"
			>
			<div style="position:absolute;">
				<ul
					v-if="Object.keys(search_list).length"
					class="autocui w-full rounded bg-white border border-gray-300"
				>

					<li
						v-for="v in search_list"
						:key="v.title"
						@click="select(v.id,v.title)"
						class="hover:bg-gray-100 p-1"
					>
						{{ v.title }}
					</li>
				</ul>
			</div>

		</div>
	</div>
</template>
<script>
import axios from 'axios';
import config from '@/config.js'
export default {
	name: 'lg-select-autocomplete',

	data() {
		return {
			search_term : '',
			text_value: '',
			search_list: {}
		}
	},

	props: {
		modelValue: {
			type: [ Number],
			default: 0
		},
		data: {
			type: [ Object ],
			default: {}
		},
		url: {
			type: [ String ],
			default: ''
		},
		placeholder: {
			type: [ String],
			default: ''
		}
	},
	mounted ()
	{
		this.text_value=this.data['id_'+this.modelValue].title
		this.search_term=this.text_value
	},
	methods: {
		select(id,text)
		{
//			console.log('se')
			this.text_value = text
			this.search_term = text
			this.search_list={}
			this.$emit('update:modelValue', id);
		},
		unselect()
		{
//			console.log('un')
			setTimeout(this.do_unselect,250)
		},
		do_unselect()
		{
//			console.log('do_un')
			if(this.search_term.trim()===''){
				this.text_value=''
				this.$emit('update:modelValue', 0);
			}
			else
				this.search_term=this.text_value
			this.search_list={}
		},
		do_search()
		{
			if (this.search_term.trim() === ''){
				this.search_list={}
				this.$emit('update:modelValue', 0);
				return
			}
			if(this.search_term===this.text_value){
				this.search_list={}
				return
			}
			axios({url: config.API_LOCATION+this.url+'?term='+this.search_term+'&limit=10' })
				.then(resp => {
					this.search_list=resp.data.data
				})
				.catch(err => {
					console.log(err)
				})
		},
		update_value(id)
		{

			this.text_value=this.data['id_'+id].title
			this.search_term=this.text_value
		}
	},
	watch: {

	}
}
</script>

<style>
.cursor-pointer {
	cursor: pointer;
}
.autocui {
	list-style: none;
	padding-left: 0;
	padding-right: 0;
	position: absolute;
	z-index: 20;
	min-width: 13.5em;
	top: 4px;
}
.autocui li {
	cursor: pointer;
	padding: .5em 1em;
}
.autocui li:hover,
.autocui li:focus {
	background-color: #6CB4EE;
	color: #fff;
}
</style>