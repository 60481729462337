<template>
	<td class="align-top" style="white-space: normal; line-height: normal;">
		{{data.user_change}}<br />
		<span class="small text-muted">{{getDate()}}</span>
	</td>
</template>

<script>
export default {
	name: 'type-log-grid',
	props: {
		data: [ Object],
	},
	methods: {
		getDate ()
		{
			let date = new Date(this.data.date_change * 1000);
			return date.toLocaleDateString("ru-RU")+' '+date.toLocaleTimeString('ru-RU');
		}
	},
}
</script>

<style scoped>

</style>
