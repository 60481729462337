<template>
	<lg-menu/>

	<div class="main-content">

		<div class="container-fluid">
			<div  class="row justify-content-center">
				<!-- HEADER -->
				<div class="header">
					<div class="container-fluid">

						<!-- Body -->
						<div class="header-body">
							<div class="row align-items-end">
								<div class="col">

									<!-- Pretitle -->
									<h6 class="header-pretitle">Рабочий стол / справочники</h6>

									<!-- Title -->
									<h1 class="header-title">Транстпортные компании</h1>

								</div>


							</div> <!-- / .row -->
						</div> <!-- / .header-body -->

					</div>
				</div> <!-- / .header -->

				<!-- CARDS -->
				<div class="container-fluid">


					<grid :url="url"></grid>

				</div>
			</div>
		</div>
	</div>

</template>
<script>
import Grid from "@/components/Grid";
export default {
	components: {
		Grid
	},
	data() {
		return {
			url: 'directories/forwarder'
		}
	},
	methods: {
		load_filter(filter,key)
		{

		}
	}
}
</script>