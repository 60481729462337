<template>
	<div class="col-12 col-md-2">
		<div class="form-group">
			<label class="mb-3">{{title}}</label>
			<div class="input-group input-group-merge">
				<input v-model="modelValue.from" ref="from" type="text" class="form-control" placeholder="ДД.ММ.ГГГГ">
				<div class="input-group-text">
					<span class="fe fe-x mr-2" @click="obj_from.clear()"></span>
					<span class="fe fe-calendar" @click="obj_from.open()"></span>
				</div>
			</div>
		</div>
	</div>

	<div class="col-12 col-md-2">
		<div class="form-group">
			<label class="mb-3">&nbsp;</label>
			<div class="input-group input-group-merge">
				<input v-model="modelValue.to" ref="to" type="text" class="form-control" placeholder="ДД.ММ.ГГГГ">
				<div class="input-group-text">
					<span class="fe fe-x mr-2" @click="obj_to.clear()"></span>
					<span class="fe fe-calendar" @click="obj_to.open()"></span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import flatpickr from 'flatpickr';
	import { Russian } from "flatpickr/dist/l10n/ru.js";
	export default {
		name: 'lg-type-date-filter',
		props: {
			modelValue: {
				type: Object,
				default:  {from:'',to:''}
			},
			title: {
				type: String,
				default: ''
			},
		},
		data(){
			return {
				obj_from:null,
				obj_to:null,
			}
		},
		mounted ()
		{
			this.obj_from=flatpickr(this.$refs.from, {
				"locale": Russian,
				dateFormat:'d.m.Y'
			});
			this.obj_to=flatpickr(this.$refs.to, {
				"locale": Russian,
				dateFormat:'d.m.Y'
			});
		},
		methods: {
			changeOption(event) {
				this.$emit('update:modelValue', event.target.value);
			}
		},
	}
</script>