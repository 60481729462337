<template>
	<lg-menu/>

	<div class="main-content">

		<div class="container-fluid">
			<div  class="row justify-content-center">
				<!-- HEADER -->
				<div class="header">
					<div class="container-fluid">

						<!-- Body -->
						<div class="header-body">
							<div class="row align-items-end">
								<div class="col">

									<!-- Pretitle -->
									<h6 class="header-pretitle">Рабочий стол / Оплата </h6>

									<!-- Title -->
									<h1 class="header-title">Расчет с поставщиками</h1>

								</div>


							</div> <!-- / .row -->
						</div> <!-- / .header-body -->

					</div>
				</div> <!-- / .header -->

				<!-- CARDS -->
				<div class="container-fluid">

					<div class="row">
						<div class="col-12 col-md-2">
							<div class="input-group input-group-merge input-group-reverse mb-3">
								<input class="form-control" placeholder="Qrder" type="text"  v-model="filter.order_n">
								<div class="input-group-text">
									<i class="las la-stream la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>

						<div class="col-12 col-md-4">
							<div class="input-group d-block mb-3">
								Оплачен:
								<lg-select v-if="!loading" v-model="filter.set_payed" :options="filter_key.set_payed"  :placeholder="'Оплачен'" :width="150" />

							</div>
						</div>


					</div>
					<div class="row">
						<div class="col-12 col-md-4">
							<div class="input-group input-group-merge input-group-reverse form-group">
								<input class="form-control" placeholder="Поставщик" type="text" v-model="filter.provider">
								<div class="input-group-text">
									<i class="las la-user-circle la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>


					</div>

					<div class="row">
						<lg-type-date-filter v-model="filter.date" :title="'Дата'"/>
						<div v-if="false" class="col-12 col-md-2">
							<div class="form-group">
								<label class="mb-3">Дата изменения</label>
								<div class="input-group input-group-merge">
									<input id="date" type="text" class="form-control" placeholder="ДД.ММ.ГГГГ">
									<div class="input-group-text">
										<span class="fe fe-x mr-2"></span>
										<span class="fe fe-calendar"></span>
									</div>
								</div>
							</div>
						</div>

						<div v-if="false" class="col-12 col-md-2">
							<div class="form-group">
								<label class="mb-3">&nbsp;</label>
								<div class="input-group input-group-merge">
									<input id="date" type="text" class="form-control" placeholder="ДД.ММ.ГГГГ">
									<div class="input-group-text">
										<span class="fe fe-x mr-2"></span>
										<span class="fe fe-calendar"></span>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-4">
							<div class="form-group">
								<label class="mb-3">&nbsp;</label>
								<div>
									<button @click="exec_filter()" class="btn btn-primary">Искать</button>
									<a @click="clear_filter()" class="btn btn-link text-muted">Очистить фильтр</a>
								</div>
							</div>
						</div>
					</div>



					<grid :url="url" :can_add="false" :can_edit="false" :can_del="false" ref="ref_grid"></grid>

				</div>
			</div>
		</div>
	</div>

</template>
<script>
import axios from 'axios';
import config from '@/config.js'
import Grid from "@/components/Grid";
export default {
	components: {
		Grid
	},
	data() {
		return {
			url: 'payment/provider',

			filter: {},
			default_filter: {
				order_n : '', provider: '', date: {from:'',to:''}, set_payed: [],
			},
			filter_key: {},

			status_count: {},
			loading: true,
			loading_status: true,
		}
	},
	mounted()
	{
		this.filter=structuredClone(this.default_filter);
		this.load_status_count();
	},
	methods: {
		load_status_count()
		{
			this.loading_status=true;
			axios({url: config.API_LOCATION+'payment/provider/get_status_count/' })
				.then(resp => {
					this.status_count=resp.data.status_count;
					for(let i=1;i<2;i++){
						if(!this.status_count.hasOwnProperty(i))
							this.status_count[i]={status:i,count:0};
					}
					this.loading_status=false;
				})
				.catch(err => {
					console.log(err)
				})
		},
		async exec_filter ()
		{
			await this.$refs.ref_grid.exec_filter(this.filter);
			this.load_status_count();
		},
		load_filter(filter,key)
		{
			this.filter=filter;
			this.filter_key=key;
			this.loading=false;
		},
		async clear_filter(filter)
		{
//			this.$refs.filter_set_payed.object.removeActiveItems();
			this.filter=structuredClone(this.default_filter);

//			console.log(this.$refs.filter_responsible.object.getValue(true));

//			console.log(this.$refs.filter_responsible.object.getValue(true));
			await this.$refs.ref_grid.exec_filter(this.filter);
			this.load_status_count();
		},
		set_status_filter(status)
		{

			this.$refs.ref_grid.exec_filter(this.filter);
		},
	}
}
</script>