<template>
	<lg-menu/>

	<div class="main-content">
		<div class="container-fluid">
			<div  class="row justify-content-center">
				<div class="header">
					<div class="container-fluid">
						<div class="header-body">
							<div class="row align-items-end">
								<div class="col">
									<h6 class="header-pretitle">Рабочий стол / Оплата </h6>
									<h1 class="header-title">Invoice</h1>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="container-fluid">

					<div class="row">
						<div class="col-12 col-md-2">
							<div class="input-group input-group-merge input-group-reverse mb-3">
								<input class="form-control" placeholder="Invoice" type="text"  v-model="filter.n_view">
								<div class="input-group-text">
									<i class="las la-stream la-lg" style="padding-bottom: 0;"></i>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-2">
							<div class="input-group d-block mb-3">
								Оплачен:
								<lg-select v-if="!loading" v-model="filter.payed" :options="filter_key.payed"  :placeholder="'Оплачен'" :width="150" />
							</div>
						</div>
						<div class="col-12 col-md-4">
							<div class="input-group d-block mb-3">
								<lg-select-multi ref="filter_unit" v-if="!loading" v-model="filter.unit" :options="filter_key.unit"  :placeholder="'Компания'" class="form-control" />
							</div>
						</div>
						<div class="col-12 col-md-4">
							<div class="input-group d-block mb-3">
								<lg-select-multi ref="filter_intermediary" v-if="!loading" v-model="filter.intermediary" :options="filter_key.intermediary"  :placeholder="'Посредник'" class="form-control" />
							</div>
						</div>
					</div>

					<div class="row">
						<lg-type-date-filter v-model="filter.date" :title="'Дата'"/>

						<div class="col-12 col-md-4">
							<div class="form-group">
								<label class="mb-3">&nbsp;</label>
								<div>
									<button @click="exec_filter()" class="btn btn-primary">Искать</button>
									<a @click="clear_filter()" class="btn btn-link text-muted">Очистить фильтр</a>
								</div>
							</div>
						</div>
					</div>



					<grid :url="url" :can_add="false" :can_edit="false" :can_del="false" :can_excel="true" ref="ref_grid"></grid>

				</div>
			</div>
		</div>
	</div>

</template>
<script>
import axios from 'axios';
import config from '@/config.js'
import Grid from "@/components/Grid";
import LgSelectMulti from "../../components/UI/SelectMulti.vue";
export default {
	components: {
		LgSelectMulti,
		Grid
	},
	data() {
		return {
			url: 'payment/invoice',

			filter: {},
			default_filter: {
				n_view : '', date: {from:'',to:''}, payed: [], unit: [], intermediary: []
			},
			filter_key: {},

			status_count: {},
			loading: true,
			loading_status: true,
		}
	},
	mounted()
	{
		this.filter=structuredClone(this.default_filter);
	},
	methods: {
		async exec_filter ()
		{
			await this.$refs.ref_grid.exec_filter(this.filter);
		},
		load_filter(filter,key)
		{
			this.filter=filter;
			this.filter_key=key;
			this.loading=false;
		},
		async clear_filter(filter)
		{
			this.$refs.filter_unit.object.removeActiveItems();
			this.$refs.filter_intermediary.object.removeActiveItems();
//			this.$refs.filter_set_payed.object.removeActiveItems();
			this.filter=structuredClone(this.default_filter);

//			console.log(this.$refs.filter_responsible.object.getValue(true));

//			console.log(this.$refs.filter_responsible.object.getValue(true));
			await this.$refs.ref_grid.exec_filter(this.filter);
		},
		set_status_filter(status)
		{

			this.$refs.ref_grid.exec_filter(this.filter);
		},
	}
}
</script>