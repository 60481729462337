<template>
	<lg-menu/>
	<div class="main-content">
		<div class="container-fluid">
			<div v-if="data!=null" class="row justify-content-center">
				<div class="header mt-md-5">
					<div class="header-body">
						<div class="row align-items-center">
							<div class="col">
								<h6 class="header-pretitle">Рабочий стол</h6>
								<h1 class="header-title">Местоположение # {{data.id}}</h1>
							</div>
							<div class="col">
								<div class="position-relative" style="height:44px;">
									<div v-if="Object.keys(save_list).length>0||data_saving" class="alert alert-light" role="alert">Данные сохраняются, не закрывайте вкладку!</div>
								</div>
							</div>
						</div> <!-- / .row -->
					</div>
				</div>
				<div class="col">
					<div class="row gx-3">
						<div class="col-12 col-md-2">
							<div class="form-group">
								<label class="form-label">Наименование</label>
								<lg-input v-model="data.title"/>
							</div>
						</div>
						<div class="col-12 col-md-6 text-md-end d-flex justify-content-md-end">
							<div class="form-group float-end">
								<label class="form-label invisible d-none d-md-block">К списку</label>
								<button @click="save_manual(false)" type="button" class="btn btn-success" :disabled="data.client==0"> Сохранить</button>
							</div>
							<div class="form-group float-end">
								<label class="form-label invisible d-none d-md-block">К списку</label>
								<button @click="save_manual(true)" type="button" class="btn btn-secondary ms-2 mb-2">К списку</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import axios from 'axios';
import {  updatedDiff } from 'deep-object-diff';
import objectAssignDeep from 'object-assign-deep'
import config from '@/config.js'
import router from "../../router/router";

export default {
	data() {
		return {
			url: 'directories/location',

			data: null,
			data_old: null,
			setting: null,

			save_list: {},
			data_saving: false,
			current: false,
			back_list: [],
		}
	},
	mounted ()
	{
		this.load()
	},
	methods: {
		async load() {
			axios({url: config.API_LOCATION + 'directories/location/edit/' + this.$route.params.id + '/'})
				.then(resp => {
					this.data = resp.data.data[Object.keys(resp.data.data)[0]]
					this.data_old = structuredClone(this.data)
					this.setting = resp.data.setting
				})
				.catch(err => {
					console.log(err)
				})

			setTimeout(this.save, 10000)
		},
		async save() {
			if (Object.keys(this.save_list).length == 0) {
				setTimeout(this.save, 10000)
				return
			}
			this.data_saving = true
			let save_data = {row: {}}
			save_data.row[this.data.id] = structuredClone(this.save_list)
			this.save_list = {}
			save_data.row[this.data.id].__save=1

			axios({url: config.API_LOCATION + 'directories/location/save_list/', data: save_data, method: 'POST'})
				.then(resp => {
					if (resp.data.status == 'ok') {
						this.data_saving = false
						this.current = true
						setTimeout(this.save, 10000)
					}
				})
				.catch(err => {

				})
		},
		async save_manual(close)
		{
			this.data_saving=true
			let save_data={row:{}}
			save_data.row[this.data.id]=structuredClone(this.save_list)
			this.save_list={}
			save_data.row[this.data.id].__save=1

			axios({url: config.API_LOCATION+this.url+'/save_list/', data: save_data, method: 'POST' })
				.then(resp => {
					if(resp.data.status=='ok') {
						this.data_saving=false
						this.current=true
						if(close)
							router.push('/'+this.url)
					}
				})
				.catch(err => {

				})
		},
		backspace() {
			if (this.back_list.length == 0)
				return
//			let t=this.data_old
			eval("this.data_old" + this.back_list[this.back_list.length - 1].name + "='" + this.back_list[this.back_list.length - 1].value + "'")
//			console.log("this.data_old"+this.back_list[this.back_list.length-1].name+"='"+this.back_list[this.back_list.length-1].value+"'")
//			console.log(t)
//			console.log(this.data_old)
			let update = updatedDiff(this.data, this.data_old)
			this.data = structuredClone(this.data_old)
			objectAssignDeep(this.save_list, update)
			this.back_list.pop()
//			console.log(this.back_list.slice(1,1))
		},
		save_empty() {
			if (this.current)
				return
			let save_data = {row: {}}
			save_data.row[this.data.__id] = {__save:1}
			axios({url: config.API_LOCATION + 'directories/location/save_list/', data: save_data, method: 'POST'})
				.then(resp => {
					if (resp.data.status == 'ok') {
						this.current = true
					}
				})
				.catch(err => {

				})
		},
	},
	watch: {
		data: {
			handler(){
//				console.log('up')
//				console.log(this.data_old)
//				console.log(updatedDiff(this.data_old, this.data));
				let update=updatedDiff(this.data_old, this.data)
				if(Object.keys(update).length === 0)
					return
				let get_name= function(o)
				{
					return "['"+Object.keys(o)[0]+"']"+(typeof o[Object.keys(o)[0]] === 'object' && !Array.isArray(o[Object.keys(o)[0]]) && o[Object.keys(o)[0]] !== null?get_name(o[Object.keys(o)[0]]):'')
				}
				let name=get_name(update)
//				console.log(name)
				let old_value=eval('this.data_old'+name)
//				console.log(old_value)
				this.back_list.push({name:name,value:old_value})
				objectAssignDeep(this.save_list,update)
				this.data_old=structuredClone(this.data)
			},
			deep: true
		}
	},
}
</script>