<template>
	<div class="card">
		<div class="card-header">
			<div class="col-12 col-md-3" style="padding-left: 0; margin-left: -0.6rem;">
				<div v-if="can_add" class="btn-group" role="group">
					<button @click="add()" type="button" class="btn btn-success btn-sm"><span class="fe fe-plus"></span> Создать</button>
				</div>
				<div v-if="false" class="btn-group ml-2" role="group">
					<button type="button" class="btn btn-secondary btn-light btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="fe fe-check-square"></span> С выбранными</button>

					<div class="dropdown-menu">
						<a class="dropdown-item" href="#">Удалить</a>
						<a class="dropdown-item" href="#">Экспортировать</a>
					</div>
				</div>
			</div>
			<div class="col"></div>


			<form class="mr-3">
				<select class="custom-select custom-select-sm form-control-flush" data-toggle="select" data-options='{"minimumResultsForSearch": -1}' @change="count">
					<option v-for="count in pageCountList" :key="count" :value="count"  >{{count}} записей на стр.</option>
				</select>
			</form>
			<a v-if="can_excel" :href="domain+url+'/excel/?token='+token" target="_blank" class="btn btn-sm btn-white">Экспорт</a>
		</div>
		<div class="table-responsive mb-0">
			<table v-if="!isLoading" class="table table-nowrap card-table">
				<thead>
				<tr>
					<template v-for="(field, name) in setting.field">
						<th @click="sort(name)" style="color: #000; cursor: pointer;" v-if="field.grid_hide!=1&&do_sort" v-bind:style="field.style" >
							{{field.title}}
							<template v-if="name==setting.option.sort.field">
								<template v-if="setting.option.sort.order=='asc'">up</template>
								<template v-if="setting.option.sort.order=='desc'">down</template>
							</template>
						</th>
						<th style="color: #000; " v-if="field.grid_hide!=1&&!do_sort" v-bind:style="field.style" >
							{{field.title}}
						</th>
					</template>
					<th></th>
				</tr>
				</thead>
				<tbody>
				<tr v-bind:class="(row.hasOwnProperty('__class')?row.__class[Object.keys(row.__class)[0]].class:false)" v-for="row in data">
					<template v-for="(field, name) in setting.field">
						<template v-if="name.substring(0,2)!=='__'&&field.grid_hide!=1">
							<type-string-grid :data="row[name]" :max_len="(field.hasOwnProperty('max_len')?field.max_len:0)" v-if="field.template_type==='string'"/>
							<type-date-grid :data="row[name]" v-if="field.template_type==='date'"/>
							<type-bool-grid :data="row[name]" v-if="field.template_type==='bool'"/>
							<type-link-grid :data="row[name]" v-if="field.template_type==='link'"/>
							<type-person-grid :data="row[name][Object.keys(row[name])[0]]" v-if="field.template_type==='person'"/>
							<type-child-grid-two :data="row[name]" v-if="field.template_type==='child_two'"/>
							<type-child-grid-one :data="row[name]" v-if="field.template_type==='child_one'"/>
							<type-key-grid :data="row[name]" :field="field" v-if="field.template_type==='key'"/>
							<type-log-grid :data="row[name]" :field="field" v-if="field.template_type==='log'"/>
							<type-set-status-grid :data="row[name]" :field="field" :id="row.id" :name="name" :url="url" v-if="field.template_type==='set_status'"/>
							<type-string-several-grid :data="row" :field="field" v-if="field.template_type==='string_several'"/>
							<type-money-grid :data="row[name]" :currency="row[field.currency_string]" v-if="field.template_type==='money'"/>
							<type-file-grid :data="row[name]" :id="row.id" v-if="field.template_type==='file'"/>
							<type-status-grid :data="row" :field="setting.field" v-if="field.template_type==='sky_status'"/>
							<type-pn-grid :data="row[name]" :field="field" v-if="field.template_type==='sky_pn'"/>
							<type-invoice-grid :data="row[name]" :field="field" v-if="field.template_type==='sky_invoice'"/>
							<type-order-grid :data="row[name]" :field="field" v-if="field.template_type==='sky_order'"/>
							<type-delivery-grid :data="row" :field="setting.field" v-if="field.template_type==='sky_delivery'"/>
							<type-link-pn-grid :url="row[name]" v-if="field.template_type==='sky_link_pn'"/>
						</template>
					</template>
					<td v-if="can_edit||can_del">
						<div class="col	">
							<span v-if="can_edit"  @click="$router.push('/'+url+'/edit/'+row.id)" style="cursor: pointer;" class="fe fe-edit"></span>
							<span v-if="can_del"  @click="del(row.id)" style="cursor: pointer;" class="fe fe-trash-2"></span>
							<release-menu v-if="release_menu" :row="row"/>
						</div>
					</td>
				</tr>
				</tbody>
			</table>
			<div v-else style="text-align: center;">Загрузка</div>
		</div>
		<div class="card-footer d-flex justify-content-between">

			<!-- Pagination (prev) -->
			<ul class="list-pagination-prev pagination pagination-tabs card-pagination">
				<li class="page-item">
					<a class="page-link pl-0 pr-4 border-right" href="#">
						<i class="fe fe-arrow-left mr-1"></i> Пред.
					</a>
				</li>
			</ul>

			<!-- Pagination -->
			<ul v-if="!isLoading" class="list-pagination pagination pagination-tabs card-pagination">
				<li v-for="pageN in navPageList" :class="{'active':pageN-1===setting.option.page.current}"><a class="page" @click="page(pageN-1)" style="cursor: pointer;">{{pageN}}</a></li>
			</ul>

			<!-- Pagination (next) -->
			<ul class="list-pagination-next pagination pagination-tabs card-pagination">
				<li class="page-item">
					<a class="page-link pl-4 pr-0 border-left" href="#">
						<i class="fe fe-arrow-right ml-1"></i> След.
					</a>
				</li>
			</ul>
		</div>
	</div>

	<confirm_delete ref="confirm_delete" @del="do_del" :text="'Вы уверенные что хотите удалить запись '+del_id"/>

</template>
<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
import router from "@/router/router";
import axios from 'axios';
import config from '@/config.js'
import ReleaseMenu from '@/pages/release/ReleaseMenu'
import confirm_delete from '@/components/UI/ConfirmDelete.vue'
import TypeStatusGrid from "@/components/SKY/TypeStatusGrid.vue";
import TypePnGrid from "@/components/SKY/TypePNGrid.vue";
import TypeInvoiceGrid from "@/components/SKY/TypeInvoiceGrid.vue";
import TypeOrderGrid from "@/components/SKY/TypeOrderGrid.vue";
import TypeDeliveryGrid from "@/components/SKY/TypeDeliveryGrid.vue";
import TypeLinkPnGrid from "@/components/SKY/TypeLinkPnGrid.vue";

export default {
	components: {
		TypeStatusGrid,
		TypePnGrid,
		TypeInvoiceGrid,
		TypeOrderGrid,
		TypeDeliveryGrid,
		ReleaseMenu,
		confirm_delete,
		TypeLinkPnGrid,
	},
	data() {
		return {
			domain: config.API_LOCATION,
			pageCountList: [
				50,100,500,1000
			],
			del_id: 0,
			token: localStorage.getItem('token') || '',
		}
	},
	props: {
		url: {
			type: String,
			required: true,
		},
		filter: {
			type: Object,
			default: {}
		},
		can_add: {
			type: Boolean,
			default: true
		},
		can_edit: {
			type: Boolean,
			default: true
		},
		can_del: {
			type: Boolean,
			default: true
		},
		can_excel: {
			type: Boolean,
			default: false
		},
		release_menu: {
			type: Boolean,
			default: false
		},
		do_sort: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		...mapMutations({
			setUrl: 'grid/setUrl',
		}),
		...mapActions({
			load: 'grid/load',
			page: 'grid/page',
			count: 'grid/count',
			sort: 'grid/sort',
			exec_filter: 'grid/exec_filter',
		}),
		async add()
		{
			const response = await axios.get(config.API_LOCATION+this.url+'/add/', {});
			router.push('/'+this.url+'/edit/'+response.data.id)
		},
		async del(id)
		{
			this.del_id=id
			this.$refs['confirm_delete'].show()
		},
		async do_del()
		{
			const response = await axios.get(config.API_LOCATION+this.url+'/delete/'+this.del_id+'/', {});
			this.load();
			this.del_id=0
		},
		download_excel()
		{

		}
	},
	async mounted()
	{
		this.setUrl(this.url);
		await this.load();
		this.$parent.load_filter(this.getSavedFilter,this.getKeyFilter);
	},
	computed: {
		...mapState({
			setting: state => state.grid.setting,
			data: state => state.grid.data,
			isLoading: state => state.grid.isLoading,
		}),
		...mapGetters({
			navPageList: 'grid/navPageList',
			getSavedFilter: 'grid/getSavedFilter',
			getKeyFilter: 'grid/getKeyFilter',
		})
	},

}
</script>