<template>
	<td class="align-top" style="white-space: normal; line-height: normal;">
		<template v-for="pn in data">
			<b>{{pn.pn}}</b>&nbsp;-&nbsp;{{pn.order_count}}
			<br/>
		</template>
	</td>
</template>

<script>
export default {
	name: 'type-pn-grid',
	props: {
		data: [ Object],
	},
	methods: {

	},
}
</script>

<style scoped>

</style>
