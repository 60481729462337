<template>
	<div class="container-fluid">
		<div class="row justify-content-center">
			<div class="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5 align-self-center">

				<!-- Heading -->
				<h1 class="display-4 text-center mb-3">
					Skyparts
				</h1>

				<!-- Subheading -->
				<p class="text-muted text-center mb-5">
					Вход
				</p>

				<!-- Form -->
				<form @submit.prevent="login">

					<!-- Email address -->
					<div class="form-group">

						<!-- Label -->
						<label class="form-label">
							Номер телефона
						</label>

						<!-- Input -->
						<input type="tel" v-model="phone" class="form-control" placeholder="+7 (999) 888-77-66" data-inputmask="'mask': '+ 7 (999) 999-99-99'">

					</div>

					<!-- Password -->
					<div class="form-group">
						<div class="row">
							<div class="col">

								<!-- Label -->
								<label class="form-label">
									Пароль
								</label>

							</div>
							<div class="col-auto">



							</div>
						</div> <!-- / .row -->

						<!-- Input group -->
						<div class="input-group input-group-merge">

							<!-- Input -->
							<input v-model="password" class="form-control" type="password" placeholder="Введите ваш пароль">

							<!-- Icon -->
							<span class="input-group-text">
                  <i class="fe fe-eye"></i>
                </span>

						</div>
						<div v-if="error_1" class="badge bg-danger-soft">
							{{error_text_1}}
						</div>
						<div class="form-check form-switch">
							<input class="form-check-input" type="checkbox" v-model="not_save">
							<label class="form-check-label" for="switchOne"><small class="form-text text-muted">Не запоминать</small></label>
						</div>
					</div>

					<!-- Submit -->
					<button class="btn btn-lg w-100 btn-primary mb-3" >
						Войти
					</button>

					<!-- Link -->


				</form>

			</div>
			<div class="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">

				<!-- Image -->
				<div class="bg-cover h-100 min-vh-100 mt-n1 me-n3" :style="ccsBackgroundImage"  ></div>

			</div>
		</div> <!-- / .row -->
	</div>

</template>
<script>
import store from '@/store';
import Inputmask from 'inputmask'
export default {
	components: {

	},
	store,
	data() {
		return {
			ccsBackgroundImage: {
				backgroundImage: `url(${require('@/img/covers/auth-side-cover.jpg')})`
			},
			phone : '',
			password : '',
			not_save : false,
			error_1 : false,
			error_text_1 : '',
		}
	},
	methods: {
		login: function ()
		{
			let login = this.phone
			let password = this.password
			let not_save = this.not_save
			this.$store.dispatch('login', { login, password, not_save  })
				.then(function (resp){
					console.log(resp)
					this.$router.push('/')
				}.bind(this))
				.catch(function (err){
					if(err.error_code=='error_login'){
						this.error_1=true
						this.error_text_1='Неверный телефон или пароль'
					}
				}.bind(this))
		}
	},
	mounted ()
	{
		const toggles = document.querySelectorAll('[data-inputmask]');
		const options = {
			rightAlign: false,
		};
		Inputmask().mask(toggles);
	}
}
</script>
<style>

</style>