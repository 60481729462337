<template>
	<lg-menu/>

	<div class="main-content">




		<!-- HEADER -->
		<div class="header">
			<div class="container-fluid">

				<!-- Body -->
				<div class="header-body">
					<div class="row align-items-end">
						<div class="col">

							<!-- Pretitle -->
							<h6 class="header-pretitle">
								Рабочий стол
							</h6>

							<!-- Title -->
							<h1 class="header-title">
								Лабораторные исследования
							</h1>

						</div>

					</div> <!-- / .row -->
				</div> <!-- / .header-body -->
				<div class="row align-items-center">
					<div class="col">

						<!-- Nav -->
						<ul class="nav nav-tabs nav-overflow header-tabs">
							<li class="nav-item">
								<a @click="patient_select(0)" class="nav-link text-nowrap" :class="{'active':patient_id==0}">
									Все пациенты <span class="badge rounded-pill bg-secondary-soft">{{count}}</span>
								</a>
							</li>
							<li v-for="(p,p_id) in patient_list" :key="p_id" class="nav-item">
								<a @click="patient_select(p_id)" class="nav-link text-nowrap" :class="{'active':patient_id==p_id}">
									{{p.fio_shot}} <span class="badge rounded-pill bg-secondary-soft">{{p.count}}</span>
								</a>
							</li>
						</ul>

					</div>
				</div>

			</div>
		</div> <!-- / .header -->
		<div class="container-fluid">

			<div class="row">
				<div v-for="row in data" class="col-12 col-md-6 col-xl-4" style="display: flex; padding-top: 24px;">
					<div class="card" style="width: 600px;">
						<!-- Dropdown -->

						<!-- Body -->
						<div class="card-body">
							<a class="avatar avatar-xl card-avatar card-avatar-top">
								<img v-if="row.patient[Object.keys(row.patient)[0]].sex=='M'" src="@/img/dd/m.jpg" class="avatar-img rounded-circle border border-4 border-card" alt="...">
								<img v-if="row.patient[Object.keys(row.patient)[0]].sex=='F'" src="@/img/dd/w.jpg" class="avatar-img rounded-circle border border-4 border-card" alt="...">
							</a>
							<p class="card-text" style="margin-top: -2.5825rem;"><span class="badge-soft-secondary">{{row.date_get}}</span></p>
							<template v-if="row.invitro">
								<template v-for="s in row.service">{{s.service[Object.keys(s.service)[0]].title}}<br/></template>
							</template>
							<template v-else>
								<template v-if="row.type=='old'">
									{{row.service[Object.keys(row.service)[0]].title}}
								</template>
								<template v-else>
									<template v-for="s in row.services">
										{{s.analysis_title}}<br/>
									</template>
								</template>
							</template>
							<p class="small text-muted mb-3">{{row.patient[Object.keys(row.patient)[0]].fio}}</p>
						</div>

						<!-- Footer -->
						<div class="card-footer card-footer-boxed">
							<div class="row align-items-center justify-content-between">
								<div class="col-auto">

									<!-- Status -->
									<small v-if="row.status=='r'">
										<span class="text-success">●</span> <template v-if="row.print_part">Частично готов</template><template v-else>Готов</template>
									</small>

								</div>
								<div  v-if="row.status=='r'" class="col-auto">
									<template v-if="row.invitro">
										<a v-for="r in row.invitro_result"  target="_blank" :href="r.id" class="btn btn-sm btn-primary"
										   @click.prevent="download_pdf(true,r.id,0,'Бланк № '+r.n)">Бланк № {{r.n}}</a>
									</template>
									<template v-else>
										<template v-if="row.type=='old'">
											<a v-if="row.file==1" target="_blank" :href="row.id" class="btn btn-sm btn-primary"
											   @click.prevent="download_pdf(false,row.id,0,row.service[Object.keys(row.service)[0]].title)"
											>Результат в PDF</a>
											<template v-if="row.file>1">
												<a v-for="i in row.file"  target="_blank" :href="row.id" class="btn btn-sm btn-primary"
												   @click.prevent="download_pdf(false,row.id,i-1,row.service[Object.keys(row.service)[0]].title)">Бланк № {{i}}</a>
											</template>
										</template>
										<template v-else>
											<a v-if="row.file==1" target="_blank" :href="row.id" class="btn btn-sm btn-primary"
											   @click.prevent="download_pdf(false,row.id,0,row.services[Object.keys(row.services)[0]].analysis_title)"
											>Результат в PDF</a>
											<template v-if="row.file>1">
												<a v-for="i in row.file"  target="_blank" :href="row.id" class="btn btn-sm btn-primary"
												   @click.prevent="download_pdf(false,row.id,i-1,'Бланк № '+i)">Бланк № {{i}}</a>
											</template>
										</template>
									</template>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div><!-- / .main-content -->
</template>
<script>
import router from "../router/router";
import axios from 'axios';
import config from '@/config.js'
import LgMenu from '@/components/Menu.vue'
export default {
	components: {
		LgMenu
	},
	data() {
		return {
			count : 0,
			patient_id : 0,
			patient_list: null,
			data : null,
		}
	},
	mounted ()
	{
		router.push('/offer')

	},
	methods: {
		async load()
		{
			axios({url: config.API_LOCATION+'lk/analysis/'+this.patient_id+'/' })
				.then(resp => {
					this.count = resp.data.count
					this.patient_list=resp.data.patient
					this.data=resp.data.data
				})
				.catch(err => {
					console.log(err)
				})
		},
		patient_select(patient_id)
		{
			this.patient_id=patient_id
			this.load()
		},
		download_pdf(invitro,id,n,title)
		{
			axios.get(config.API_LOCATION+'lk/analysis/download_'+(invitro?'invitro':'own')+'/'+id+'/'+n+'/', { responseType: 'blob' })
				.then(response => {
					const blob = new Blob([response.data], { type: 'application/pdf' })
					const link = document.createElement('a')
					link.href = URL.createObjectURL(blob)
					link.download = title
					link.click()
					URL.revokeObjectURL(link.href)
				}).catch(console.error)
		}
	},
}
</script>
<style>

</style>