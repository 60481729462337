<template>
	<td class="align-top" style="white-space: normal; line-height: normal;">
		<template v-for="row in data">
			<b>{{getKeyData(row,0)}}</b><br />
			<span class="small text-muted">{{getKeyData(row,1)}}</span>
		</template>
	</td>
</template>

<script>
export default {
	name: 'type-child-grid-two',
	props: {
		data: [ Object],
	},
	methods: {
		getKeyData (row,i)
		{
			let res=row[Object.keys(row)[i]];
			while(typeof res === 'object'){
				res=res[Object.keys(res)[0]];
			}
			return res;
		}
	},
}
</script>

<style scoped>

</style>
