<template>
	<td class="align-top" >
		<template v-if="typeof getColor === 'undefined'">
			{{getData}}
		</template>
		<div v-if="typeof getColor !== 'undefined'" class="badge d-inline" :class="getColor">
			<strong>{{getData}}</strong>
		</div>
	</td>
</template>

<script>
export default {
	name: 'type-key-grid',
	props: {
		data: [ Object, String, Number],
		field: [ Object ]
	},
	data() {
		return {
			color: false,
		}
	},
	mounted()
	{
		if(typeof this.data === 'object') {
			if(this.data[Object.keys(this.data)[0]].hasOwnProperty('color'))
				this.color=true
		}
		else{
			let data=this.field.data;
			if(typeof data === 'object') {
				if (data[Object.keys(data)[0]].hasOwnProperty('color'))
					this.color=true
			}
		}
	},
	computed: {
		getData()
		{
			let res=null;
			if(typeof this.data === 'object') {
				res = this.data[Object.keys(this.data)[0]].title;
			}
			else{
				let data=this.field.data;
				if(typeof data === 'object') {
					if(data.hasOwnProperty('id_'+this.data))
						res=data['id_'+this.data].title;
					else
						res='';
				}
				else{
					res=this.data;
				}
			}
//			while (typeof res === 'object') {
//				res = res[Object.keys(res)[0]];
//			}
			return res;
		},
		getColor()
		{
			let res=null;
			if(typeof this.data === 'object') {
				res = this.data[Object.keys(this.data)[0]].color;
			}
			else{
				let data=this.field.data;
				if(typeof data === 'object') {
					if(data.hasOwnProperty('id_'+this.data))
						res=data['id_'+this.data].color;
					else
						res='';
				}
				else{
					res='';
				}
			}
//			while (typeof res === 'object') {
//				res = res[Object.keys(res)[0]];
//			}
			return res;
		}
	}
}
</script>

<style scoped>

</style>
