<template>
	<div class="modal fade" tabindex="-1" style="display: none;" >
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div v-if="data!=null" class="modal-card card" data-list="{&quot;valueNames&quot;: [&quot;name&quot;]}">
					<div class="card-header">
						<h4 class="card-header-title" id="exampleModalCenterTitle"><strong>Редактирование</strong></h4>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="card-body">
						<ul class="list-group list-group-flush list my-n3">
							<li class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										PN: <type-pn-edit v-model="data.pn"  :url="'offer/pn_autocomplete/0/'" />
									</div>
								</div> <!-- / .row -->
							</li>
							<li class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										Описание: <textarea v-model="data.discription" class="form-control form-control-sm mt-3" placeholder="Описание" rows="3"></textarea>
									</div>
								</div> <!-- / .row -->
							</li>
							<li class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										Альтернатива: <textarea v-model="data.alt" class="form-control form-control-sm" placeholder="Альтернатива"></textarea>
									</div>
								</div> <!-- / .row -->
							</li>
							<li v-if="false" class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										Сертификат:
										<template v-for="c in data.certificate">
											<p v-if="c.type!=0">{{setting.certificate.setting.field.type.data['id_'+c.type].title}}</p>
										</template>
										<div class="mt-2">
											<p v-for="(cert,cert_k) in data.certificate">
												<div class="d-flex">
													<lg-input v-model="cert.date" :mask="'9{2}.9{2}.9{4}'" :width="80" class="me-1" />
													<div class="fixed-w1">
														<lg-select style="width: 100px;" v-model="cert.type" :options="setting.field.certificate.setting.field.type.data" class="form-control-sm" />
													</div>
												</div>
												<div class="text-end mt-2">
															<span class="text-reset me-3" title="Загрузить" >
																<label :for="'file-input'+cert_k">
																	<i class="fe fe-paperclip" style="cursor: pointer;--hover-color: green"></i>
																</label>
																<input class="d-none" :id="'file-input'+cert_k" type="file" v-on:change="upload_spares_certificate(k,cert.id,cert_k,$event)"/>
															</span>
													<a v-if="cert.file.id!=0" @click="download_spares_certificate(k,cert.id,cert.file.name,cert.file.type)" class="text-reset me-3" style="cursor: pointer;" title="Скачать">
														<i class="fe fe-download"></i>
													</a>
													<a @click="delete_spares_certificate(k,cert.id,cert_k)" class="text-reset me-3" style="cursor: pointer;" title="Удалить" >
														<i class="fe fe-trash-2"></i>
													</a>
												</div>
											</p>
											<span  @click="add_spares_certificate(k)" style="cursor: pointer;"  class="fe fe-plus-circle ms-2 order-1"></span>
										</div>
									</div>
								</div> <!-- / .row -->
							</li>
							<li class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										Кондиция: <lg-select style="width: 100px;" v-model="data.condition" :options="setting.field.condition.data" class="form-control-sm" />
									</div>
								</div> <!-- / .row -->
							</li>
							<li class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										Срок отгрузки: {{data.dispatch_date}}
									</div>
								</div> <!-- / .row -->
							</li>
							<li class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										Место отгрузки: {{data.dispatch_place}}
									</div>
								</div> <!-- / .row -->
							</li>
							<li class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										MOQ: <lg-input v-model="data.moq" :type="'float'" :width="40" class="mt-1" />
									</div>
								</div> <!-- / .row -->
							</li>
							<li class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										UOM: <lg-select v-model="data.uom" :options="setting.field.uom.data" :width="70" />
									</div>
								</div> <!-- / .row -->
							</li>
							<li v-if="false" class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										Количество: {{data.order_count}}
									</div>
								</div> <!-- / .row -->
							</li>
							<li class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										Цена поставщика: {{formatter_money[data.currency_provider].format(data.price_provider)}}
									</div>
								</div> <!-- / .row -->
							</li>
							<li class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										Наценка: {{data.margin}}%
									</div>
								</div> <!-- / .row -->
							</li>
							<li class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										Цена без доставки: {{formatter_money.USD.format(data.price_usd)}}
									</div>
								</div> <!-- / .row -->
							</li>
							<li class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										ОГАО: {{data.ogao}}
									</div>
								</div> <!-- / .row -->
							</li>
							<li class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										Пошлина: {{data.tax}}%
									</div>
								</div> <!-- / .row -->
							</li>
							<li class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										Таможенные процедуры: <lg-input v-model="data.customs_price_usd" :type="'float'" :width="60" class="mt-1" />
									</div>
								</div> <!-- / .row -->
							</li>
							<li class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										Итоговая цена USD: {{formatter_money.USD.format(data.price_result_usd)}}
									</div>
								</div> <!-- / .row -->
							</li>
							<li class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										Итоговая цена RUB: {{formatter_money.RUB.format(data.price_result_rub)}}
									</div>
								</div> <!-- / .row -->
							</li>
						</ul>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
						<button @click="save" type="button" class="btn btn-primary">Сохранить</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import config from '@/config.js'
import axios from 'axios';
import { Modal } from 'bootstrap';
import TypePnEdit from "@/components/SKY/TypePNEdit.vue";
import {  updatedDiff } from 'deep-object-diff';
export default {
	components: {TypePnEdit},
	emits: ['reload'],
	props: {
//		id: {
//			type: Number,
//			required: true,
//		},
	},
	data() {
		return {
			url: 'release/pn/',
			object: null,
			id: 0,
			data: null,
			data_old: null,
			setting: null,
			formatter_money: null,
		}
	},
	mounted()
	{
		this.object = new Modal(this.$el)

		this.formatter_money = {
			USD: new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'USD'}),
			EUR: new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'EUR'}),
			RUB: new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB'}),
			AED: new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'AED'}),
		}
	},
	methods: {
		async edit(id)
		{
			this.data=null
			this.data_old=null
			this.id=id
			await axios({url: config.API_LOCATION + this.url + 'edit/'+this.id+'/',  method: 'GET'})
				.then(resp => {
					if (resp.data.status == 'ok') {
						this.setting=resp.data.setting;
						this.data=resp.data.data[Object.keys(resp.data.data)[0]]
						this.data_old = structuredClone(this.data)
						this.object.show()
					}
				})
				.catch(err => {

				})
		},
		async save()
		{
			let update=updatedDiff(this.data_old, this.data)
			if(Object.keys(update).length === 0)
				return
			let save_data={row:{}}
			save_data.row[this.id]=update

			axios({url: config.API_LOCATION+this.url+'save_list/', data: save_data, method: 'POST' })
				.then(resp => {
					if(resp.data.status=='ok') {
						this.object.hide()
						this.$emit('reload')
					}
				})
				.catch(err => {

				})
		},
		add_spares_certificate()
		{
			axios({url: config.API_LOCATION+this.url+'child/'+this.id+'/certificate/add/' })
				.then(resp => {
					if(resp.data.status=='ok') {
						let c_id=Object.keys(resp.data.data)[0]
						this.data_old.certificate[c_id]=resp.data.data[c_id]
						this.data=structuredClone(this.data_old)
					}
				})
				.catch(err => {

				})
		},
		delete_spares_certificate(s_k,s_c_id,s_c_k)
		{
			axios({url: config.API_LOCATION+'offer/child/'+this.data.__id+'/spares/child/'+this.data.spares[s_k].spares_id+'/certificate/delete/'+s_c_id+'/' })
				.then(resp => {
					if(resp.data.status=='ok') {
						delete this.data_old.spares[s_k].certificate[s_c_k]
						this.data=structuredClone(this.data_old)
						this.save_empty()
						this.check_error()
					}
				})
				.catch(err => {

				})
		},
		upload_spares_certificate(s_k,s_c_id,s_c_k,e)
		{
			let formData = new FormData();
			formData.append('file', e.target.files[0]);
			axios({
				url: config.API_LOCATION+'offer/child/'+this.data.__id+'/spares/child/'+this.data.spares[s_k].id+'/certificate/element/'+s_c_id+'/file/upload/',
				data: formData,
				method: 'POST',
				headers: {'Content-Type': 'multipart/form-data'}
			})
				.then(resp => {
					if(resp.data.status=='ok') {
						this.data_old.spares[s_k].certificate[s_c_k].file=resp.data.data.file
						this.data=structuredClone(this.data_old)
						this.save_empty()
					}
				})
				.catch(err => {

				})
		},
		download_spares_certificate(s_k,s_c_id,file_name,file_type)
		{
			axios.get(config.API_LOCATION+'offer/child/'+this.data.__id+'/spares/child/'+this.data.spares[s_k].id+'/certificate/element/'+s_c_id+'/file/download/', { responseType: 'blob' })
				.then(response => {
					const blob = new Blob([response.data], { type: file_type })
					const link = document.createElement('a')
					link.href = URL.createObjectURL(blob)
					link.download = file_name
					link.click()
					URL.revokeObjectURL(link.href)
				}).catch(console.error)
		},
	}
}
</script>