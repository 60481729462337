<template>
	<div class="modal fade" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">Удаление</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					{{text}}
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Нет</button>
					<button @click="del" type="button" class="btn btn-primary">Да</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { Modal } from 'bootstrap';
export default {

	emits: ['del'],
	props: {
		text: {
			type: String,
			default: ''
		}
	},
	data(){
		return {
			object: null
		}
	},
	mounted()
	{
		this.object = new Modal(this.$el)

	},
	methods: {
		show()
		{
			this.object.show()
		},
		del()
		{
			this.object.hide()
			this.$emit('del')
		},
	}
}
</script>