<template>
	<select v-model="modelValue" @change="changeOption" class="form-select form-select-sm" :style="[width!=0 ? { width: width+'px'}:{}]" :disabled="disabled">
		<option
			v-for="(v,k) in options"
			:key="k"
			:value="v.id"
		>
			{{ v.title }}
		</option>
	</select>
</template>
<script>
import Choices from 'choices.js';
import axios from 'axios';
import config from '@/config.js'
export default {
	name: 'lg-select',
	props: {
		modelValue: {
			type: [String, Number]
		},
		options: {
			type: Object,
			default: {}
		},
		width: {
			type: Number,
			default: 0
		},
		param: {
			type: Object,
			default: {}
		},
		backend: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
	},
	data() {
		return {
			sel: null,
			default_param: {
				searchEnabled: false,
				classNames: {
					containerInner: "form-select form-select-sm",
					input: 'form-control',
					inputCloned: 'form-control-sm',
					listDropdown: 'dropdown-menu',
					itemChoice: 'dropdown-item',
					activeState: 'show',
					selectedState: 'active',
				},
				shouldSort: false,
				allowHTML: true,
				searchFields: ['label'],
//				searchChoices: false,
				callbackOnCreateTemplates: function (template) {
					return {
						choice: (classNames, data) => {
							const classes = `${classNames.item} ${classNames.itemChoice} ${
								data.disabled ? classNames.itemDisabled : classNames.itemSelectable
							}`;
							const disabled = data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable';
							const role = data.groupId > 0 ? 'role="treeitem"' : 'role="option"';
							const selectText = this.config.itemSelectText;

							const label =
								data.customProperties && data.customProperties.avatarSrc
									? `
            <div class="avatar avatar-xs me-3">
              <img class="avatar-img rounded-circle" src="${data.customProperties.avatarSrc}" alt="${data.label}" >
            </div> ${data.label}
          `
									: data.label;

							return template(`
            <div class="${classes}" data-select-text="${selectText}" data-choice ${disabled} data-id="${data.id}" data-value="${data.value}" ${role}>
              ${label}
            </div>
          `);
						},
					};
				},
			}
		}
	},
	methods: {
		changeOption(event)
		{
			this.$emit('update:modelValue', event.target.value);
		},
/*		update_value(val)
		{
			console.log(val)
			this.$el.value=val
		},*/
	},
	mounted ()
	{
/*		const options = {
			...this.default_param,
			...this.param,
		};
		this.sel=new Choices(this.$el,options)
		if(this.backend!=''){
			this.sel.setChoices(async () => {
				try {
					const items = await axios(config.API_LOCATION+this.backend);
					return items.json();
				} catch (err) {
					console.error(err);
				}
			});
		}*/
	}
}
</script>


<style>
.choices__list div {
	padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
	line-height: 1.75;
	font-size: 0.8125rem;
}
.fixed-w1.form-control .choices__list.choices__list--single .choices__item.choices__item--selectable {
		min-width: 8ch!important;
	}
</style>